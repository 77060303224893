.s-case-study-cooperation-stories-list {
    color: $fm-black;
    padding: 40px 0;

    @include min-screen($tablet-s) {
        padding: 80px 0;
    }
    
    @include min-screen($desktop) {
        padding: 120px 0;
    }

    &__header {
        color: $fm-white;
        margin-bottom: 40px;

        @include min-screen($tablet-s) {
            margin-bottom: 60px;
        }

        @include min-screen($desktop) {
            margin-bottom: 80px;
        }
    }

    .s-case-study-featured-cooperation-story {
        margin-bottom: 40px;

        &:before {
            content: none;
        }
    }
}