.s-statistic {
    background: $fm-black;
    padding: 48px;
    color: $fm-white;
    display: flex;
    flex-direction: column;
    
     @include min-screen($tablet-s) {
        aspect-ratio: 1 / 1;
        height: clamp(21.125rem, 9.3009rem + 24.6657vw, 31.5rem);
        width: clamp(21.125rem, 9.3009rem + 24.6657vw, 31.5rem);
         // $tablet-s - 338px - $desktop-l 504px
     }

     &__content {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        text-wrap: balance;
  
        &--value {
            font-size: 160px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -1.6px;
        }

        h5 {
            max-width: 520px;
        }
     }

    &__line {
        height: 6px;
        width: 100%;
        background-color: white;
        background-image: linear-gradient(89.04deg, #F7AE9D 0.62%, #D64F3C 15.43%, #5C153B 47.74%, #561D5C 54.8%, #152A9E 80.24%, #1394C3 93.11%, #07C7DF 97.37%);
    }
}
