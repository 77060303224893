.s-career-cta {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-block: 40px;
    text-wrap: warp;
    
    @include min-screen($tablet) {
        margin-block: 100px 80px;
    }
 
    &--title {
        @include h--m();
        text-align: center;

        span {
            @include text--gradient-cold(89.93deg);
        }
    }

    a {
        width: fit-content;
        align-self: center;
    }

    &--offer {
        align-items: center;
        margin-top: 40px;
        text-align: center;

        .s-career-cta--title{
            width: 24ch;

            @include min-screen($phone) {
                width: unset;
            }
        }
        
        @include min-screen($tablet) {
            margin-top: 65px;
        }
    }
}

.s-career-cta-wrapper {
    display: flex;
    flex-direction: column-reverse;
    padding-block: 80px;
    gap : 60px;
    margin-bottom: 80px;
    
    img {
        align-self: center;
    }
    @include min-screen($tablet) {
       gap : 200px;
       flex-direction: row;
    }

    p {
        padding-top: 24px;
        padding-bottom: 60px;
    }
}