@mixin secondary-font {
  font-family: $secondary-font-family;
  text-transform: uppercase;
}

@mixin gradient-black ($degrees: 180deg) {
  background: linear-gradient($degrees, rgba(21, 21, 21, 0.00) 0%, #151515 100%);
}

@mixin colors-gradient-blue($degrees: 90deg) {
  background: linear-gradient($degrees, #aecdf0 1.33%, #83a9d6 16.56%, #074a94 43.93%, #2073bb 71.92%, #aed2e9 97.21%);
}

@mixin colors-gradient-cold($degrees: 90deg) {
  background: linear-gradient($degrees, #AECDF0 0.03%, #83A9D6 6.83%, #2073BB 21.59%, #AED2E9 42.9%)
}

@mixin colors-gradient($degrees: 90deg) {
  background: linear-gradient(
    $degrees,
    #fab8a7 10%,
    #d8503c 20%,
    #57143b 45%,
    #761a3c 50%,
    #15229b 70%,
    $main-gradient-6 85%,
    #00e2ee 100%
  );
}

@mixin colors-gradient-light($degrees: 90deg) {
  background: linear-gradient($degrees,
      #F6B2A2 0%,
      #E84230 9.96%,
      #AF2272 27.68%,
      #9F154B 32.1%,
      #804CBB 45.83%,
      #4060F2 58.23%,
      #0096C7 88%,
      #5CC2D7 100%);
};

@mixin colors-gradient-eb($degrees: 135deg) {
  background-image: linear-gradient(
    $degrees,
    #f7ae9d 0%,
    #d64f3c 15.3%,
    #5c153b 48.7%,
    #561d5c 56%,
    #152a9e 82.3%,
    #1394c3 95.6%,
    #07c7df 100%
  );
}

@mixin colors-gradient-press-office($degrees: 90deg) {
  background: linear-gradient($degrees, #e94230, #94153a, #800a3c);
}

@mixin colors-gradient-press-office-text($degrees: 90deg) {
  background-image: linear-gradient($degrees, #e94230, #94153a, #800a3c);
}

@mixin gradient-text() {
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

@mixin formatRichText() {
  p,
  ul li {
    @include b--m();
    line-height: 32px;
  }

  p {
    margin-bottom: 20px;
  }

  h1 {
    @include h--l();
    margin: 32px 0 18px 0;
  }

  h2 {
    @include h--m();
    margin: 24px 0 14px 0;
  }

  h3 {
    @include h--s();
    margin-top: 12px;
  }
}

@mixin baseContainerMaxWidth() {
  max-width: 1075px;

  @include min-screen($desktop-l) {
    max-width: 1250px;
  }

  @include min-screen($desktop-xl) {
    max-width: 1500px;
  }
}

@mixin baseContainerSize() {
  @include baseContainerMaxWidth();
  width: 100%;
  margin: 0 auto;
}

@mixin text-ellipsis($lines: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin between-screen($min-width, $max-width) {
  @media (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    @content;
  }
}


@mixin text--gradient-cold($degrees: 90deg) {
  background: linear-gradient($degrees, #AECDF0 0%, #83A9D6 16%, #074A94 44%, #2073BB 74%, #AED2E9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
