$base-font-family: "Aeonik", Arial, Helvetica, sans-serif;
$secondary-font-family: "PP Fraktion Mono", Arial, Helvetica, sans-serif;
$base-font-weight: 400;
$base-font-size: 14px;

$padding-horizontal-desktop: 120px;
$padding-vertical-desktop: 60px;

$phone-s: 320px;
$phone: 540px;
$tablet-s: 767px;
$tablet: 960px;
$desktop-s: 1180px;
$desktop: 1280px;
$desktop-l: 1440px;
$desktop-xl: 1700px;

$fm-atlas-grey: #929497;
$fm-black: #151515;
$fm-black-transparent: rgba(21, 21, 21, 0.5);
$fm-iron: #343434;
$fm-light-grey: #A1A1A1;
$fm-grey: #787878;
$fm-stone: #C9C9C9;
$fm-smoke: #E3E3E3;
$fm-alabaster: #F8F8F8;
$fm-white: #FFFFFF;
$fm-transparent: rgba(255, 255, 255, 0.12);
$fm-transparent-2: rgba(255, 255, 255, 0.5);
$fm-transparent-40: rgba(255, 255, 255, 0.40);

$main-gradient-1: #F7AE9D;
$main-gradient-2: #D64F3C;
$main-gradient-3: #5C153B;
$main-gradient-4: #561D5C;
$main-gradient-5: #152A9E;
$main-gradient-6: #1394C3;
$main-gradient-7: #07C7DF;

$grid-gap: 16px;
$grid-gap-xxl: 64px;

$transition-time: 250ms;
$transition-easing: ease-in-out;

$blur-sm: 4px;
$blur: 8px;

$radius-sm: 4px;
$radius-md: 8px;

$breakpoints: (phone-s: $phone-s, phone: $phone, tablet-s: $tablet-s, tablet: $tablet, desktop-s: $desktop-s, desktop: $desktop, desktop-l: $desktop-l, desktop-xl: $desktop-xl);
