.s-career-recruitment-process {
    padding-block: 0 40px;

    &--title {
        @include h--l();
        font-weight: 250;
        margin-bottom: 20px;

        b {
            font-weight: 400;
        }
    }

    &--subtitle {
        @include h--s();
        font-weight: 250;
    }
}

.s-career-recruitment-process__steps {
    margin-block: 70px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    max-width: 800px;
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 0;
        bottom: 0;
        width: 2px;
        z-index: 1;
        height: 100%;
        background-color: $fm-smoke;

    }

    &__line {
        content: '';
        position: absolute;
        left: 5px;
        top: 0;
        height: 0%;
        width: 2px;
        z-index: 2;
        @include colors-gradient(180deg);
    }
}

.s-career-recruitment-process__steps--item {
    position: relative;
    padding-left: 60px;

    h5 {
        font-size: 23px;
        line-height: 28px;
        font-weight: 400;
        color: $fm-black;
        margin-bottom: 16px;

        span {
            color: $fm-stone;
        }
    }

    p {
        font-size: 13px;
        line-height: 21px;
        font-weight: 400;
        color: $fm-black;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 12px;
        height: 12px;
        background-color: $fm-smoke;
        z-index: 3;
    }

    &--is-visible {
        &:before {
          background-color: $fm-black;
        }
      }

    &:last-of-type {
        p {
            @include h--m();
            transform: translateY(8px);

            @include min-screen($desktop-xl) {
                white-space: nowrap;
                font-size: 35px;
                line-height: 42px;
            }
        }

        &:before {
            bottom: 0;
            top: unset;
        }
    }  
}
