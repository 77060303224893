.cta {
    background: center no-repeat;
    background-position: center;
    background-image: var(--img);
    background-size: cover;
    padding-block: 120px;
    position: relative;

    @include min-screen($tablet) {
        padding-block: 160px;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background: center no-repeat;
        background-position: center;
        background-image: linear-gradient(200deg, #F6B2A2 0%, #E84230 9.96%, #80123D 32.1%, #253788 58.23%, #0096C7 88%, #5CC2D7 100%);
        opacity: 0.65;
    }

    &>div {
        position: relative;
        z-index: 1;
    }

    &__info {
        max-width: 350px;
        color: $fm-white;
        text-wrap: balance;

        h2 {
            margin-bottom: 40px;

            @include min-screen($tablet-s) {
                font-size: 55px;
                line-height: 72px;
            }
        }

        h3 {
            margin-bottom: 56px;

            @include min-screen($tablet-s) {
                font-size: 20px;
                line-height: 26px;
            }
        }

        @include min-screen($tablet) {
            max-width: unset;
        }
    }
}
