.s-case-study-challenge {
  margin: 40px 0 60px;

  @include min-screen($tablet-s) {
    margin: 80px 0 120px;
  }

  @include min-screen($desktop) {
    margin: 120px 0 80px;
  }

  &__heading {
    margin-bottom: 60px;
  }

  ul {
    li {
      position: relative;
      margin-bottom: 24px;
      padding-left: 44px;
      @include b--l;
      line-height: 32px;


      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        background: url("../images/icon-tick.svg") 50% 50% / contain no-repeat;
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }
}
