.swal2-shown>[aria-hidden='true'] {
    filter: blur($blur-sm);
}

div[data-path*="/raport-phygital/"] ~ .swal2-container {
    overflow-y: hidden !important;

    @include max-screen($phone) {
        padding: 0 !important;
    }
}

.s-report-preview {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button,
        a {
            height: 56px;
        }

      > a:first-of-type {
            border-color: $fm-white;
            font-size: clamp(0.5625rem, 0.017rem + 2.7273vw, 0.9375rem);; // $phone-s = 9px => $phone = 15px 
            padding-inline: clamp(1rem, -0.2727rem + 6.3636vw, 1.875rem); // $phone-s = 16px => $phone = 30px 
        }
    }

    &__actions {
        display: flex;
        column-gap: 12px;

        @include min-screen($desktop-s) {
            column-gap: 30px;
        }

        &__dl-button {

            &--mobile {
                @extend .c-button--circle;

                span {
                    transform: scale(1.6);
                }

                @include min-screen($desktop-s) {
                    display: none
                }
            }

            &--desktop {
                display: none;

                @include min-screen($desktop-s) {
                    display: flex;
                    align-items: center;
                    border-color: $fm-white;
                    color: $fm-white;
                    width: fit-content;


                    &:hover {
                        background-color: transparent;
                        color: $fm-white;
                    }

                    span {
                        transform: scale(1.6) translateX(8px);
                    }
                }
            }
        }
    }

    &__content {
        background-color: $fm-white;
        height: 100%;
        overflow: auto;
        position: relative;
    }
}