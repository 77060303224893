.s-about {
  padding-top: 80px;

  &__header {
    @include min-screen($tablet-s) {
      margin-bottom: 60px;
    }
  }

  &__item {
    $item: &;

    display: flex;
    flex-direction: column;

    @include min-screen($tablet-s) {
      flex-direction: row;
      margin-bottom: 80px;
    }

    @include min-screen($desktop) {
      margin-bottom: 120px;
    }

    &__image {
      text-align: center;
      flex: 1;

      @include min-screen($tablet-s) {
        margin-left: 48px;
      }

      @include min-screen($desktop-s) {
        margin-left: 120px;
      }

      img {
        @include min-screen($tablet-s) {
          width: 295px;
          object-fit: cover;
        }

        @include min-screen($desktop-s) {
          width: 504px;
          height: 504px;
        }
      }
    }

    &__text {
      flex: 1;

      h3 {
        margin-block: 0 40px;
      }

      p {
        margin-bottom: 32px;
      }

      ul {
        li {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 18px;

          &:before {
            content: '';
            background: url("../images/icon-tick.svg") 50% 50% / contain no-repeat;
            display: inline-block;
            min-width: 24px;
            min-height: 24px;
            margin-right: 16px;
            margin-top: 4px;
          }
        }
      }

      .c-button {
        margin-bottom: 48px;
        
        @include min-screen($tablet-s) {
          margin-bottom: 0;
          margin-top: 16px;
        }
      }
    }

    &#{$item}--imgOnTheLeft {
      @include min-screen($tablet-s) {
        flex-direction: row-reverse;
      }

      #{$item}__image {
        @include min-screen($tablet-s) {
          margin-right: 48px;
          margin-left: 0;
        }

        @include min-screen($desktop-s) {
          margin-right: 120px;
        }
      }
    }

    &#{$item}--onlyImg {
      #{$item}__text {
        display: none;
      }

      #{$item}__image {
        margin: 0 auto;
        max-width: 100%;
      }
    }

    &#{$item}--onlyText {
      #{$item}__image {
        display: none;
      }
    }
  }
}
