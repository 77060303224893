@mixin h--xl() {
  font-size: 40px;
  line-height: 55px;
  font-weight: 400;

  @include min-screen($tablet) {
    font-size: 60px;
    line-height: 75px;
  }

  @include min-screen($desktop-s) {
    font-size: 80px;
    line-height: 100px;
  }

  @include min-screen($desktop-xl) {
    font-size: 130px;
    line-height: 165px;
  }
}

@mixin h--l {
  font-size: 45px;
  line-height: 52px;

  @include min-screen($desktop-s) {
    font-size: 55px;
    line-height: 72px;
  }

  @include min-screen($desktop-xl) {
    font-size: 80px;
    line-height: 100px;
  }
}

@mixin h--m--desktop-s() {
  font-size: 25px;
  line-height: 32px;
  font-weight: 400;

  @include min-screen($tablet) {
    font-size: 30px;
    line-height: 42px;
  }

  @include min-screen($desktop-s) {
    font-size: 35px;
    line-height: 42px;
  }
}

@mixin h--m {
  @include h--m--desktop-s;

  @include min-screen($desktop-xl) {
    font-size: 55px;
    line-height: 72px;
  }
}

@mixin h--s-desktop {
  font-size: 18px;
  line-height: 22px;

  @include min-screen($desktop-s) {
    font-size: 20px;
    line-height: 26px;
  }
}

@mixin h--s {
  @include h--s-desktop();

  @include min-screen($desktop-xl) {
    font-size: 25px;
    line-height: 31px;
  }
}

@mixin h--xs {
  @include secondary-font;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  @include min-screen($tablet) {
    font-size: 13px;
  }

  @include min-screen($desktop-xl) {
    font-size: 15px;
  }
}

@mixin b--m() {
  font-size: 16px;
  line-height: 24px;

  @include min-screen($desktop-xl) {
    font-size: 18px;
  }
}

@mixin b--l() {
  font-size: 16px;
  line-height: 24px;

  @include min-screen($tablet) {
    font-size: 20px;
    line-height: 32px;
  }
}

@mixin btn--m() {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}

@mixin text--gradient() {
  @include colors-gradient();
  @include gradient-text();
}

@mixin text--gradient-light {
  @include colors-gradient-light();
  @include gradient-text();
}

@mixin clamp-text($lines) {
  -webkit-line-clamp: $lines;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
