footer {
  padding: 50px 0 0 0;
  @include min-screen($phone) {
    padding: 105px 0 0 0;
  }
  @include min-screen($desktop-xl) {
    padding: 145px 0 0 0;
  }
  @include secondary-font;
  color: $fm-white;
  font-size: 15px;

  a {
    color: $fm-white;
    text-decoration: none;
  }

  @include min-screen($tablet) {
    font-size: 13px;
  }

  @include min-screen($desktop-xl) {
    font-size: 14px;
  }
}

.s-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  @include min-screen($tablet) {
    grid-template-columns: 246px 252px 252px auto;
    column-gap: 0;
  }
  @include min-screen($desktop-s) {
    grid-template-columns: 346px 292px 292px auto;
  }
  @include min-screen($desktop-l) {
    grid-template-columns: 346px 392px 392px auto;
  }

  @include min-screen($desktop-xl) {
    grid-template-columns: 500px 380px 380px auto;
  }
}

.s-footer__logo {
  width: 175px;
  height: 65px;

  @include min-screen($desktop-l) {
    width: 225px;
    height: 75px;
  }

  @include min-screen($desktop-xl) {
    width: 360px;
    height: 110px;
  }
}

.s-footer__contact {
  @include max-screen($tablet-s) {
    grid-column-start: span 2;
  }
}

.s-footer__nav {
  li {
    padding: 0;
    margin: 0 0 8px 0;
  }

  li a {
    text-decoration: underline;
  }
}
