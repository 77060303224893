.s-career-benefits__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    @include min-screen($desktop-s) {
        padding-top: 60px;
    }

    &--title {
        @include h--l();
        color: $fm-black;
        font-weight: 250;
        margin-bottom: 60px;
        text-wrap: balance;
        text-align: center;
        @include min-screen($desktop-s) {
            text-align:unset
        }
    }

    b {
        font-weight: 400;
    }
}

.s-carrer-benefits__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    @include min-screen($phone) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include min-screen($desktop-s) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.s-career-benefits__card {
    background: $fm-alabaster;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 6px;
    padding: 28px 18px;

    @include min-screen($tablet) {
        padding: 28px 48px;
    }

    &--title {
        @include h--s();
        color: $fm-black;
    }

    &--desc {
        color: $fm-iron;
        font-size: 12px;
        line-height: 18px;

        @include min-screen($desktop-l) {
            font-size: 14px;
        }
    }
}
