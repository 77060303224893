.s-career-team {
  text-align: center;
  padding: 60px 0;
  
  @include min-screen($tablet) {
    padding: 100px 0;
    text-align:left;
  }

  @include min-screen($desktop-xl) {
    padding-top: 140px;
  }
}

.s-career-filter__container {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  & > p {
    text-align: center;
    margin-bottom: 40px;
  }

  @include min-screen($tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 64px;

    & > p {
      margin-bottom: 0;
    }
  }
}

.s-career-spinner {
  display: none;
  justify-content: center;
  padding: 40px 0;
  
  @include min-screen($tablet) {
    padding: 80px 0;
  }
}

.s-career-spinner--visible {
  display: flex;
}

.s-career-offers {
  margin-bottom: 60px;
  
  @include min-screen($tablet) {
    margin-bottom: 100px;
  }

  @include min-screen($desktop-xl) {
    margin-bottom: 140px;
  }
}

.s-career-offers__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px;
  margin-bottom: 20px;
  border: 1px solid $fm-smoke;
  color: $fm-black;
  text-decoration: none;
  border-radius: 7px;
  transition: border-color 0.2s linear;

  h3 {
    margin-bottom: 20px;
  }

  @include min-screen($tablet-s) {
    padding: 30px 60px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }

  @include min-screen($tablet) {
    padding: 60px 90px;
  }

  @include min-screen($desktop-xl) {
    margin-bottom: 40px;
  }

  &:hover {
    border: 1px solid darken($fm-smoke, 30%);
    h3 {
      text-decoration: underline;
    }
  }
}

.s-career-offers__item-location {
  color: $fm-grey;
}

.s-career-benefits {
  padding: 40px 0;
  color: $fm-white;
  // display: flex;
  // justify-content: center;
  margin: 0 auto;

  @include max-screen($phone) {
    justify-content: center;
  }
  
  @include min-screen(1060px) {
    padding: 100px 0;
  }

  @include min-screen($desktop-xl) {
    padding: 140px 0;
  }
}

.s-career-benefits__item {
  display: flex;
  margin-bottom: 20px;
  
  & > img {
    margin-right: $grid-gap;
  }

  @include max-screen($phone) {
    width: 250px;
  }

  @include min-screen($tablet) {
    margin-bottom: 40px;
  }
  
  @include min-screen($desktop-s) {
    margin-bottom: 76px;
  }
}

.s-career-benefits--light {
  color: $fm-black
}

.s-career-48 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: $fm-white;
  padding-bottom: 60px;

  & > h2 {
    margin-bottom: 24px;
  }

  @include min-screen($phone) {
    padding-bottom: 80px;
  }

  @include min-screen($tablet) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 189px;
  }
}

.s-career-48--light {
  color: $fm-black;
}

.s-career-48__items {
  margin-top: 60px;

  @include min-screen($tablet) {
    margin-top: 0;
  }
}

.s-career-48__item {
  display: flex;
  align-items: center;
  margin-bottom: $grid-gap;
  span {
    margin-right: 40px;
  }

  &:last-of-type {
      h3 {
      width: 100%;
      @include colors-gradient-blue();
      @include gradient-text();
    }
  }
}

.s-career-trips {
  padding-top: 60px;
  padding-bottom: 60px;

  h2 {
    margin-bottom: 40px;
    text-align: center;
  }

  @include min-screen($tablet) {
    padding-top: 160px;
    padding-bottom: 160px;

    h2 {
      margin-bottom: 80px;
      text-align: left;
    }
  }

  @include min-screen($desktop-xl) {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}

.s-career-questions {
  margin-bottom: 80px;
  display: flex;
  text-align: center;

  flex-direction: column;
  
  @include min-screen($tablet) {
    margin-bottom: 167px;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}

.s-career-questions__cta {
  width: 100%;
  
  @include min-screen($tablet) {
    width: 50%;
  }

  h2 {
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 64px;
  }
}

.s-career-questions__person {
  text-align: center;

  img {
    width: 254px;
    height: auto;
  }

  h3 {
    margin: 16px 0 8px 0;
  }

  p {
    color: $fm-grey;
  }

  @include max-screen($tablet) {
    margin-top: 40px;
    img {
      width: 180px;

    }
  }
}

.s-career-alternative {
  padding: 80px 0;

  h2 {
    margin-bottom: 20px;
    span {
      @include colors-gradient-blue();
      @include gradient-text();
    }
  }

  a {
    margin-top: 48px;
  }
  
  @include min-screen($tablet) {
    padding: 140px 0;

    h2 {
      margin-bottom: 40px;
    }

    a {
      margin-top: 64px;
    }
  }
}