.s-listing_container {
  padding-top: 64px;
  display: grid;
  grid-template-areas: 
  'tags'
  'search'
  'featured'
  'listing';
  
  @include min-screen($desktop-s) {
    padding-top: 84px;
    grid-template-areas: 
    'featured'
    'tags'
    'listing';
  }
}

.s-listing_featured {
  grid-area: featured;
}

.s-insights-blocks__listing {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @include min-screen($tablet-s) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @include min-screen($desktop-s) {
    gap: 65px;
  }

  .insight-block {
    width: 100%;
  }
  
  .c-pagination {
    grid-column: span 3;
  }
  
  .insight-block__tags {
    margin-top: auto;
  }
}

.s-insights-blocks__results-for {
  @include min-screen($tablet-s) {
    grid-column: span 3;
  }
}

.s-insights-blocks__results-for {
  @include min-screen($tablet-s) {
    grid-column: span 3;
  }
}

.s-insight-block--authors {
  span:not(:last-of-type) {

    &::after {
      content: ',';
    }
  }
}
