.accessibility-page {
    .s-header-cta__spacer {
        .s-header__subtext {
            font-family: Aeonik;
            font-size: 35px;
            font-weight: 400;
            line-height: 42px;
            letter-spacing: -0.01em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
  

    }
    .section-description {
        display: flex;
        flex-direction: column;
        gap: 60px;
        text-align: center;
        margin:  60px 0;

        @include min-screen($desktop-s) {
            margin:  148px 0;
        }
        h2 {
            background-clip: text !important;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background: linear-gradient(89.1deg, #D64F3C 1.54%, #152A9E 64.54%, #1394C3 98.46%);

        }

    }

    .s-description-text-image {
        padding-block: 0px;

        @include min-screen($desktop-s) {
            padding-block: 60px;
        }
    }

    .section-invite {
        padding:  80px 0;
        .testimonials {
            display: flex;
            gap: 16px;
            padding-top:40px;
            justify-content: center;
            flex-wrap: wrap;

            @include min-screen($desktop-s) {
                flex-wrap: nowrap;

            }

            .item {
                width: 258px;
                display: flex;

                flex-direction: column;
                align-items: center;
                padding: 32px 16px;
                gap: 10px;
                text-align: center;
                border: 1px solid rgba(227, 227, 227, 1)
            
            }
            .description {
                font-family: Aeonik;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

            }
        }
    }

    .section-cta {
        .cta-text {
            text-align: center;
            padding: 100px 20px;
            color: $fm-black;
          
            @include min-screen($tablet) {
              padding: 148px 0;
            }
          
            h1 {
              color: $fm-grey;
            }
          
            h2 {
              margin: 0 auto;
          
              span {
                @include colors-gradient();
                @include gradient-text();
              }
            }
        }
    }

    .section-feature {
        padding: 80px 0;
        margin-bottom: 0;

        @include min-screen($desktop-s) {
            margin-bottom: 40px;
        }
        h2{
            padding-bottom: 24px;
        }
        h3{
            padding-bottom: 24px;

        }

        h4 {
            @include h--s-desktop;

        }
        &.base-container--alabaster-bg {
            p {
                font-weight: 400;
            }
        }
    }

    .test {
        padding: 100px;
    }
    .section-clients {
        padding: 40px 0 60px 0;
        h4 {
            text-transform: uppercase;
            font-family: $secondary-font-family;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            padding-bottom: 40px;
            @include min-screen($desktop-s) {
                padding-bottom: 0;
            }
        }

    }

    .section-approach {
        padding: 100px 0;
        .content{
            display: flex;
            gap: 60px;
            flex-direction: column;
            @include min-screen($desktop-s) {
                flex-direction: row;
                gap: 104px;

            }
        }
        h3 {
            padding-bottom:16px;
            display: flex;
            position: relative;
            margin-bottom: 16px;
            @include min-screen($desktop-xl) {
                font-size: 50px;
            }
        }

        h3::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 4px; 
            background: linear-gradient(89.04deg, #F7AE9D 0.62%, #D64F3C 15.43%, #5C153B 47.74%, #561D5C 54.8%, #152A9E 80.24%, #1394C3 93.11%, #07C7DF 97.37%);
            border-radius: 2px; 
            width: 100%;
            
          }
          
        .list-text {
            padding-top: 40px;
        } 

        ul {
            list-style-type: disc;
            list-style-position: inside;
        }
    }
}