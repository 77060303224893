.s-career-development {
    margin-block: 40px 35px;
    
    @include min-screen($tablet-s) {
        margin-block: 40px 35px;
    }

    &--title {
        @include h--l();
        font-weight: 250;
        margin-bottom: 24px;

        b {
            font-weight: 400;
        }
    }

    &--content {
        @include h--s();
        font-weight: 250;
        margin-bottom: 40px;
        p {
            margin-bottom: 60px;
        }

        ul {
            margin-bottom: 40px;
            li {
                background: no-repeat url('../images/icon-benefit-tick.svg');
                background-size: 28px 24px;
                background-position: left 5px;
                padding-left: 42px;
                margin: 24px 0;
                line-height: 25px;
                font-family: Aeonik;
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                text-align: left;

             }
          }

    }
}
