.s-career-filter {
  position: relative;
  user-select: none;
  width: 300px;
  color: $fm-grey;
}
.s-career-filter__select {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $fm-black;
  border-radius: 4px;
}
.s-career-filter__select-trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-size: 18px;
  background: $fm-white;
  cursor: pointer;
  border-radius: 4px;
}
.s-career-filter__custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid $fm-stone;
  background: #fff;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 3;
  height: 250px;
  overflow: auto;
}
.s-career-filter__select.open .s-career-filter__custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.s-career-filter__custom-option {
  position: relative;
  display: block;
  padding: 15px;
  font-size: 18px;
  color: #3b3b3b;
  cursor: pointer;
  transition: all 0.5s;
  background-color: #fff;
}
.s-career-filter__custom-option:hover {
  cursor: pointer;
  background-color: #fbfbfb;
}
.s-career-filter__custom-option.selected {
  background-color: #fbfbfb;
}
.s-career-filter__arrow img {
  transition: 0.2s ease;
}
.open .s-career-filter__arrow img {
  transform: rotate(180deg);
}