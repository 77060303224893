.s-case-study-story-timeline {
  position: relative;
  margin-bottom: 80px;
  margin-top: 40px;

  @include min-screen($tablet-s) {
    margin-bottom: 120px;
    margin-top: 60px;
  }

  @include min-screen($desktop) {
    margin-bottom: 160px;
    margin-top: 80px;
  }

  @include min-screen($desktop-xl) {
    margin-bottom: 200px;
    margin-top: 120px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 27px;
    top: 0;
    bottom: 0;
    width: 2px;
    z-index: 1;
    background-color: $fm-smoke;

    @include min-screen($desktop-s) {
      left: 7px;
    }
  }

  &__line {
    content: '';
    position: absolute;
    left: 27px;
    top: 0;
    height: 24%;
    width: 2px;
    z-index: 2;
    @include colors-gradient(180deg);

    @include min-screen($desktop-s) {
      left: 7px;
    }
  }
}

.s-case-study-story-timeline-item {
  position: relative;

  &:not(:last-child) {
    padding-bottom: 80px;

    @include min-screen($tablet-s) {
      padding-bottom: 160px;
    }

    @include min-screen($desktop) {
      padding-bottom: 280px;
    }

    @include min-screen($desktop-xl) {
      padding-bottom: 320px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: $fm-smoke;
    z-index: 3;
  }

  &__inner {
    display: grid;
    gap: $grid-gap;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
    padding-left: 8.888%;

    @include min-screen($tablet-s) {
      grid-template-columns: 7fr 5fr;
      grid-template-rows: 1fr;
      padding-left: 0;
    }

  }

  &__image {
    text-align: center;

    @include min-screen($tablet-s) {
      padding: 0 8.888%;
    }
    @include min-screen($desktop) {
      padding: 0 16.666%;
    }
  }

  &__content {

    .h--m {
      margin-bottom: 18px;

      @include min-screen($tablet-s) {
        margin-bottom: 24px;
      }
    }

    p {
      margin-bottom: 20px;

      @include min-screen($tablet-s) {
        margin-bottom: 40px;
      }
    }
  }

  &__tags {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    @include min-screen($tablet-s) {
      margin-bottom: 40px;
    }
  }

  &__results {
    margin-bottom: 24px;

    @include min-screen($tablet-s) {
      margin-bottom: 42px;
    }
    
    ul {
      display: flex;
      flex-direction: column;
    }

    .c-list-icon-tick {
      margin-top: 18px;
    }
  }

  &.is-visible {
    &:before {
      background-color: $fm-black;
    }
  }
}