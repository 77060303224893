.h--xl {
  @include h--xl;
}

.h--xl-light {
  @include h--xl;
  font-weight: 200;
}

.h--l-regular {
  @include h--l();
  font-weight: 400;
}

.h--l-light {
  @include h--l();
  font-weight: 250;
}

.h--m {
  @include h--m();
}

.h--m-light {
  @include h--m();
  font-weight: 200;
}

.h--s-medium {
  @include h--s();
  font-weight: 500;
}

.h--s-regular {
  @include h--s();
  font-weight: 400;
}

.h--s-light {
  @include h--s();
  font-weight: 250;
}


.h--xs {
  @include h--xs();
}

.b--m {
  @include b--m;
}

.b--l {
  @include b--l;
}

ul.l--normal {
  margin-left: 20px;
  list-style: disc;
}

.btn--m {
  @include btn--m;
}

.text--gradient {
  @include text--gradient();

  &--light {
    @include text--gradient-light();
  }
}
