.s-career-job-offers {
    padding-block: 40px;

    @include min-screen($tablet) {
        padding-block: 80px;
    }

    &--title {
        @include h--l();
        font-weight: 250;
        margin-bottom: 16px;

        b {
            font-weight: 400;
        }
    }

    &--subtitle {
        color: $fm-black;
        font-family: Aeonik;
        font-size: 20px;
        font-weight: 250;
        line-height: 26px;
        max-width: 840px;
    }
}

.s-carrer-job-offers__tags {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 12px;
    margin-block: 40px;

    @include min-screen($tablet) {
        margin-block: 60px 80px;
        row-gap: 20px;
    }

    span {

        &:hover {
            cursor: pointer;
        }
    }
}

.s-carrer-offers {
    display: flex;
    flex-direction: column;
    row-gap: 14px;

    &--button {
        width: fit-content;
        background-color: transparent;
        align-self: center;
        color: $fm-black;
    }
}

.s-carrer-offer__tile {
    text-decoration: none;
    border: 1px solid $fm-smoke;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 120px;
    padding-left: 8%;

    @include min-screen($tablet) {
        align-items: center;
        height: 105px;
        padding-left: 8%;
        display: grid;
        grid-template-columns: 65% 35%;
    }

    @include min-screen($desktop-xl) {
        height: 140px;
    }

    &--title {
        @include h--s();
        font-weight: 500;
        width: 300px;
        word-break: break-word;
        @include min-screen($tablet) {
            width: 400px;
        }
    
    }

    &--salary {
        @include h--s();
    }

    &--location {
        @include h--xs();
        color: $fm-grey;
        text-transform: uppercase;
    }

    &:hover {
        h5 {
            text-decoration: underline;
        }
    }
}
