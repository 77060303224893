.floating-button-container {
    display: none;
    position: fixed;
    bottom: 60px;
    right: 20px;
    width: 419px;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 35px 35px 32px 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 99999999999999;
    

    &.popup_form {
        width: auto;
        padding:0;
        border-radius: 28px;
        right: 80px;
        background-color: transparent;

    }

    .subscribe-text {
        p {
        font-size: 24px;
        line-height: 28px;
        color: #000;
        margin-bottom: 38px;
        font-weight: 250;
        strong, b {
            font-weight: 400;
        }
    }
        
    }

    .subscribe-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border-radius: 50px;
        padding: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        margin-bottom: 29px;
    }
    .email-input {
        border: none;
        outline: none;
        padding: 10px 20px;
        border-radius: 50px;
        flex-grow: 1;
        font-size: 16px;
        color: #666;
        background-color: transparent;
        width: 265px;

    }
    
    .email-input::placeholder {
        color: #999;
    }

    .submit-btn {
        background-color: #000;
        color: white;
        border: none;
        outline: none;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        transition: background-color 0.3s;
        padding: 6px 1px;
    }
    
    .submit-btn:hover {
        background-color: #333;
    }


    .s-newsletter__checkbox-control {

        font-family: Aeonik;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #343434;    
    }
    .s-newsletter__form-checkbox {
            display: none;
    }
    .s-newsletter__form-checkbox .active {
        display: flex;
}

.floating-thanks {
    display: none;
        .text--gradient {
            font-family: Aeonik;
            font-size: 40px;
            font-weight: 500;
            line-height: 48px;
            text-align: left;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text !important;
            color: transparent;
            background: linear-gradient(90.02deg, #F6B2A2 0.02%, #E84230 4.6%, #5F113D 12.73%, #80123D 14.76%, #253788 26.75%, #0096C7 40.41%, #5CC2D7 45.92%);
        }

        p {
            font-family: Aeonik;
            font-size: 24px;
            font-weight: 250;
            line-height: 28px;
            letter-spacing: -0.002em;
            text-align: left;
            padding-top: 20px;

            strong, b {
                font-weight: 400;
            }
        }

}
}