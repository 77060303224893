.insights-block-featured {
  display: grid;
  overflow: hidden;
  margin-bottom: 48px;

  @include min-screen($tablet-s) {
    border: 1px solid $fm-transparent-40;
    border-radius: 8px;
    grid-template-columns: 1fr 1fr;
  }
}

.insights-block-featured__image {
  overflow: hidden;

  img {
    width: 100%;
    height: 362px;
    object-fit: cover;
    display: block;
    border-radius: 8px;

    @include min-screen($tablet-s) {
      border-radius: 0;
    }
  }
}

.insight-block__header {
  color: $fm-white;
}

.insights-block-featured__body {
  padding-top: 24px;
  display: flex;
  flex-direction: column;

  @include min-screen($tablet-s) {
    padding: 40px;
  }
}

.insight-block__heading {

  .insights-block-featured & {
    font-size: 35px;
    line-height: 42px;
    height: calc(42px * 3);
    color: $fm-white;
  }
}
