

.s-raport {
    padding-top: 80px;
  
    @include min-screen($tablet-s) {
      padding-top: 120px;
    }
    @include min-screen($desktop-s) {
      padding-top: 160px;
    }
  
    .base-container {
      @include min-screen($tablet) {
        padding-left: 40px;
      }
    }

    &__header {
      @include min-screen($tablet-s) {
        margin-bottom: 60px;
      }
    }
  
    &-item {
      display: flex;
      flex-direction: column;

      &:first-of-type {
        h3:first-of-type {
          margin-top: 10px;
        }
      }
  
      @include min-screen($tablet-s) {
        flex-direction: row;
        margin-bottom: 80px;
      }
  
      @include min-screen($desktop-s) {
        margin-bottom: 160px;
      }
  
      &__image {
        text-align: center;
        margin-top: 100px;
  
        @include min-screen($tablet-s) {
          margin-top: 0;

          margin-right: 48px;
        }
  
        @include min-screen($desktop-s) {
          margin-right: 120px;
        }

        &-right {
          @include min-screen($tablet-s) {
            margin-right: 0px;
            margin-left: 48px;
          }
    
          @include min-screen($desktop-s) {
            margin-right: 0px;
            margin-left: 120px;
          }

        }
  
        img {
  
          @include min-screen($tablet-s) {
            width: 295px;
            object-fit: cover;
          }
  
          @include min-screen($desktop-s) {
            width: 504px;
            height: 504px;
          }
        }
      }
  
      &__text {
        flex: 1;
  
        h3 {
          margin: 40px 0;
        }
  
        > p {
          margin-bottom: 32px;
        }
  
        ul {
          li {
            display: inline-flex;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 18px;
  
            &:before {
              content: '';
              background: url("../images/icon-tick.svg") 50% 50% / contain no-repeat;
              display: inline-block;
              min-width: 24px;
              min-height: 24px;
              margin-right: 16px;
              margin-top: 4px;
            }
          }
        }
  
        .c-button {
          margin-bottom: 48px;
          
          @include min-screen($tablet-s) {
            margin-bottom: 0;
            margin-top: 16px;
          }
        }
        &--position {
          color: $fm-grey;

        }
      }
  
      &__summary {
        background-color: $fm-alabaster;
        padding-top: 64px;
        padding-bottom: 64px;
        margin-top: 100px;

        p {
          font-family: Aeonik;
          font-size: 20px;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: left;
          @include min-screen($tablet-s) {
            max-width: 70%;
          }
        }

        @include min-screen($tablet-s) {
          margin-top: 0;
          padding-top: 96px;
          padding-bottom: 96px;
        }
      }

      &--reversed {
        flex-direction: column-reverse;
        @include min-screen($tablet-s) {
          flex-direction: column;
        }
        @include min-screen($tablet-s) {
          flex-direction: row;
        }
      }
  
      h2 {
        margin-bottom: 24px;
      }

      &--center {
        flex-direction: column;
        justify-content: center;
        margin-top:80px;

        @include min-screen($tablet-s) {
         margin-top:0;
        }
      }
    }
    &-cta {
      background-image: url("../images/CTA-raport-mlodzi-mobile.webp");
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 50px;
      padding-bottom: 50px;
      background-position: center;

      h2,h3 {
        color: white;
      }
    
      h2 {
        margin-bottom: 40px;
      }

      h3 {
        margin-bottom: 53px;
        max-width: 50%;
      }


      @include min-screen($desktop-s) {
        padding-top: 150px;
        height: 632px;
        background-image: url("../images/CTA-raport-mlodzi.webp");
        h3 {
          max-width: 100%;
        }
      }
    }
    &__author {
      width: 70%;
    }

    &-patronage {
      display: flex;
      gap: 50px;
      margin-top: 32px;


      @include min-screen($tablet-s) {
        gap: 100px;
        margin-top: 128px;
      }
      @include min-screen($desktop-s) {
        margin-top: 0;
      }

      

      &__item {
        padding-top: 56px;
        padding-bottom: 56px;
     
        .logos {
          display: flex;
          gap: 30px;

          img {
            max-width: 90px;
          }

          @include min-screen($tablet-s) {
            gap: 50px;
            img {
              max-width: 120px;
            }
          }
        }

        h3 {
          color: $fm-grey;
          margin-bottom: 36px;
        }

      }
    }

    &__form {
      flex:1;

      &-text {
        margin-bottom: 56px;
      }
    }

    .quote-text {
      padding-left: 70px;
      h2 {
        @include h--m();
        @include min-screen($tablet-s) {
          @include h--l();
          font-weight: 400;       
         }
     
      }
    }

    .quote-tick {
      font-family: Aeonik;
      font-size: 158px;
      font-weight: 400;
      line-height: 136px;
      letter-spacing: -0.01em;
      text-align: left;
      position: absolute;
      left: 0px;
    }
    .quote {

      @include min-screen($tablet-s) {
        max-width: 85%;
      }
    }

    .gradient-line {
      height: 4px;
      width: 100%;
      background-color: white;
      margin-top: 60px;
      margin-bottom: 25px;
      background-image: linear-gradient(89.04deg, #F7AE9D 0.62%, #D64F3C 15.43%, #5C153B 47.74%, #561D5C 54.8%, #152A9E 80.24%, #1394C3 93.11%, #07C7DF 97.37%);
    }
  }
  
  .text--gradient-purple {
    background: linear-gradient(89.04deg, #5C153B 47.74%, #561D5C 54.8%), linear-gradient(0deg, #151515, #151515);
    @include gradient-text();

  }

  .text--gradient-blue {
    background: linear-gradient(89.04deg, #152A9E 80.24%, #1394C3 93.11%, #07C7DF 97.37%), linear-gradient(0deg, #151515, #151515);
    @include gradient-text();
  }

  .text--gradient-orange {
    background: linear-gradient(89.04deg, #F7AE9D 0.62%, #D64F3C 15.43%), linear-gradient(0deg, #151515, #151515);
    @include gradient-text();
  }


  .center-image {
    padding-top: 80px;

    @include min-screen($tablet-s)  {
      padding-top: unset

    }
}
