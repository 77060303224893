.s-case-study-services {
  display: grid;
  gap: $grid-gap;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  margin: 40px auto;

  @include min-screen($tablet) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 80px auto;
}

@include min-screen($desktop-xl) {
      margin: 160px auto;
  }

  &__top {
    h3 {
      span {
        @include text--gradient-light();
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 24px 0;
    background-color: $fm-alabaster;

    @include min-screen($tablet) {
      min-height: 560px;
    }

    @include min-screen($desktop-xl) {
      min-height: 630px;
    }

    &:first-child {
      padding: 32px;
      background-color: $fm-black;
      color: $fm-white;
    }

    p {
      margin-bottom: 32px;
    }
  }

  &__heading {
    display: inline-flex;
    align-items: center;
    margin-bottom: 48px;
    text-decoration: none;

    &__heading-arrow {
        display: inline-block;
        width: 30px;
        height: 15px;
        margin-top: 15px;
        margin-left: 15px;
        background-repeat: no-repeat;
        background-position: left;
        transition: background-position .3s ease-out;
        background-image: url('../images/arrow-black.svg');
      }
  }

  .service {
    @include h--xs();
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 24px;
    font-family: $secondary-font-family;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid $fm-black;

    &__heading-arrow {
      display: inline-block;
      width: 30px;
      height: 15px;
      margin-left: 15px;
      background-repeat: no-repeat;
      background-position: left;
      transition: background-position 0.3s ease-out;
      background-image: url("../images/arrow-black.svg");
    }
  }
}
