
@mixin sliderGrabCursor($breakpoint: $tablet) {
    @include min-screen($breakpoint) {
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
  
        &:active {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      }
}