.patronage {
    background-color: $fm-black;
    padding-block: 56px;
 
    @include min-screen($phone) {
        margin-bottom: 60px;
    }

    @include min-screen($tablet) {
        margin-bottom: 80px;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 32px 96px;

        &__item {
            &__logos {
                display: flex;
                gap: 30px;

                @include min-screen($tablet-s) {
                    gap: 50px;
                }

                img {
                    max-width: 90px;

                    @include min-screen($tablet-s) {
                        max-width: 120px;
                    }
                }
            }

            &__label {
                color: $fm-grey;
                margin-bottom: 32px;
                @include h--xs();

                @include min-screen($tablet) {
                    font-size: 15px;
                }
            }
        }
    }
}
