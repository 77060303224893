.s-project-testimonial {
  max-width: 710px;
  color: white;
  margin: 0 auto;
}

.s-project-testimonial__border {
  display: block;
  width: 100%;
  height: 4px;
  margin-bottom: 16px;
  background: linear-gradient(89.04deg, $main-gradient-1 0.62%, $main-gradient-2 15.43%, $main-gradient-3 47.74%, $main-gradient-4 54.8%, $main-gradient-5 80.24%, $main-gradient-6 93.11%, $main-gradient-7 97.37%);
}

.s-project-testimonial__content {
  @include formatRichText();
}

.s-project-testmionial__person {
  display: flex;
  align-items: center;
}

.s-project-testmionial__person-img {
  margin-right: 16px;
  img {
    border-radius: 50%;
    width: 64px;
    height: 64px;
  }
}

.s-project-testimonial__company-position {
  margin-top: 4px;
  color: $fm-grey;
}

.s-career-testimonials {
  b {
    font-weight: 400;
  }

 #tns1 > .tns-item {
  padding-right: 24px;
 }

 @include min-screen($tablet) {
  margin-block: 80px 0px;
}
}