.s-gradient-hero-scene {
  background-color: $fm-black;
  color: $fm-white;
  text-align: center;
  padding: 74px 0;

  a {
    margin-top: 40px;
  }

  span:not(.u--nowrap) {
    @include text--gradient-light();
  }

  @include min-screen($tablet-s) {
    padding: 148px 0;
  }

  @include min-screen($desktop-xl) {
    a {
      margin-top: 60px;
    }
  }
}

.s-hradient-hero-scene--grey {
  background-color: $fm-alabaster;
  color: $fm-black;

  span {
    @include text--gradient();
  }

  @include min-screen($tablet-s) {
    padding: 86px 0;
  }
}

.s-gradient-hero-scene--to-hide {
  padding: 74px 0 37px;
  transition: all 0.3s ease;

  @include min-screen($tablet-s) {
    padding: 148px 0 74px;
  }

  & + .s-insights-filter {
    padding-top: 37px;

    @include min-screen($tablet-s) {
      padding-top: 74px;
    }
  }
}
