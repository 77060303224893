.c-portfolio-cooperation-story {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 40px;

  @include min-screen($tablet-s) {
    flex-direction: row;
  }


  &__header {
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    height: 294px;

    .homepage-case-study & {
      height: 260px;
      max-height: unset !important;
      

      @include min-screen($tablet-s) {
        height: 200px;
        max-width: 450px !important;
      }
    }

    &__title {
      @include btn--m();
      font-family: $secondary-font-family;
      text-transform: uppercase;
      font-size: 13px;
      margin-bottom: 32px;
    }

    &__logo {
      margin-bottom: 24px;

      img {
        display: block;
        max-width: 200px;
        height: 100%;
      }
    }

    &__desc {
      @include h--s-desktop();

      p {
        color: $fm-grey;
      }

      .c-button {
        margin-top: 32px;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 50px;
    width: 100%;

    @include min-screen($tablet) {
      row-gap: 80px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 675px;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;


    @include min-screen($tablet) {
      row-gap: 40px;
      align-items: flex-start;
    }
   

    &:nth-child(odd) {
      @include min-screen($tablet) {
        margin-top: -385px; 
      }
    }

    &:nth-child(3) {
      @include min-screen($tablet) {
        margin-top: -400px; 
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__thumbnail {
    img {
      height: 246px;
      width: 315px;
      object-fit: cover;

      @include min-screen($tablet) {
        height: 515px;
        width: 100%;
      }

    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}


.js-cooperation-stories-container {
  margin-top: 80px;

  .base-container {
    &:not(:first-of-type) {
      margin-top: 80px;

      @include min-screen($tablet) {
        margin-top: -285px;
      }
    }
  }

}

.s-what-weve-done__portfolio {
  .c-portfolio-cooperation-story__list:has(.c-portfolio-cooperation-story__list-item:nth-child(odd):last-child) {
    @include min-screen($tablet) {  
      margin-bottom: -500px;
    }
  }

  margin-top: 80px;
  @include min-screen($tablet) {  
    margin-top: -285px;
  }

  .c-portfolio-cooperation-story__list {
    grid-auto-rows: 875px;

    &-item {
      &:nth-child(3) {
        @include min-screen($tablet) {
          margin-top: -600px; 
        }
      }

      &:nth-child(odd) {
        @include min-screen($tablet) {
          margin-top: -585px; 
        }
      }
    }
  }
  
  .c-portfolio-cooperation-story__thumbnail {
    img {
      @include min-screen($tablet) {
        height: 720px;
      }
    }
  }

  

  &.no-margin {
    margin-top: 0;
  }

  .c-portfolio-cooperation-story__header {
    max-height: 240px;

    @include min-screen($tablet) {
      max-width: 350px;
    }

  }

  .c-portfolio-cooperation-story__header__logo {
    img {
      width: 88.65px;
      height: 54px;
    }
  }
}

.homepage-case-study {
  .c-portfolio-cooperation-story__header__logo {
    img {
      width: 88.65px;
      height: 54px;
    }
  }
}


.homepage-case-study-title {
  margin-top: 100px;
}

.homepage-case-studies-button {
  margin: 80px 0 0 0  !important;

  @include min-screen($tablet) {
    margin: 0 !important;
  }

}