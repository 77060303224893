.s-advisory__conctact {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding-block: 40px;

    @include min-screen($tablet) {
        flex-direction: row;
        padding-block: 80px;
        column-gap: clamp(2.5rem, -8.75rem + 18.75vw, 6.25rem); // $tablet- 40px => $desktop-s - 100px
    }

    @include min-screen($desktop-s) {
        column-gap: clamp(6.25rem, -12.7976rem + 23.8095vw, 12.5rem); // $desktop-s- 100px => $desktop-xl - 200px
    }
}

.s-advisory__contact-person {
    display: flex;
    flex-direction: column;
    max-width: 700px;


    @include min-screen(620px) {
        flex-direction: row;
        justify-content: space-between;
        margin-inline: auto;
        width: 100%;
    }

    @include min-screen($tablet) {
        max-width: fit-content;
        flex-direction: column;
        justify-content: unset;
        margin-inline: unset;
    }

    &--left-container {
        display: flex;
        flex-direction: column;
        max-width: fit-content;

        &--title {
            margin-bottom: 16px;
        }

        &--desc {
            font-size: 28px;
            line-height: 35px;
            font-weight: 400;
            color: $fm-black;
            margin-bottom: 24px;
            max-width: 350px;
        }

        &--email {
            font-size: clamp(1.5rem, 0.6111rem + 4.4444vw, 2rem);
            line-height: 38px;
            font-weight: 400;
            @include text--gradient();
            margin-bottom: 32px;
            cursor: pointer;

            &:hover {
                text-align: underline;
            }
        }
    }

    &--right-container {
        display: flex;
        flex-direction: column;

        &--image {
            width: 180px;
            height: 100%;
            max-height: 275px;
            border-radius: 6px;
            object-fit: cover;
            margin-bottom: 24px;
            align-self: center;

            @include min-screen($tablet) {
                width: 256px;
                align-self: flex-start;
            }
        }

        &--name {
            font-size: 30px;
            margin-bottom: 8px;
        }

        &--position {
            @extend .c-tag--text-grey;
            font-size: 20px;
            text-transform: uppercase;
        }
    }
}

.s-advisory__contact-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    input, textarea {
        font-size: inherit;
    }

    @include min-screen($tablet) {
        justify-content: flex-start;
    }
}

.s-advisory__contact-form__service-page {
    margin-right: 0 !important;

    .s-advisory-contact-form {
        @include min-screen($desktop-l) {
            max-width: unset;
        }
    }
}
