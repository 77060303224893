.s-case-study-video {
  position: relative;
  text-align: center;
  margin-top: -2px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $fm-white;
  }

  video {
    position: relative;
    z-index: 1;
    display: block;
    clip-path: inset(12% 0 12% 0);

  }
}
