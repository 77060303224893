.s-case-studies-recent {
    padding-block: 40px;

    @include min-screen($tablet) {
        padding-block: 80px;
    }

    h2 {
        margin-bottom: 55px;
        
        p {
            @extend .h--l-regular;
        }
    }

    p {
        @include b--m();
    }

     p:empty {
        display: none;
    }
}

.s-case-studies-recent--grid {
    padding-block: 55px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    column-gap: 14px;
    row-gap: 26px;

    @include min-screen($desktop-s) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.s-case-studies-recent__item {
    text-decoration: none;
    div {
        overflow: hidden;
        height: 210px;
        margin-bottom: 12px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            @include animationZoomOnHover;
        }
    }

    h3 {
        @include b--m;
    }

    > p {
        margin-bottom: 4px;
        @extend .c-tag--text-grey;
    }

    &:hover {
        cursor: pointer;
    }
}

.s-case-studies-recent--button {
    display: flex;
    justify-content: center;

    .c-button {
        margin-top: 0;
    }
}
