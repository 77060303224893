.client-reviews-section {
    padding-block: 65px 100px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    justify-content: space-between;
    max-width: 750px;
}


.client- {
    .testimonial-item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $fm-smoke;
        margin-bottom: 80px;
        column-gap: 120px;
        row-gap: 40px;

        padding-bottom: 80px;
      
        @include min-screen($tablet-s) {
          flex-direction: row;
        }


        .testimonial-logo {
            min-width: 300px;
            max-width: 300px;
            &-description {
                padding-top: 40px;
                color: $fm-grey;
            }
        }

        .testimonial-author {
            display: flex;
            align-items: center;
            padding-block: 40px;
        }

        .testimonial-quote {
            &-text {
                display: flex;
                flex-direction: column;
                gap: 32px;
            }
            
            .quote-gradient {
                display: flex;
                align-items: center;
            }

            .gradient-line {
                height: 4px;
                width: 100%;
                background-color: white;
                margin-top: -50px;
                margin-bottom: 0px;
                background-image: linear-gradient(89.04deg, #F7AE9D 0.62%, #D64F3C 15.43%, #5C153B 47.74%, #561D5C 54.8%, #152A9E 80.24%, #1394C3 93.11%, #07C7DF 97.37%);
            }

            .quote-tick {
                font-family: Aeonik;
                font-size: 158px;
                font-weight: 400;
                line-height: 136px;
                letter-spacing: -0.01em;
                text-align: left;
                position: absolute;
                position: relative;
                margin-right: 30px;
            }

            .testimonial-quote-text {
                color: $fm-grey;
            }

        }
    }
}
