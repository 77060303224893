.s-lifestyle-gallery {
  margin-bottom: 100px;
  position: relative;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    height: 200px;
    width: auto;
    object-fit: cover
  }
  
  @include min-screen($phone) {
    img {
      height: 300px;
    }
  }

  @include min-screen($tablet-s) {
    img {
      height: 400px;
    }
  }

  .tns-item {
    @include sliderGrabCursor()
  }
  
}

.s-lifestyle-gallery--visible {
  visibility: visible;
  opacity: 1;
}
