$navbar-heights: (
  $phone: 70px,
  $tablet: 75px,
  $desktop: 95px,
  $desktop-l: 125px,
  $desktop-xl: 136px,
);

.sticky-header__container {
  height: 50px;

  @each $size, $height in $navbar-heights {
    @include min-screen($size) {
      height: $height;
    }
  }

  &--black-bg {
    background-color: $fm-black;
  }

  &--transparent-bg {
    background-color: transparent;
  }

  &--insights-bar-expanded {
    background: transparent;
  }
  
}

.sticky-header {
  position: fixed;
  width: 100%;
  z-index: 20;
  top: 0;
  transition: all $transition-time $transition-easing;

  @include min-screen($tablet) {
    width: calc(100% - $insight-bar-width);
  }

  @include min-screen($desktop-xl) {
    width: calc(100% - $insight-bar-width-desktop-xl);
  }
}

.header__mobile-nav-open {
  background: transparent !important;
  backdrop-filter: blur(0px);
  transition-duration: 0.5s;
}

.insights-bar-header {
  position: static;
  top: 0;
  width: 100%;
}

.header {
  .contact-form--sent &,
  & {

    color: $fm-white;
    padding-top: 20px;
    position: relative;
    padding-bottom: 10px;

    @include min-screen($desktop) {
      padding-top: 40px;
    }

    @include min-screen($desktop-l) {
      padding-top: 60px;
    }

    @include min-screen($desktop-xl) {
      padding-top: 80px;
    }

    a {
      color: $fm-white;
      text-decoration: none;
      font-size: 15px;
    }

    .header__logo {
      display: block;
      background-size: contain;

      img {
        width: 38px;
        height: 24px;
        
        @include min-screen($desktop-l) {
          width: 47px;
          height: 30px;
        }

        @include min-screen($desktop-xl) {
          width: 53px;
          height: 31px;
        }
      }
    }

    .header__name {
      display: none;
      @include min-screen($desktop-s) {
        display: block;
      }
      align-self: center;
      img {
        width: 129px;
        height: 35px;
        @include min-screen($desktop-l) {
          width: 205px;
          height: 55px;
        }
      }
    }
  }
}

.header--black {
  color: $fm-black;

  a {
    color: $fm-black;
  }

  ul.header__main-nav li.header__main-nav-element:after {
    border-bottom: 1px solid $fm-black;
  }
}

.header--insights {
  background: transparent;
  padding-bottom: 0;
  z-index: -1;

  ul.header__main-nav {
    visibility: hidden;
  }

  div.header__mobile-items {
    display: none;
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between
}

ul.header__main-nav {
  display: none;
  user-select: none;
  @include min-screen($tablet) {
    display: initial;
  }

  margin-top: 10px;

  li {
    display: inline-block;
  }

  li.header__main-nav-element {
    margin-right: 45px;
    cursor: pointer;

    > a {
      display: flex;
      height: 100%;
      padding-bottom: 10px;
    }

    &:after {
      display: block;
      content: '';
      border-bottom: 1px solid $fm-white;
      transform: scaleX(0);
      transition: transform $transition-time $transition-easing;
      transform-origin: 0% 50%;
    }

    &:hover:after {
      transform: scaleX(1);
    }
  }

  li.header__main-nav-element--active {
    &:after {
      border-bottom: 1px solid $fm-white;
      transform: none;
    }
  }

    li.header__contact-nav-element {
      > a {
        @extend .c-button--iron;
        font-size: 13px;
        font-family: $secondary-font-family;
        border-radius: 28px;
        padding: 16px 32px;
        text-transform: uppercase;
        border: none;
      }

      &--smoke {
        > a {
          background-color: $fm-smoke;
          color: $fm-black;

          &:hover {
            background-color: $fm-iron;
            color: $fm-white;
          }
        }
      }
    }
}

.header__dropdown-content {
  @include baseContainerMaxWidth();
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding-top: 21px;
  z-index: 5;
}

.header__dropdown-content--visible {
  visibility: visible;
  opacity: 1;
}

.header__mobile-items {
  display: flex;
  align-items: center;
  @include min-screen($tablet) {
    display: none;
  }
}

.header__mobile-insights {
  background: none;
  border: none;
  color: $fm-white;
  @include colors-gradient(270deg);
  padding: 5px 10px;
  border-radius: 28px;
  margin-right: 20px;
  z-index: 10;
  width: 102px;

  @include min-screen($phone) {
    padding: 10px 15px;
    margin-right: 40px;
  }
}

.header__hamburger {
  display: block;
  position: relative;
  width: 70px;
  height: 12px;
  background: none;
  border: none;
  z-index: 10;

  span {
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: $fm-white;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      bottom: 0;
    }
  }
}

.header__hamburger--white {
  span {
    background-color: $fm-white !important;
  }
}

.header__hamburger--black {
  span {
    .contact-form--sent & {
      background-color: $fm-white;
    }
    & {
      background-color: $fm-black;
    }
  }
}

.header__hamburger--open {
  span {
    background-color: $fm-black !important;

    &:nth-child(1) {
      top: 5px;
      -webkit-transform: rotate(15deg);
      -moz-transform: rotate(15deg);
      -o-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    &:nth-child(2) {
      top: 5px;
      -webkit-transform: rotate(-15deg);
      -moz-transform: rotate(-15deg);
      -o-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }
  }
}

.hide-on-load {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}