@mixin insightsBarWidth() {
  width: $insight-bar-width;
  @include min-screen($desktop-xl) {
    width: $insight-bar-width-desktop-xl;
  }
}

.insights-bar {
  display: none;
  @include secondary-font;
  @include colors-gradient(0deg);
  @include insightsBarWidth();
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: rotate(-180deg);
  color: $fm-white;
  cursor: pointer;
  z-index: 2;
  font-size: 13px;

  @include min-screen($tablet) {
    display: flex;
  }

  @include min-screen($desktop) {
    font-size: 14px;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 15%;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
    @include insightsBarWidth();
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 15%;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
    @include insightsBarWidth();
  }

  &:hover::after, &:hover::before {
    background: none;
  }
}

.insights-bar--blue {
  @include colors-gradient-blue(0deg);
}

.insights-bar--close {
  background: transparent;
  border-left: 1px solid $fm-transparent-2;

  &::after, &::before {
    display: none;
  }
}

.insights-bar__arrow {
  position: absolute;
  top: 30px;
  right: 14px;
  width: 20px;
  height: 10px;
  background-image: url('../images/arrow-white.svg');
  background-repeat: no-repeat;
  background-position: left;
  transition: background-position .3s ease-out;
  transform: scaleX(-1);

  @include min-screen($desktop) {
    right: 16px;
  }
}

.insights-bar__arrow--inverted {
  transform: scaleX(1);
  right: 5px;

  @include min-screen($desktop) {
    right: 10px;
  }
}

.insights-bar:hover .insights-bar__arrow {
  background-position: right;
}

.s-insights-bar__posts-button-wrapper {
  @include max-screen($tablet) {
    padding-bottom: 100px;
  }
}