.c-client__logos {
    padding-block: 45px;
    background-color: $fm-alabaster;
    max-width: 100%;
    overflow: visible;
    position: relative;
    padding-left: 20px;
    

    h3 {
        margin-bottom: 32px;
    }

    &--white {
        background-color: $fm-white;
    }
}

.c-client-logos__images {
    display: flex;
    gap: 60px;
    align-items: center;
    overflow-x: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &.center-justify {
        justify-content: center;
      }

    img {
        max-height: 90px;
    }
}
