.s-mobile-insights {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  color: $fm-white;
}

.s-mobile-insights--visible {
  position: fixed;
  opacity: 1;
  height: auto;
  z-index: 6;
  width: 100vw;
  height: 100vh;
  padding-top: 20px;
  background: linear-gradient(196deg, $main-gradient-1 0%, $main-gradient-2 20%, $main-gradient-3 60%, $main-gradient-4 80%, $main-gradient-5 100%);
}

.s-mobile-insights__container {
  height: 100%; 
  padding-bottom: 50px;
}