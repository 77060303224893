.s-case-study-scope {
  margin: 40px 0;

  @include min-screen($tablet-s) {
    margin: 80px 0;
  }

  @include min-screen($desktop) {
    margin: 120px 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 120px;
  }

  &__item {
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 85px;
    border-bottom: 1px solid $fm-stone;
    gap: 30px;

    ul {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: end;
      row-gap: 10px;
      column-gap: 18px;

      @include min-screen($tablet) {
        flex-direction: row;
      }

      li {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        font-family: $secondary-font-family;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 20px;
        text-align: end;
      
        @include min-screen($phone) {
          &:not(:first-child) {
            &:before {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              background-color: $fm-black;
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
}
