.s-advisory-solutions {
  color: $fm-white;
  padding-top: 40px;
  padding-bottom: 40px;

  p {
    margin: 40px 0;
    max-width: 100%;
  }

  @include min-screen($tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
    p {
      max-width: 600px;
    }
  }
}