.s-advisory__conctact {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding-top: 40px;

    @include min-screen($tablet) {
        flex-direction: row;
        padding-block: 80px;
        column-gap: clamp(2.5rem, -8.75rem + 18.75vw, 6.25rem); // $tablet- 40px => $desktop-s - 100px
    }

    @include min-screen($desktop-s) {
        column-gap: clamp(6.25rem, -12.7976rem + 23.8095vw, 12.5rem); // $desktop-s- 100px => $desktop-xl - 200px
    }

    &.contact-popup {
        column-gap: 0;
        justify-content: space-around;
        text-align: left;
        row-gap: 40px;
        padding-left: 0;
        padding-right: 0;
        padding-block: 24px 0;

        @include min-screen($tablet) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .js-form-advisory-success-message {
            text-align: center;
            margin: auto;
        }
    }
}

.s-advisory__contact-person {
    display: flex;
    flex-direction: column;
    max-width: 700px;

    @include min-screen(620px) {
        flex-direction: row;
        justify-content: space-between;
        margin-inline: auto;
        width: 100%;
    }

    @include min-screen($tablet) {
        max-width: 450px;
        flex-direction: column;
        justify-content: unset;
        margin-inline: unset;
    }


    @include min-screen($desktop) {
        max-width: 550px;
    }

    &--left-container {
        display: flex;
        flex-direction: column;

        &--title {
            margin-bottom: 18px;
            font-size: 61px;
            line-height: 81px;
            color: $fm-black;
        }

        &--desc {
            font-size: 28px;
            line-height: 35px;
            font-weight: 400;
            color: $fm-black;
            margin-bottom: 24px;
            max-width: 330px;

        }

        &--email {
            font-size: clamp(1.5rem, 0.6111rem + 3.6vw, 2rem);
            line-height: 38px;
            font-weight: 400;
            display: inline;
            @include text--gradient();
            margin-bottom: 32px;
            width: fit-content;

            @include min-screen($tablet) {
                font-size: clamp(1.5rem, 0.6111rem + 4.4444vw, 2rem);
                line-height: 42px;
                margin-bottom: 42px;

            }
        }
    }

    &--right-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
            display: none;
        }

        @include min-screen(620px) {
            align-items: flex-start;

            img {
                display: unset;
                margin-bottom: 44px;
            }
        }

        &--image {
            width: 180px;
            height: 100%;
            max-height: 281px;
            border-radius: 6px;
            object-fit: cover;
            margin-bottom: 24px;
            align-self: center;

            @include min-screen($tablet) {
                width: 256px;
                align-self: flex-start;
            }
        }

        &--name {
            font-size: 30px;
            margin-bottom: 8px;
            color: $fm-black;
        }

        &--position {
            @extend .c-tag--text-grey;
            font-size: 20px;
            text-transform: uppercase;
        }
    }
}

.s-advisory__contact-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;

    &-hubspot {
        display: block;
    }

    input, textarea {
        font-size: inherit;
    }
    

    @include min-screen($tablet) {
        justify-content: flex-start;
    }

    .s-advisory-contact-form {
        padding: 24px;

        @include min-screen($tablet) {
            padding: 32px;
        }
    }
}

.swal2-popup {
    background-color: transparent !important;
}

.swal2-html-container {
    margin: 0 10px !important;
    background-color: transparent;

    @include min-screen($tablet) {
        margin: 18px 28px !important;
    }
}

.swal2-close {
    display: flex;
    color: white !important;
}


.swal2-container {
 max-width: 100vw;
}