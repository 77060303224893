.s-copy {
  padding-bottom: 50px;
  @include secondary-font;
  color: $fm-white;
  font-size: 15px;

  a {
    color: $fm-white;
    text-decoration: none;
  }

  @include min-screen($tablet) {
    padding-bottom: 104px;
    font-size: 13px;
  }
  
  @include min-screen($desktop-xl) {
    padding-bottom: 145px;
    font-size: 14px;
  }
}

.s-copy__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  @include min-screen($tablet) {
    grid-template-columns: 246px 504px auto;
    column-gap: 0;
  }
  @include min-screen($desktop-s) {
    grid-template-columns: 346px 584px auto;
  }
  @include min-screen($desktop-l) {
    grid-template-columns: 346px 784px auto;
  }
  @include min-screen($desktop-xl) {
    grid-template-columns: 500px 760px auto;
  }
}

.s-copy__column {
  display: flex;
  align-items: flex-end;

  a {
    text-decoration: underline;
  }
}