.s-advisory__categories-showcase {
    &--richtext {
        @include formatRichText();

        margin-bottom: 40px;

        @include min-screen($tablet) {
            margin-bottom: 60px;
        }

        @include min-screen($desktop-s) {
            margin-bottom: 80px;
        }

        p {
            font-size: 20px;
            font-weight: 250;
            line-height: 32px;
            color: $fm-black;
        }

        ul {
            padding-left: 32px;
            padding-top: 6px;

            li {
                position: relative;
                margin-bottom: 24px;
                padding-left: 44px;
                font-size: 20px;
                font-style: normal;
                font-weight: 250;
                line-height: 26px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 5px;
                    background: url("../images/icon-tick.svg") 50% 50% / contain no-repeat;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
