.s-report-quote {
    padding-block: 60px;

    @include min-screen($tablet) {
        padding-block: 80px
      }

    .quote__text {
        h2 {

            @include min-screen($tablet-s) {
                font-size: 55px;
                line-height: 72px;
            }
        }
    }

    .quote__line {
        margin-bottom: unset;
    }
}
