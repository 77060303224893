html {
  scroll-behavior: smooth;
}

body {
  background-color: $fm-white;
  font-family: $base-font-family;
  @include b--m;
  height: 100%;
  color: $fm-black;
  scroll-behavior: smooth;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

button {
  font-family: $base-font-family;
  font-size: 15px;
}

.body--noscroll {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.center-content {
  @include min-screen($tablet) {
    padding-left: 40px;
  }

  @include min-screen($desktop-xl) {
    padding-left: 55px;
  }
}

.base-container {
  @include max-screen($desktop-s) {
    padding: 0 20px;
    max-width: 100%;
  }
  @include baseContainerSize();
  position: relative;
}

.base-container--md {
  max-width: 735px;

  @include min-screen($desktop-xl) {
    max-width: 1180px;
  }
}

.base-container--lg {
  max-width: 885px;

  @include min-screen($desktop-xl) {
    max-width: 1180px;
  }
}

.base-container--padding-top {
  padding-top: 74px;

  @include min-screen($tablet-s) {
    padding-top: 148px;
  }
}

.base-container--black-bg {
  background-color: $fm-black;
  color: $fm-white;
}

.base-container--alabaster-bg {
  background-color: $fm-alabaster;
}

.base-container--transparent-bg {
  background-color: transparent;
}

.base-container--narrow {
  @include min-screen($desktop-l) {
    max-width: 1232px;
  }
}

.base-container--small {
  @include min-screen($desktop) {
    max-width: 816px;
  }
}

a {
  color: inherit;
}

.error-page {
  padding-bottom: 180px;
  color: white;
}

// hide captcha
.grecaptcha-badge { visibility: hidden; }
