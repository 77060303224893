.c-portfolio-card {

}

.c-portfolio-card__image-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  height: 515px;

  .col-big & {
    height: 720px;
  }
  
  &::before {
    content:'';
    display: block;
    padding-top: (568 / 619) * 100%;
  }

  @media only screen and (max-width: 1024px) {
    height: auto !important;
  }
}

.c-portfolio-card__image-wrapper--no-radius {
  border-radius: 0;
}

.c-portfolio-card__image-wrapper--full-screen {
  &::before {
    padding-top: (599 / 1072) * 100%;
  }
}

.c-portfolio-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  
  @media only screen and (max-width: 1024px) {
    height: auto !important;
  }
}

.c-portfolio-card__title {
  margin-top: 20px;
}

.c-portfolio-card__title--grey {
  color: $fm-grey;
  margin-bottom: 4px;
}

.c-portfolio-card__title-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.c-portfolio-card__tags {
  margin-top: 16px;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  gap: 5px;

  span, a {
    white-space: nowrap;
  }
}
