.s-report-image {

    &__desktop {
        display: none;

        @include min-screen($tablet) {
            display: block;
        }
    }

    &__mobile {
        display: block;

        @include min-screen($tablet) {
            display: none;
        }
    }
}
