.c-career-apply-form {
    background: $fm-alabaster;
    padding: 16px;
    display: flex;
    flex-direction: column;

    @include min-screen($phone) {
        padding: 36px;
    }

    .c-contact-form__submit {
        display: flex;
        width: fit-content;
    }
}
