.s-carrer-header-job-offer {
    @extend .s-header-cta, .s-header-cta--transparent-bg, .s-header-cta-gradient;

    &--title {
        @include h--l();
        padding-top: 40px;
        color: $fm-white;
    }

    &--subtitle {
        @include h--m();
        color: $fm-white;
        margin-bottom: 24px;
    }

    &--subtext {
        @include h--s();
        color: $fm-white;
        font-weight: 250;
        margin-bottom: 20px;
    
        b {
            font-weight: 400;
        }
    }

    .offer-button {
        margin-bottom: 20px;
        @include min-screen($tablet) {
            margin-bottom: 80px;
        }
    }
}
