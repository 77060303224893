.s-contact-offices {
    display: flex;
    flex-direction: column;
    margin-block: 20px 40px !important;

    @include min-screen($desktop-s) {
        display: grid;
        grid-template-columns: 1fr 33%;
    }
}

.s-contact-offices__image {
    overflow: visible;
    background: var(--quote-bg) center;
    background-size: cover;
    z-index: 1;
    width: auto;
    height: 500px;

    @include min-screen($desktop-s) {
        height: auto;
    }
}

.s-contact-offices__list {
    display: grid;
    grid-template-areas:
        "title"
        "offices";
    column-gap: 40px;
    row-gap: 56px;
    padding-block: 60px;
    padding-inline: 20px;
    background: $fm-alabaster;

    &--title {
        font-size: 35px;
        line-height: 42px;
        grid-area: title;
        font-weight: 250;

        b {
            font-weight: 500;
        }
    }

    &--offices {
        grid-area: offices;
        display: flex;
        flex-wrap: wrap;
        column-gap: 42px;
        row-gap: 32px;

        @include min-screen($desktop-s) {
            row-gap: 42px;
            flex-direction: column;
        }
    }

    @include min-screen($desktop-s) {
        padding-block: 72px;
        padding-inline: 104px;
    }
}

.s-contact-offices__office {
    &--title {
        @include h--s();
        margin-bottom: 16px;
    }

    p {
        @include h--xs();
    }
}