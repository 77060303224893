.s-career-company-values {
    background: no-repeat center url('../images/career-hero.png');
    background-size: cover;
    padding-block: 80px 100px;
    position: relative;
    overflow: hidden;
    
    &--content {
        display: flex;
        flex-direction: column;
        gap: 60px;
        color: white;

        h2 {
            @include h--l();
            font-weight: 250;
            b {
                font-weight: 400;;
            }
        }
    }
    .values {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        column-gap: 107px;
        row-gap: 80px;

        h3 {
            @include h--m();
            margin-bottom: 10px;
        }

        .item-text {
            font-family: Aeonik;
            font-size: 13px;
            font-weight: 400;
            line-height: 20.8px;
            letter-spacing: 0.01em;
            text-align: left;
        }

        @include min-screen($tablet) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;        
        }
    }

    .closing-remarks {
        @include h--m();

        .text--gradient {
            background: linear-gradient(90deg, #F6B2A2 1%, #E84230 10.74%, #AF2272 28.06%, #9F154B 32.39%, #804CBB 45.82%, #4060F2 57.94%, #0096C7 87.05%, #5CC2D7 98.78%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-box-decoration-break: clone;

        }

    }
}