.cookies-info {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 10px;
  width: 250px;
  z-index: 8;
  color: $fm-white;
  font-family: $secondary-font-family;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  background-color:rgba(0, 0, 0, 0.6);
  border: 1px solid $fm-transparent-2;
  border-radius: 7px;

  @include min-screen($phone) {
    width: 300px;
  }

  @include min-screen($tablet) {
    bottom: 30px;
    right: 30px;
    padding: 20px;
    width: 300px;
    font-size: 15px;
    line-height: 20px;
  }

  span {
    margin-top: 10px;
    float: right;
    cursor: pointer;
  }
}