.s-report-feature {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    margin-bottom: 40px;

    @include min-screen($desktop-s) {
        margin-bottom: 80px;
    }

    &__headings {
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        h3 {
            max-width: $tablet-s;
        }
    }

    &__tiles {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 40px;

        @include min-screen($tablet-s) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 120px;
        }
    }
}

.s-report-tile {
    display: flex;
    column-gap: 34px;

    img {
        align-self: flex-start;
        margin-top: 3px;
    }

    &__headings {
        display: flex;
        flex-direction: column;
        row-gap: 20px
    }
}