.s-thank-you-page__container {

}

.s-thank-you-page__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    row-gap: 40px;
    padding-block: 60px;
    text-wrap: balance;

    &--title {
        @include h--xl;
    }

    &--text {
        @include h--m;
    }

    @include min-screen($tablet) {
        padding-block: 100px;
    }

    @include min-screen($desktop-s) {
        padding-block: 140px;
    }
}

.s-thank-you-page {
    display: flex;
    flex-direction: column-reverse;
    padding-block: 80px;
    row-gap: 60px;
    align-items: center;

    img {
        object-fit: contain;
        display: flex;
    }

    @include min-screen($desktop-s) {
        flex-direction: row;
        justify-content: space-between;
        align-items: unset;
        column-gap: clamp(3.75rem, -13.2692rem + 23.0769vw, 7.5rem);

        img {
            max-width: unset;
            max-height: 800px;
        }
    }

    @include min-screen($desktop-s) {
        padding-block: 120px;
    }

    &__form-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 40px;

        @include min-screen($tablet) {
            width: auto;
        }
    }
}

.s-thank-you-page__insights {
   
    .s-insights-recent {                
        h2 {
            p {
                @include h--l;
                margin-top: unset;
            }
        }
    }
}