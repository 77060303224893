.s-center-image {
    padding-block: 60px;

    @include min-screen($tablet) {
        padding-block: 80px;
    }

    img {
        width: 100%;
        height: auto;
    }
}
