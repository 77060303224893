.s-case-study-objective {
  background-color: $fm-white;
  margin: 40px 0;

    @include min-screen($tablet-s) {
        margin: 80px 0;
    }
    
    @include min-screen($desktop) {
        margin: 120px 0;
    }

  &__heading {
    margin-bottom: 60px;
  }

  &__image {
    display: flex;
    margin: 120px auto;
    border-radius: 16px;
    img {
      margin:auto;
    }
  }

  &__video {
    display: flex;
    margin: 120px auto;
    border-radius: 16px;
  
    video {
      margin: auto;
    }
  }

  p {
    @include b--l;
    margin-bottom: 20px;
  }
}
