.press-office-download__lead-text {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 50%;
  @include max-screen($phone) {
    width: 100%;
  }
}

.press-office-download__files-list {
  list-style: none;
  padding-left:0;
  margin-bottom: 64px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  a {
    font-size: 20px;
    line-height: 26px;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
      @include colors-gradient-press-office-text;
      @include gradient-text;

      .press-office-download__arrow-down {
        background-image: url('../images/press-office/arrow-down-gradient.svg');
      }
    }
  }
}

.press-office-download__number {
  width: 53px;
  font-size: 15px;
  line-height: 20px;
  font-family: $secondary-font-family;
}

.press-office-download__arrow-down {
  width: 24px;
  height: 24px;
  background-image: url('../images/press-office/arrow-down-grey.svg');
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 4px;
}

.press-office-page__download-press-kit {
  position: relative;
}

.press-office-page__download-press-kit-background {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 50%;

  @include max-screen($desktop-l) {
    width: 50%;
  }

  @include max-screen($phone) {
    display: none;
  }
}
