.s-header-cta__spacer {
  padding: 60px 0 60px 0;

  @include min-screen($tablet) {
    padding: 100px 0 60px 0;
  }

  @include min-screen($desktop-s) {
    padding: 140px 0 66px 0;
  }

  @include min-screen($desktop-xl) {
    padding-bottom: 80px;
  }
}

.s-what-we-do__hero-scene {
  .s-gradient-hero-scene {
    padding-block: 74px;
  }
}

.s-what-we-do__two-step-process {
  color: $fm-white;
  padding-block: 40px;

  @include min-screen($tablet-s) {
    padding-block: 80px;
  }
}

.s-what-we-do__two-step-process--assessment {
  margin-bottom: 65px;

  h3 {
    margin-bottom: 58px;
  }

  .row {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-inline: 0;

    @include min-screen($tablet) {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 100px;
    }

    @include min-screen($desktop) {
      justify-content: space-between;
      column-gap: 160px;
    }
  }
}

.s-what-we-do__two-step-process--assessment-item {
  h2 {
    white-space: nowrap;
  }

  @include min-screen($tablet) {
    flex: 0.5;
    max-width: 455px;
  }

  @include min-screen($desktop) {
    max-width: unset;
  }
}

.s-what-we-do__two-step-process--line {
  height: 4px;
  width: 100%;
  background-color: white;
  margin-block: 16px;
  @include colors-gradient-eb();
}

.s-what-we-do__apps-pride {
  padding-top: 48px;
  padding-bottom: 38px;
}

.s-what-we-do__apps-pride-heading {
  margin-top: 32px;
  color: $fm-stone;
}

.s-what-we-do__contact-banner {
  .s-contact-banner__spacing-top-small {
    padding-top: 64px;

    @include min-screen($tablet-s) {
      padding-top: 64px;
    }
  }
}

.s-what-we-do-branches {
  @include min-screen($tablet-s) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.s-what-we-do-branches--two-items {
  @include min-screen($tablet-s) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-what-we-do-branches--cover {
  @include min-screen($tablet-s) {
    margin-bottom: -62px;
  }
}

.s-what-we-do-branches__item {
  display: flex;
  height: 168px;
  padding: 15px 20px 24px 24px;
  background: $fm-alabaster;
  border: 1px solid $fm-white;
  flex-direction: column;
  justify-content: space-between;

  &, &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    background: #efefef;
    cursor: pointer;
  }

  @include min-screen($tablet-s) {
    height: 208px;
  }
}

.s-what-we-do-branches__item--smaller {
  height: 124px;
  padding: 15px 20px 24px 24px;
}

.s-what-we-do-branches__number {
  color: $fm-stone;
  @include b--m;
}

.s-what-we-do-branches__heading {
  @include h--s();
  color: $fm-black;
}

.s-what-we-do-branches__heading-arrow {
  display: inline-block;
  width: 30px;
  height: 15px;
  margin-left: 15px;
  background-repeat: no-repeat;
  background-position: left;
  transition: background-position .3s ease-out;
  background-image: url('../images/arrow-black.svg');
}

.s-what-we-do-branches__item:hover .s-what-we-do-branches__heading-arrow {
  background-position: right;
}

.s-what-we-do-branch {
  padding: 70px 0;

  &:nth-child(2n+1) {
    background: $fm-alabaster;
  }

  @include min-screen($tablet-s) {
    padding: 140px 0;
  }
}

.s-what-we-do-branch__container {
  display: flex;
  justify-content: space-between;
}

.s-what-we-do-branch__desc {
  h2 {
    @include h--xl;
    margin-bottom: 27px;
  }

  p {
    margin-bottom: 64px;
  }
}

.s-what-we-do-branch__tags {
  display: flex;
  list-style: none;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-bottom: 0;

  & > span {
    cursor: pointer;
  }
}

.s-what-we-do-branch__tags--column {
  flex-direction: column;
}

.s-what-we-do-branch__cs {
  display: flex;
  padding-top: 48px;
  flex-wrap: wrap;
  gap: 40px;

  @include min-screen($tablet-s) {
    padding-top: 0;
  }

  @include min-screen($phone) {
    flex-wrap: nowrap;
    gap: 16px;
  }
}

.s-what-we-do-branch__grid {
  display: grid;
  grid-template-columns: 256px;
  padding-top: 60px;
  gap: 40px;

  @include min-screen($tablet-s) {
    padding-top: 0px;
    grid-template-columns: 256px;
    justify-content: end;
  }

  @include min-screen($tablet) {
    grid-template-columns: 256px 256px;
  }
}

.s-what-we-do-branch__cs--vertical {
  @include min-screen($tablet-s) {
    flex-direction: column;
    row-gap: 32px;
  }
}

.s-what-we-do-branch__more {
  display: flex;
  padding-top: 48px;
}

.s-what-we-do-branch__more-button {
  @include min-screen($tablet-s) {
    margin-left: auto;
  }
}

.s-what-we-do__project {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    width: 100%;
  }
  img {
    display: block;
    margin-bottom: 12px;
    width: 256px;
    height: 208px;
    object-fit: cover;
  }

  @include max-screen($phone) {
    img {
      width: 100%;
    }
  }
}
.s-what-we-do__project-title {
  text-decoration: none;
  font-weight: 700;
  color: $fm-grey;

  &:hover {
    text-decoration: underline;
  }
}
.s-what-we-do__project-lead {
  margin-top: 4px;
  margin-bottom: 4px;
}
