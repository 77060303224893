@mixin reportTypography() {
    @include formatRichText();

    h3 {
      margin-block: 0 24px;
      @include h--m;

      @include min-screen($phone) {
        font-size: 35px;
        line-height: 42px;
      }
    }

    p {
      @include min-screen($phone) {
        margin-bottom: 40px;
        font-size: 20px;
      }
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    ul {
      li {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 18px;

        &::before {
          content: '';
          background: url("../images/icon-tick.svg") 50% 50% / contain no-repeat;
          display: inline-block;
          min-width: 24px;
          min-height: 24px;
          margin-right: 16px;
          margin-top: 4px;
        }
      }
    }

    .c-button {
      margin-bottom: 48px;

      @include min-screen($tablet-s) {
        margin-bottom: 0;
        margin-top: 16px;
      }
    }
}
