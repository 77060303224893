.app-health-approach {
  color: $fm-white;
  padding: 50px 0;
}

.app-health-approach__content {
  max-width: 622px;
  width: 100%; 
}

.app-health-approach__heading {
  margin-top: 80px;
  margin-bottom:16px;

  &:first-of-type { margin-top: 60px; }
}

.app-health-approach__list {
  list-style: none;

  li:before {
    content: '+';
    color: $fm-white;
    margin-right: 10px;
  }
}

.app-health-approach__tag {
  color: $fm-grey;
}

.app-health-approach__assessment {
  margin-top: 80px;

  h3 {
    margin-bottom: 30px;
  }
}

.app-health-approach__assessment-step {
  @include max-screen($tablet) {
    margin-bottom: 30px;
  }
}

.app-health-approach__line {
  height: 4px;
  width: 100%;
  background-color: white;
  margin-bottom: 25px;
  margin-top: 10px;
}

.app-health-approach__line--first {
  background-image: linear-gradient(
    90deg,
    #f8ae9e 0%,
    #96313c 100%,
  );
}

.app-health-approach__line--second {
  background-image: linear-gradient(
    90deg,
    #882a3c 0%,
    #35247f 100%,
  );
}

.app-health-approach__line--third {
  background-image: linear-gradient(
    90deg,
    #2b2689 0%,
    #08c7df 100%,
  );
}

.app-health-approach__line--design-first {
  background-image: linear-gradient(
    90deg,
    #f8ae9e 0%,
    #5c163d 100%,
  );
}

.app-health-approach__line--design-second {
  background-image: linear-gradient(
    90deg,
    #591a50 0%,
    #1e2996 50%,
    #08c7df 100%,
  );
}

.app-health-approach__person {
  display: flex;
  margin-top: 8px;
  margin-bottom: 50px;

  img {
    margin-right: 16px;
  }
}

.app-health-approach__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-health-approach__photo {
  width: 64px;
  height: 64px;
}

.app-health-approach__logos {
  padding-bottom: 45px;
  padding-top: 45px;
  background-color: $fm-alabaster;
  max-width: 100%;

  h3 {
    margin-bottom:32px;
  }
}

.app-health-approach__logos--white {
  background-color: white;
}

.app-health-approach__logos--spacing-top {
  padding-top: 50px; 
}

.app-health-approach__images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include baseContainerMaxWidth();
  margin: auto;

  .js-horizontal-slider-item {
    max-width: 160px;
  }

  &--heading {
    color: $fm-grey;
    font-size: 12px;
    @include secondary-font;
    position: absolute;
    top: 0;
  }

  @include max-screen(900px) {
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
  }
}

.design-system__cases {
  margin-bottom: 100px;
  img {
    margin-bottom: 16px;
  }
}

.app-health-approach__horizontal {
  padding: 30px 0;
  display: inline-flex;
  flex-direction: column;
  
  @include min-screen($tablet-s) {
    flex-direction: row;
    gap: 80px;
  }
}