.s-categories-showcase {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding-block: 40px;

    @include min-screen($tablet) {
        padding-block: 80px;
        row-gap: 80px;
    }
}

.s-categories-showcase__categories {
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 40px;

    @include min-screen($tablet) {
        flex-direction: row;
        justify-content: space-between;
        column-gap: clamp(2.5rem, -8.75rem + 18.75vw, 6.25rem); // 960px - 40px => 1280px - 100px
    }
}

.s-categories-showcase__categories-list {
    width: 100%;
    gap: 5px;
    display: flex;
    flex-wrap: wrap;
    text-wrap: balance;

    @include min-screen($tablet) {
        flex-direction: column;
        row-gap: 40px;
        max-width: 365px;
        padding-bottom: 12px;
    }

    li {
        display: flex;
        column-gap: 40px;
        width: fit-content;
        align-items: center;

        img {
            display: none;

            @include min-screen($tablet) {
                display: block;
                height: 32px;
                width: 32px;
            }

            @include min-screen($desktop-s) {
                height: 36px;
                width: 36px;
            }
        }
    }
}

@mixin list-item-desktop {
    position: relative;
    font-size: 25px;
    font-weight: 250;
    line-height: 26px;
    color: $fm-grey;
    text-transform: uppercase;
    white-space: normal;
    font-family: $base-font-family;
    padding: unset;
    border: none;
    border-radius: none;
    display: block;
    background: transparent;

    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 2px;
        margin-top: 5px;
        transition: width $transition-time $transition-easing;
        background-color: $fm-black;
    }

    &:hover::after {
        width: 100%;
    }
@include min-screen($desktop-xl) {
    font-size: 28px;
    line-height: 36px;
}
}

.s-categories-showcase__categories-list-item {
    @extend .c-tag, .c-tag--white, .u--nowrap;

    @include min-screen($tablet) {
        @include list-item-desktop;
    }

    &:hover {
        cursor: pointer;
    }
}

@mixin list-item-active-desktop {
    font-weight: 500;
    color: $fm-black;

    &::after {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        margin-top: 5px;
        background-color: $fm-black;
    }
}

.s-categories-showcase__categories-list-item--active {
    @extend .c-tag, .c-tag--black, .u--nowrap;

    @include min-screen($tablet) {
        @include list-item-active-desktop;
    }
}

.s-categories-showcase__description--wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include min-screen($tablet) {
        max-width: 620px;
    }

    @include min-screen($desktop) {
        max-width: unset;
    }
}

.s-categories-showcase__description {
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    p {
        font-weight: 250;
    }

    @include min-screen($tablet) {
        row-gap: 80px;
    }

    &--hide {
        display: none;
    }

    &--visible {
        display: flex;
    }
}