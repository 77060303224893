.s-contact-offices {
  padding: 60px 0 20px 0;
  h2 {
    text-align: center;
    span {
      font-weight: 400;
    }
  }

  @include min-screen($tablet) {
    padding: 100px 0 60px 0;
    h2 {
      text-align: left;
    }
  }
}

.s-contact-offices__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: center;
  
  @include min-screen($tablet) {
    justify-content: flex-start;
    margin-top: 80px;
  }
}

.s-contact-offices__item {
  width: 260px;
  margin-bottom: 40px;

  h3 {
    margin-bottom: 10px;
  }

  p {
    color: $fm-iron;
  }

  @include max-screen($tablet) {
    text-align: center;
  }
}