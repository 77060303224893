.s-advisory-contact-form {
    background: $fm-alabaster;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    padding: 36px;

    button {
        width: fit-content;
        margin-top: 34px;
    }

    textarea {
        resize: none;
        max-height: 105px;
    }

    .s-newsletter__checkbox-control {
        color: $fm-iron;
        font-size: 14px;
    }

    @include min-screen($tablet) {
        max-width: 600px;
    }
}
