.s-solutions__cooperations {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding-block: 40px;

    @include min-screen($tablet) {
        padding-block: 80px;
        row-gap: 80px;
    }
}

.s-solutions__categories {
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 40px;

    @include min-screen($tablet) {
        flex-direction: row;
        justify-content: space-between;
    }

    @include min-screen($desktop) {
        column-gap: 100px;
    }
}

.s-solutions__categories-list {
    width: 100%;
    gap: 5px;
    display: flex;
    flex-wrap: wrap;

    @include min-screen($tablet) {
        flex-direction: column;
        row-gap: 72px;
        max-width: 350px;
        padding-bottom: 12px;
    }

    li {
        display: flex;
        column-gap: 40px;

        img {
            display: none;

            @include min-screen($tablet) {
                display: block;
                height: 32px;
                width: 32px;
            }

            @include min-screen($desktop-s) {
                height: 36px;
                width: 36px;
            }
        }

        p {
            white-space: wrap;
        }
    }
}

@mixin list-item-desktop {
    position: relative;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    color: $fm-iron;
    text-transform: capitalize;
    white-space: nowrap;
    font-family: $base-font-family;
    padding: unset;
    border: none;
    border-radius: none;
    display: block;
    background: transparent;

    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 2px;
        margin-top: 5px;
        transition: width $transition-time $transition-easing;
        background-color: $fm-black;
    }

    &:hover::after {
        width: 100%;
    }
}

.s-solutions__categories-list-item {
    @extend .c-tag, .c-tag--white, .u--nowrap;

    @include min-screen($tablet) {
        @include list-item-desktop;
    }

    @include min-screen($desktop-s) {
        font-size: 28px;
    }

    &:hover {
        cursor: pointer;
    }
}

@mixin list-item-active-desktop {
    font-weight: 500;
    &::after {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        margin-top: 5px;
        background-color: $fm-black;
    }
}

.s-solutions__categories-list-item--active {
    @extend .c-tag, .c-tag--black, .u--nowrap;

    @include min-screen($tablet) {
        @include list-item-active-desktop;
    }
}

.s-spinner--visible {
    width: 100%;
    align-self: center;
}

.s-solutions__description--wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include min-screen($tablet) {
        max-width: 620px;
    }

    @include min-screen($desktop) {
        max-width: unset;
    }
}

.s-solutions__description {
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    p {
        font-weight: 250;
    }

    @include min-screen($tablet) {
        row-gap: 80px;
    }

    &--hide {
        display: none;
    }

    &--visible {
        display: flex;
    }
}

.s-solutions__description-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    text-align: center;

    @include min-screen($phone) {
        flex-direction: row;
        column-gap: 75px;
        align-items: unset;
        text-align: unset;
        row-gap: 40px;
    }

    img {
        height: 182px;
        width: 182px;
        border-radius: 6px;
        object-fit: cover;
    }
}

.s-solutions__description-contact--wrapper {
    display: flex;
    flex-direction: column;

    a {
        width: fit-content;
        margin-top: 36px;
    }
}

.s-solutions__description-contact--text {
    h3 {
        font-size: 20px;
      
        @include min-screen($phone) {
            line-height: 46px;
        }
    }

    p {
        @include secondary-font;
        font-size: 12px;
        line-height: 30px;
        text-transform: uppercase;
        color: $fm-black-transparent;
    }
}
