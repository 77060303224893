.advisory-service__header {
    background: $fm-white;
    color: $fm-black;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding-block: 60px;

    @include min-screen($tablet) {
        padding-block: 100px 90px;
    }

    @include min-screen($desktop-s) {
        row-gap: 60px;
    }

    @include min-screen($desktop-xl) {
        padding-block: 140px;
        row-gap: 80px;
    }
   
    &--title {
        @include h--xl();

        @include min-screen($desktop-l) {
            max-width: 85%;
        }

        @include min-screen($desktop-xl) {
            max-width: unset;
        }
    }

    &--subtitle {
        @include h--m();
    }
}
