@mixin make-justify-content() {
  @each $name, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint){
      @for $i from 1 through 12 {
        .justify-content-#{$name}-center {
          justify-content: center;
        }
      }
    }
  }
}
