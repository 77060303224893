
.fc-container {
  background-size: 100%;

  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h1, h2, h3 {
  }
}

.fc-section-subheader {
  font-size: 28px
}

.fc-header {
  padding: 25px 0 100px
}

@media only screen and (min-width: 767px) {
  .fc-header {
    background-size: 85%
  }
}

@media only screen and (min-width: 960px) {
  .fc-header {
    padding: 75px 0 75px;
    background-size: 75%
  }
}

.fc-header__claim {
  margin-top: 0
}

@media only screen and (min-width: 767px) {
  .fc-header__claim {
  }
}

@media only screen and (min-width: 960px) {
  .fc-header__claim {
    margin-top: 60px
  }
}

.fc-header__claim h1 {
  @include h--m;
  margin-top: 28px;
  margin-bottom: 28px;
}

.fc-header__logo {
  margin-bottom: 60px
}

.fc-section-header {
  line-height: 45px;
  font-size: 40px
}

@media only screen and (min-width: 767px) {
  .fc-section-header {
    font-size: 50px
  }
}

.fc-goal {
  margin: 0 auto
}

.fc-goals {
  max-width: 1020px;
  margin: 30px auto 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between
}

@media only screen and (min-width: 767px) {
  .fc-goals {
    margin-top: 40px
  }
}

@media only screen and (min-width: 1025px) {
  .fc-goals {
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 80px
  }
}

.fc-goals__desc {
  margin: 40px auto 0;
}

.fc-goals__goal {
  width: 300px;
  height: 364px;
  padding: 40px 20px 0;
  margin: 0 auto 20px;
  text-align: center;
}

.fc-goals__img {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 84px;
  margin-bottom: 30px
}

.fc-goals__img img {
  margin: 0 auto
}

.fc-challenges {
  margin: 40px auto 40px
}

.fc-challenges__copy {
  margin: 0 auto;
}

.fc-challenges__items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between
}

@media only screen and (min-width: 1025px) {
  .fc-challenges__items {
    -ms-flex-direction: row;
    flex-direction: row;
    margin-bottom: 100px
  }
}

.fc-challenges__item {
  width: 300px;
  text-align: center;
  margin: 20px auto;
}

.fc-challenges__item img {
  margin: 0 auto;
}

.fc-approach {
  margin: 40px auto 40px
}

.fc-approach__copy {
  margin: 0 auto 80px;
}

.fc-approach__desc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 690px;
  margin: 0 auto
}

@media only screen and (min-width: 767px) {
  .fc-approach__desc {
    -ms-flex-direction: row;
    flex-direction: row
  }
}

@media only screen and (max-width: 767px) {
  .fc-approach__desc img {
    margin: 40px auto
  }
}

.fc-approach__desc p {
  max-width: 379px
}

@media only screen and (max-width: 767px) {
  .fc-approach__desc p {
    margin: 40px auto
  }
}

.fc-documentation {
  margin: 40px auto 0
}

@media only screen and (min-width: 767px) {
  .fc-documentation {
    margin: 80px auto 0
  }
}

.fc-documentation__desc {
  margin: 40px auto 40px;
}

.fc-documentation__items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  max-width: 550px;
  margin: 0 auto;
  height: 1000px
}

@media only screen and (min-width: 1025px) {
  .fc-documentation__items {
    height: 500px;
    max-width: 100%
  }
}

.fc-documentation__item {
  width: 150px;
  height: auto;
  padding: 10px;
  margin: 5px;
  line-height: 22px;
  border-radius: 5px;
  border: 1px solid $fm-stone;
}

@media only screen and (min-width: 540px) {
  .fc-documentation__item {
    width: 220px;
    padding: 20px;
    margin: 10px
  }
}

.fc-documentation__item p {
  margin-bottom: 0
}

.fc-architecture {
  margin: 140px auto 0;
}

.fc-architecture__chart-disclaimer {
  margin: 80px auto 0
}

.fc-stack {
  margin: 80px auto 0
}

.fc-stack__switchers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  max-width: 750px;
  padding: 5px;
  margin: 0 auto 40px;
  border: 1px solid $fm-grey;
  border-radius: 5px
}

.fc-stack__switcher {
  cursor: pointer;
  max-width: 250px;
  width: 100%;
  padding: 10px 0 0;
  text-align: center;
  font-size: 16px;
  background-color: $fm-black;
  color: #fff;
  opacity: 0.7;

  img {
    display: block;
  }
}

.fc-stack__switcher.active {
  background-color: $fm-black;
  color: #fff;
  opacity: 1;
}

.fc-stack__switcher img {
  margin: 5px auto 0
}

@media only screen and (min-width: 540px) {
  .fc-stack__switcher {
    font-size: 20px
  }
}

@media only screen and (min-width: 640px) {
  .fc-stack__switcher {
    padding: 15px 0 5px;
    font-size: 24px
  }
}

.fc-stack__tab {
  display: none;
  max-width: 750px;
  margin: 0 auto
}

.fc-stack__tab.active {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.fc-stack__tab-logos {
  display: none
}

@media only screen and (min-width: 640px) {
  .fc-stack__tab-logos {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background: $fm-black;
    width: 300px;
    padding: 40px 0
  }
  .fc-stack__tab-logos img {
    margin: 40px 0
  }
}

.fc-stack__tab-desc {
  max-width: 100%;
  margin-left: 0;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

@media only screen and (min-width: 640px) {
  .fc-stack__tab-desc {
    max-width: 400px;
    margin-left: 40px
  }
}

.fc-stack__tab-subheader {
  font-size: 24px;
  margin: 0 0 30px
}

.fc-results__copy {
  margin: 140px auto 80px;
}

.fc-results__carousel {
  position: relative;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.tns-nav {
  .fc-results__carousel & {
    display: flex;
    justify-content: center;
    padding-top: 10px;

    button {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #d6d6d6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity .2s ease;
      border-radius: 30px;
      border: 0;
      outline: 0;
      padding: 0;

      &.tns-nav-active {
        background: #869791;
      }
    }
  }
}

.fc-results__carousel--visible {
  visibility: visible;
  opacity: 1;
}


.fc-results__carousel-item {
  display: -ms-flexbox;
  display: flex;
  max-width: 1090px;
  margin: 0 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  z-index: 998
}

.fc-results__carousel-item p {
  max-width: 500px;
  margin: 0 auto
}

.fc-results__carousel-item h3 {
}

@media only screen and (min-width: 960px) {
  .fc-results__carousel-item {
    -ms-flex-direction: row;
    flex-direction: row;
    text-align: justify
  }
  .fc-results__carousel-item h3, .fc-results__carousel-item p {
    margin-left: 40px
  }
  .fc-results__carousel-item p {
    max-width: 100%
  }
}

.fc-results__carousel-image {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 592px;
  max-height: 455px;
  width: 100%;
  height: 100%;
  margin: 0 auto
}

.fc-results__arrow {
  position: absolute;
  cursor: pointer;
  width: 32px;
  height: 32px;
  z-index: 999;
  top: 400px;
  background-color: $fm-black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-top: -1px;
  }
}

@media only screen and (max-width: 960px) {
  .fc-results__arrow {
    display: none
  }
}

.fc-results__arrow--left {
  right: 0;
  margin-right: 42px;

  img {
    transform: scale(-1, 1);
  }
}

.fc-results__arrow--right {
  right: 0
}

.fc-mobile-app {
  margin: 40px auto 0
}

@media only screen and (min-width: 640px) {
  .fc-mobile-app {
    margin: 80px auto 0
  }
}

.fc-mobile-app__desc {
}

.fc-mobile-app__phone {
  max-width: 750px;
  margin: 0 auto 40px
}

@media only screen and (min-width: 640px) {
  .fc-mobile-app__phone {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0
  }
}

.fc-mobile-app__phone-desc {
  padding-top: 20px
}

.fc-mobile-app__phone-desc p {
  font-size: 24px;
  line-height: 30px
}

@media only screen and (max-width: 640px) {
  .fc-mobile-app__phone-desc {
    text-align: center
  }
  .fc-mobile-app__phone-desc img:first-of-type {
    margin: 0 auto
  }
  .fc-mobile-app__phone-desc img:last-of-type {
    display: none
  }
}

.fc-mobile-app__phone-img {
  -ms-flex-negative: 0;
  flex-shrink: 0;

  img {
    display: block;
  }
}

.fc-mobile-app__phone-img img {
  width: 302px;
  height: 639px
}

@media only screen and (max-width: 640px) {
  .fc-mobile-app__phone-img img {
    width: 200px;
    height: auto;
    margin: 0 auto
  }
}

.fc-mobile-app__phone-1-3 {
  margin-top: 20px
}

@media only screen and (min-width: 640px) {
  .fc-mobile-app__phone-1-3 .fc-mobile-app__phone-desc img:first-of-type, .fc-mobile-app__phone-1-3 .fc-mobile-app__phone-desc p {
    margin-left: 80px
  }
  .fc-mobile-app__phone-1-3 .fc-mobile-app__phone-desc img:last-of-type {
    margin-left: 5px
  }
}

.fc-mobile-app__phone-2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse
}

@media only screen and (min-width: 640px) {
  .fc-mobile-app__phone-2 {
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: -270px
  }
}

@media only screen and (min-width: 640px) {
  .fc-mobile-app__phone-2 .fc-mobile-app__phone-desc {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-bottom: 40px;
    text-align: right
  }
  .fc-mobile-app__phone-2 .fc-mobile-app__phone-desc img:first-of-type, .fc-mobile-app__phone-2 .fc-mobile-app__phone-desc p {
    margin-right: 80px
  }
  .fc-mobile-app__phone-2 .fc-mobile-app__phone-desc img:last-of-type {
    margin-right: 5px;
    transform: scaleX(-1)
  }
}

.fc-mobile-app__phone-4 {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 40px
}

@media only screen and (max-width: 640px) {
  .fc-mobile-app__phone-4 {
    -ms-flex-direction: column;
    flex-direction: column
  }
}

@media only screen and (min-width: 640px) {
  .fc-mobile-app__phone-4 .fc-mobile-app__phone-desc {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 300px;
    text-align: right;
    top: -30px;
    left: 0
  }
}

.fc-mobile-app__phone-4 .fc-mobile-app__phone-desc img:last-of-type {
  margin: 10px 150px 0 0;
  transform: scaleX(-1) rotate(-45deg)
}

@media only screen and (max-width: 780px) {
  .fc-mobile-app__phone-4 .fc-mobile-app__phone-desc img:last-of-type {
    display: none
  }
}

.fc-mobile-app__phone-4 .fc-mobile-app__phone-img img {
  width: 753px;
  height: auto
}

@media only screen and (max-width: 780px) {
  .fc-mobile-app__phone-4 .fc-mobile-app__phone-img img {
    width: 500px
  }
}

.fc-mobile-app__phone-5 {
  position: relative;
  display: -ms-flexbox;
  display: flex
}

@media only screen and (max-width: 640px) {
  .fc-mobile-app__phone-5 {
    -ms-flex-direction: column;
    flex-direction: column
  }
}

@media only screen and (min-width: 640px) {
  .fc-mobile-app__phone-5 .fc-mobile-app__phone-desc {
    position: absolute;
    width: 300px;
    top: -50px;
    right: 0
  }
}

.fc-mobile-app__phone-5 .fc-mobile-app__phone-desc img:last-of-type {
  margin: 40px 0 0 100px;
  transform: rotate(-45deg)
}

@media only screen and (max-width: 780px) {
  .fc-mobile-app__phone-5 .fc-mobile-app__phone-desc img:last-of-type {
    display: none
  }
}

.fc-mobile-app__phone-5 .fc-mobile-app__phone-img img {
  width: 705px;
  height: auto
}

@media only screen and (max-width: 780px) {
  .fc-mobile-app__phone-5 .fc-mobile-app__phone-img img {
    width: 500px
  }
}

.fc-testimonial {
  margin: 140px auto 0;
  text-align: center
}

.fc-testimonial__game-changer {
  font-size: 24px;
  line-height: 39px;
  text-align: center
}

.fc-testimonial__quote {
  position: relative;
  max-width: 500px;
  margin: 80px auto;
  padding: 60px 35px;
  border: 1px solid $fm-stone;
  border-radius: 5px
}

.fc-testimonial__quote p {
  line-height: 20px
}

.fc-testimonial__quote span {
  color: #9e9fa2
}

.fc-testimonial__rating {
  width: 150px;
  margin: 0 auto;
  height: 30px;
  background: url(../images/fleet-connect/star-rating.svg) repeat-x
}

.fc-testimonial__img {
  padding: 14px;
  width: 80px;
  height: 80px;
  top: -40px;
  border: 1px solid $fm-stone;
  border-radius: 50%
}

.fc-testimonial__img, .fc-testimonial__link {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  background: $fm-black;
}

.fc-testimonial__link {
  width: 246px;
  padding: 20px 10px;
  bottom: -25px;
  border: 1px solid $fm-stone;
  border-radius: 5px
}

.fc-testimonial__link a {
  color: #fff;
}

.fc-numbers {
  text-align: center;
  padding-bottom: 80px
}

.fc-numbers__row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto
}

.fc-numbers__item {
  width: 180px;
  height: 206px;
  padding: 31px 35px 0;
  margin: 7px;
  border-radius: 5px;
}

.fc-numbers__item span {
  font-size: 80px;
  color: $fm-black;
}

.fc-numbers__item p {
  text-transform: uppercase;
  font-size: 16px
}

.fc-form {
  margin-bottom: 0;
  padding-bottom: 80px
}

.fc-form label {
}

.fc-form input {
  background: #f4f4f4
}

.fc-form input:checked + label {
}

.fc-form__container {
  max-width: 500px;
  border: 1px solid #9a57ff;
  border-radius: 5px
}

.fc-button-more {
  border-color: #f27907;
  background-color: #f27907;
}
