.s-experts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  div {
    max-width: 280px;
    width: 100%;
    text-align: center;
    margin: 0 20px 120px 20px;
  }
}