.s-insights-carousel {
  position: relative;
  opacity: 0;
  transition: opacity 0.3s linear;
  display: flex;
  justify-content: center;
  margin-top: 50px;

  @include min-screen($desktop) {
    padding-block: 40px;
  }

  .tns-item {
    @include sliderGrabCursor();
  }
}

.s-insights-carousel--tag {
  background-color: $fm-iron;
  border-color: $fm-iron;
  color: $fm-stone;
}

.s-insights-carousel--visible {
  opacity: 1;
}

.s-insights-blocks {
  position: relative;
  background-color: $fm-black;
  color: $fm-white;
  overflow: hidden;
  grid-area: listing;  
}

.s-insights-blocks--no-background {
  background-color: transparent;
}

.s-insights-blocks--blog {
  & > .row {
    row-gap: 16px;
  }

  @include max-screen($phone) {
    display: flex;
    justify-content: center;
  }
}

.s-insights-blocks__center {
  @include max-screen($phone) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.s-insights-blocks__container {
  display: flex;
  align-items: flex-end;

  div {
    flex-shrink: 1 0 auto;
  }

  @include min-screen($tablet) {
    align-items: center;
  }

  @include min-screen($desktop) {
    height: 650px;
    align-items: flex-start;

    > div:nth-child(3n+1) {
      margin-top: 90px;
    }

    > div:nth-child(3n) {
      margin-top: 40px;
    }
  }
}

.insight-block {
  display: flex;
  flex-direction: column;
  height: 100%; 
  width: 315px;
  border: 1px solid $fm-transparent-40;
  border-radius: 8px;
  overflow: hidden;

  .s-insights-blocks--blog & {
    min-width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}

.insight-block:hover .insight-block__heading {
  color: $fm-white;
}

.insight-block--bar {
  width: 100%;
  border: 1px solid $fm-transparent-2;
  @include min-screen(650px) {
    max-width: 347px;
    width: 100%;
  }

  @include min-screen($tablet) {
    min-width: 300px;
  }
}

.insight-block__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;

  .s-insights-blocks--blog & {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}

.insight-block__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.insight-block__tags--fixed-height {
  align-items: baseline;
  height: 84px;
  overflow: hidden;
  transition: height $transition-time $transition-easing;
  -ms-transition: height $transition-time $transition-easing;
  -moz-transition: height $transition-time $transition-easing;
  -o-transition: height $transition-time $transition-easing;
  -webkit-transition: height $transition-time $transition-easing;
}

.insight-block__tags--expanded {
  height: 100%
}

.insight-block__link {
  text-decoration: none;
}

.insight-block__heading {
  text-align: left;
  color: $fm-transparent-2;
  margin: 16px 0 24px;
  @include clamp-text(3);

  &--bar {
     color: $fm-smoke;
  }
}

.insight-block__heading:hover {
  color: $fm-white;
}

.insight-block__footer {
  @include secondary-font;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 40px;
  margin-top: auto;
  width: 100%;
  column-gap: 8px;

  > span:first-of-type {
    @include clamp-text(2);
  }

  .s-insights-blocks--blog & {
    margin-top: auto;
  }
}

.insight-block__footer--author {
  display: flex;
  flex-direction: column;

}

.insight-block__time {
  white-space: nowrap;
  color: $fm-grey;
}

.insight-block__header-separator {
  color: $fm-grey;
}

.s-insights-buttons {
  background-color: $fm-black;
}

.s-insights-buttons__container {
  display: flex;
  justify-content: center;
  padding: 40px 0 118px 0;
  a {
    margin-right: 15px;
  }

  @include min-screen($desktop-xl) {
    padding-bottom: 140px;
  }
}

.s-insights-blocks__item {
  display: flex;
}

.insight-block__image-wrapper {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: (250 / 350) * 100%;
  }
}

.insight-block__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.s-insights-blocks__no-results {
  padding-top: 76px;
  padding-bottom: 140px;
   grid-column: span 3;
}

.s-insights-blocks__results-for {
  margin-bottom: 32px;
  @include min-screen($tablet) {
    margin-bottom: 64px;
  }
}


.insight-block__carousel-cover-container {
  a {
    display: block;
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      max-height: 250px;
    }
  }
}
