.custom-select-wrapper {
    position: relative;
    user-select: none;
    margin-bottom: 20px;
  }
  
  .custom-select {
    position: relative;
    display: block;
  }
  
  .custom-select__trigger {
    background: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 0;
    border: none;
    -webkit-border-radius: 0;
    outline: none;
    border-bottom: 2px solid $fm-grey;
    font-family: $base-font-family;
    color: $fm-black;
    font-size: 13px;
    @include max-screen($phone) {
        font-size: 16px;
      }

      .custom-dropdown-label-text {
        font-family: $secondary-font-family;
        text-transform: uppercase;
        color: $fm-grey;
        font-size: 13px;
        line-height: 13px;
        @include max-screen($phone) {
            font-size: 16px;
          }
    }
  }
  
  .custom-options {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-top: none;
    background: #fff;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .custom-options.show {
    display: flex;
    flex-direction: column;
    height:200px;
    overflow:scroll;
    overflow-x:hidden;
    z-index: 99999;
    padding: 10px 0;

    .custom-dropdown-mobile-label {
        display: none
    }
 
    @include max-screen($tablet-s) {
        width: calc(100% + 40px);
        height: 350px;
        top: 0;
        max-height: 350px;
        margin-left: -20px;
        padding-top:0;

        .custom-dropdown-mobile-label {
            display: block;
            position: sticky;
            top:0;
            min-height: 40px;

            &-text {
                padding: 8px 10px;
                position: absolute;
                z-index: 9999;
                width: 100%;
                background: white;
                padding-top:10px;
                font-family: $base-font-family;
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;
                letter-spacing: -0.01em;
            }
        }
    }
}
  
  .custom-option {
    padding: 8px 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $secondary-font-family;
    font-size: 13px;
    line-height: 20px;
  }
  
  .custom-option:hover, .custom-option.selected {
    background-color: $fm-black;
    color: #FFFFFF;
  }
  
  .custom-options::-webkit-scrollbar {
    width: 5px;
  }
  
  .custom-options::-webkit-scrollbar-track {
    background: $fm-smoke;
  }
  
  .custom-options::-webkit-scrollbar-thumb {
    background: $fm-black;
  }
  
  .custom-options::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .arrow {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
  }
  