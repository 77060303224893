

.s-header-cta-advisory {
    color: $fm-white;
    position: relative;
    min-height: 7000px;  

    .s-advisory__contact-person--left-container--title {
        color: black;
    }

    .js-form-advisory-success-message {
        color:black;
    }

    .s-insight-block--authors {
        color: #fff;
    }

    .s-advisory__contact-person--right-container--name{
        color: #000000;
    }
    .s-advisory__contact-person--left-container--email {
        overflow-wrap: break-word;
        word-wrap: break-word;
    }




    .insight-block__time, 
    .insight-block__header-separator{
        color: $fm-stone;

    }

    .break-text {
        @media only screen and (min-width: 480px) {
            &:after {    
                 content: ' ';
                clear: right;
                display: block; }
        }
    }

    .subtitle-gradient {
        background: linear-gradient(90deg, #F6B2A2 23.2%, #E84230 28.52%, #AF2272 37.99%, #9F154B 40.36%, #804CBB 47.7%, #4060F2 54.32%, #0096C7 70.23%, #5CC2D7 76.65%);

        background: linear-gradient(90deg, #F6B2A2 1%, #E84230 10%, #AF2272 28%, #9F154B 32%, #804CBB 46%, #4060F2 58%, #0096C7 88%, #5CC2D7 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;

        -webkit-text-fill-color: transparent;
    }
  }


  .mobile-app-advisory {

     > * {
        @include max-screen($desktop-s) {
            padding-left:20px;
            padding-right:20px;
            max-width: 100%;
        //   @include baseContainerSize();
        }
     }


    &-header {
        padding-top: 27px;
    }

    .header {
        position: relative;
        top: -180px;
        background-image: url('../images/mobile-app-advisory/mobile-app-advisory-header-bg2.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 1400px;  
        background-position-x: 65%;
        @media only screen and (min-width: 1024px) {
            top: -220px;
            background-position-x: 35%;

        }

        @media only screen and (min-width: 2400px) {
            height: 1600px;  
        }

    }
    .hero__text {
        padding-top: 190px;
        font-family: $base-font-family;
        text-align: center;

        gap: 40px;

        h1 {
            @include h--l();
        }
        h3 {
            padding: 40px 0 40px 0;
            @include h--m--desktop-s();
        }
    }

    .about {
        padding: 80px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap:40px;
        @include h--s();

    }

    .logos {
        padding: 40px 0;
        display: flex;
        height: 54px;
        justify-content: center;
        align-items: center;
        gap: 111px;
        flex-wrap: wrap; 
        @media only screen and (max-width: 1024px) {
            gap: 40px;
        }

        }
    .cta__text {
        padding: 80px 0;
        text-align: center;
        @include h--m();
        @media only screen and (max-width: 1024px) {
            padding: 180px 0;
        }

    }

    .journey {
        background: #151515;
        margin-top: -300px;
        padding-top: 30px;
        position: relative;
        @media only screen and (min-width: 1024px) {
            margin-top: -225px;
        }

        .journey-section {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 192px;
            align-self: stretch; 
            margin-bottom: 80px;
            @include animationZoomOnHover;
            @media only screen and (max-width: 1024px) {
                flex-direction: column;
                gap: 48px;
              }

            img {
                @media only screen and (min-width: 1024px) {
                    align-self: start;
                  }
            }
         
        
            &--right {
                flex-direction: row-reverse;
                gap: 48px;
                @media only screen and (max-width: 1024px) {
                    flex-direction: column;
                  }
            }
            .journey-section-text {
                max-width: 504px;
            }

            .title {
                margin-top: 24px;
                @include h--m--desktop-s;

            }
            .subtitle {
                margin-top: 40px;
                @include h--s-desktop();
                color: $fm-alabaster;
            }

            .description {
                margin-top: 20px;
                margin-bottom: 40px;
                @include h--s-desktop();
                color: $fm-alabaster;
            }

            .journey-extended {
                margin-top: 80px;
                max-height: 0;
                overflow: hidden; 
                transition: max-height 0.7s;
                padding: 0;

                .c-list-icon-tick{ 
                    display:flex;
                    flex-direction: column;
                }

                h5 {
                    @include h--xs();
                    text-transform: uppercase;
                    color: $fm-smoke;
                }
                ul {
                    margin-bottom: 60px;
                }
                li {
                    color: $fm-alabaster;
                }

            }
            &.show-benefits {
                .journey-extended{
                    max-height: 1200px;
                }
            }
        }
     
    }

    .about-us {
        color: $fm-white;
        background-image: url('../images/mobile-app-advisory/about-us-bg2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        height: 670px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: -102px;

        @media only screen and (max-width: 48x0px) {
            padding-top: 100px
        }

        .fm-logo {
            padding-bottom: 60px;
        }
        p {
            text-transform: uppercase;
            padding-bottom: 40px;
            font-family: $secondary-font-family;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; 
        }
    }

  }