.s-career-introduction {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;

    @include min-screen($tablet-s) {
        flex-direction: row;
        column-gap: clamp(3.75rem, -0.9223rem + 9.7466vw, 6.875rem); // $tablet-s = 60px to $desktop = 110px
        padding-bottom: 40px;
    }

    &--text {
        height: fit-content;
        @include formatRichText();

        &.intro-footer {
            @include max-screen($tablet) {
                padding-top: 60px;
              }
        }

        p {
            font-weight: 250;
            padding-bottom: 40px;
            margin: 0;

            @include min-screen($tablet) {
                font-size: 20px; 
            }

            @include min-screen($desktop) {
                font-size: 28px;
                line-height: 42px;
            }
        }

        b {
            font-weight: 400;
        }

        @include min-screen($tablet-s) {
            flex: 0.5;
        }
    }

    &--image {

        img {
            object-fit: cover;
            max-height: 460px;
        }

        @include min-screen($tablet-s) {
            flex: 0.5;
        }
    }
}
