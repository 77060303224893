.s-contact-banner {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: $fm-alabaster;

  @include min-screen($tablet-s) {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  a {
    text-align: center;

  }
}

.s-contact-banner__heading {
  margin-bottom: 32px;
    @include h--l();

  @include min-screen($tablet-s) {
    margin-bottom: 64px;
  }
}

.s-contact-banner__person {
  display: none;

  @include min-screen($tablet-s) {
    display: flex; 
    justify-content: flex-end;
    padding-top: 20px;
  }

  .s-our-management__person {
    padding-bottom: 0;
  }
}

.s-contact-banner__cta {
  display: flex;

  @include min-screen($tablet-s) {
    justify-content: flex-start;
  }
}

.s-contact-banner__person-mobile {
  display: flex;

  @include min-screen($tablet-s) {
    display: none;
  }
}

.c-button {
  margin-top: unset;
}
