.s-management {
  background-color: #e4e4e4;
}

.s-management__container {
  display: flex;
  justify-content: center;
  
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 375px;
    height: 220px;
    margin: 0 15px;
    background-color: $fm-white;

    img {
      margin-top: -30px;
    }
  }
}