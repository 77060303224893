.c-drpdown {
  background-color: $fm-white;
  padding-top: 50px;
}

.c-dropdown__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 50px;
  background-color: $fm-white;
  color: #000000;
  padding: 50px;
  border: 1px solid $fm-black-transparent;
  cursor: default; 
}

.c-dropdown__header {
  text-align: left;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 0;
  width: fit-content;

  @include min-screen($desktop-xl) {
    font-size: 24px;
    margin-bottom: 30px;
  }

    a {
      font-size: inherit;
      color: inherit;
      position: relative;
  
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 0;
        height: 1px;
        transition: width $transition-time $transition-easing;
        background-color: $fm-black;
      }
  
      &:hover::after {
        width: 100%;
      }
    }
  }

.c-dropdown__menu {
  li {
    width: 100%;
    margin-bottom: 14px;
    position: relative;
  }

  li.c-dropdown__item-retail {
    margin-top: 87px;

    &--mobile {
      margin-top: 0;
    }

    a {
      font-family: Aeonik;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      text-transform: none;
      padding-bottom: 28px;

      span {
        display: block;
        @include text--gradient-light();
      }
    }
  }

  li a {

    .contact-form--sent &,
    & {
      @include secondary-font;
      display: block;
      font-size: 12px;
      line-height: 14px;
      padding-bottom: 10px;
      color: #000000;
      text-decoration: none;

      @include min-screen($desktop-xl) {
        font-size: 13px;
      }
    }
  }

  li:before {
    content: '';
    position: absolute;
    top: 2px;
    right: 0;
    width: 10px;
    height: 10px;
    background-image: url('../images/arrow-black.svg');
    background-repeat: no-repeat;
    background-position: center right;
  }

  li.c-dropdown__item-retail:before {
    top: unset;
    bottom: 10px;
  }
  li.c-dropdown__item-retail:after {
    background: linear-gradient(89.04deg, $main-gradient-1 0.62%, $main-gradient-2 15.43%, $main-gradient-3 47.74%, $main-gradient-4 54.8%, $main-gradient-5 80.24%, $main-gradient-6 93.11%, $main-gradient-7 97.37%);
  }

  li:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: $fm-iron;
  }

  li:hover:after {
    background: linear-gradient(89.04deg, $main-gradient-1 0.62%, $main-gradient-2 15.43%, $main-gradient-3 47.74%, $main-gradient-4 54.8%, $main-gradient-5 80.24%, $main-gradient-6 93.11%, $main-gradient-7 97.37%);
  }
}

.c-dropdown__menu--wider {
  li {
    margin-bottom: 24px;
  }
}
