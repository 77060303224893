@mixin animationZoomOnHover() {
  transition: transform 5s cubic-bezier(.23,1,.32,1);
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
  }
}

.animation-zoom-on-hover {
  @include animationZoomOnHover;
}

.animation-fade-in-up-1s {
  animation: 1s fadeInLineUp ease-out;
}

.animation-fade-in-up {
  animation: 2s fadeInLineUp ease-out;
}

@keyframes fadeInLineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.animation-fade-in-left {
  animation: 2s fadeInLineLeft ease-out;
}

@keyframes fadeInLineLeft {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
