.s-case-study-results {
  color: $fm-white;
  padding: 40px 0;
  
  @include min-screen($tablet-s) {
      padding: 80px 0;
  }

  @include min-screen($desktop) {
        padding: 160px 0;
  }

  &__header {
    margin-bottom: 40px;
  
    @include min-screen($tablet-s) {
        margin-bottom: 60px;
    }

    @include min-screen($desktop) {
        margin-bottom: 120px;
    }
  }

  &__content {
    display: grid;
    gap: $grid-gap;
    grid-template-columns: 1fr;
    
    @include min-screen($tablet-s) {
        grid-template-columns: 5fr 1fr 6fr;
    }
  }

  &__text {
    grid-column: 1/2;

    h3 {
        margin-bottom: 20px;
        @include h--m--desktop-s();

        @include min-screen($tablet-s) {
            margin-bottom: 40px;
        }
    
        @include min-screen($desktop) {
            margin-bottom: 60px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        li {
            display: inline-flex;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 18px;
      
            &:before {
              content: '';
              background: url("../images/icon-tick-gray.svg") 50% 50% / contain no-repeat;
              display: inline-block;
              min-width: 24px;
              min-height: 24px;
              margin-right: 16px;
              margin-top: 0px;
            }
          }
      }
  }

  &__stats {
    display: grid;
    gap: $grid-gap;
    grid-template-columns: 1fr;
    margin-top: 40px;

    @include min-screen($tablet-s) {
        margin-top: 0;
        grid-column: 3/4;
        grid-template-columns: 1fr 1fr;
    }
  }

  &__stat {
    margin-bottom: 60px;

    @include min-screen($tablet-s) {
        margin-bottom: 60px;
    }

    h3 {
        margin-top: 20px;
        @include h--m();

        @include min-screen($tablet-s) {
            margin-top: 40px;
        }
    }

    p {
        color: $fm-grey;
        margin-top: 8px;

        @include min-screen($tablet-s) {
            margin-top: 12px;
        }
    }
  }
}
