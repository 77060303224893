// vednors
@import "~minireset.css/minireset.sass";
@import "~sass-mediaqueries/media-queries";
@import "~tiny-slider/dist/tiny-slider.css";

@import "settings/vars";
@import "settings/fonts";
@import "settings/mixins";
@import "settings/utils";
@import "settings/insights";

@import "mixins/typography";
@import "mixins/grid";
@import "mixins/order";
@import "mixins/flex";
@import "mixins/form";
@import "mixins/slider";
@import "mixins/report";

@import "base/base";
@import "base/buttons";
@import "base/tags";
@import "base/accordion";
@import "base/helpers";
@import "base/typography";
@import "base/grid";
@import "base/order";
@import "base/flex";
@import "base/spinner";
@import "base/slider";
@import "base/animations";
@import "base/bootstrap-grid"; // used in case studies
@import "base/lists";


@import "components/accessibility";


@import "components/common/description";
@import "components/common/categories-showcase";
@import "components/common/description-text-image";
@import "components/common/center-image";
@import "components/common/statistic";
@import "components/common/about-links";

@import "components/report/quote";
@import "components/report/patronage";
@import "components/report/cta";
@import "components/report/report-quote-with-desc";
@import "components/report/report-quote";
@import "components/report/report-preview";
@import "components/report//report_feature";
@import "components/report/report_image";
@import "components/report/report_actions";
@import "components/report/report_summary";

@import "components/thank-you-page/thank-you-page";

@import "components/advisory/advisory-intro";
@import "components/advisory/advisory-who-we-are";
@import "components/advisory/advisory-services-showcase";
@import "components/advisory/advisory-categories-showcase";
@import "components/advisory/advisory-contact";
@import "components/advisory/advisory-contact-form";
@import "components/advisory/advisory-service-header";
@import "components/advisory/advisory-cta";
@import "components/advisory/advisory-testimonials";
@import "components/advisory/advisory-services";

@import "components/career/career-header";
@import "components/career/career-benefits";
@import "components/career/career-introduction";
@import "components/career/career-company-overview";
@import "components/career/career-video";
@import "components/career/career-development";
@import "components/career/career-cta";
@import "components/career/career-recruitment-process";
@import "components/career/career-company-values";
@import "components/career/career-job-offers";
@import "components/career/carrer-header-job-offer";
@import "components/career/career-hiring-process";
@import "components/career/career-job-description";
@import "components/career/career-gallery";
@import "components/career/career-apply-form";
@import "components/career/career-insights";

@import "components/career/career-header";
@import "components/career/career-benefits";
@import "components/career/career-introduction";
@import "components/career/career-company-overview";
@import "components/career/career-video";
@import "components/career/career-development";
@import "components/career/career-cta";
@import "components/career/career-recruitment-process";
@import "components/career/career-company-values";
@import "components/career/career-job-offers";
@import "components/career/carrer-header-job-offer";
@import "components/career/career-hiring-process";
@import "components/career/career-job-description";
@import "components/career/career-gallery";
@import "components/career/career-apply-form";

@import "components/contact/contact-form";
@import "components/contact//contact-offices.scss";
@import "components/contact//contact-case-studies.scss";
@import "components/contact/contact-press-office.scss";
@import "components/hubspot-form.scss";


@import "components/header";
@import "components/case-studies-gallery";
@import "components/footer";
@import "components/newsletter";
@import "components/management";
@import "components/experts";
@import "components/cookies";
@import "components/video-header";
@import "components/header-cta";
@import "components/header-dropdown";
@import "components/insights-bar";
@import "components/insights-bar-content";
@import "components/insights-recent";
@import "components/copy";
@import "components/insights-carousel";
@import "components/services-showcase";
@import "components/recently-delivered";
@import "components/what-we-do";
@import "components/about-us";
@import "components/barometr";
@import "components/scrollable-company-name";
@import "components/gradient-hero-scene";
@import "components/mobile-app-development";
@import "components/careers";
@import "components/careers-filter";
@import "components/careers-header";
@import "components/mobile-nav";
@import "components/mobile-insights";
@import "components/portfolio-card";
@import "components/portfolio-cooperation-story";
@import "components/contact-banner";
@import "components/career-item";
@import "components/solutions-how-its-works";
@import "components/solutions-cooperations-fields";
@import "components/forms";
@import "components/insights-filters";
@import "components/advisory-solutions";
@import "components/contact";
@import "components/contact-section";
@import "components/contact-form";
@import "components/what-weve-done";
@import "components/insights-article";
@import "components/lifestyle-gallery";
@import "components/pagination";
@import "components/project";
@import "components/testimonial";
@import "components/animate";
@import "components/fleet-connect";
@import "components/connected-boat";
@import "components/app-health-check-lp";
@import "components/awards";
@import "components/video";
@import "components/case-study-header-cta";
@import "components/case-study-client";
@import "components/case-study-video";
@import "components/case-study-challenge";
@import "components/case-study-objective";
@import "components/case-study-scope";
@import "components/case-study-sections";
@import "components/case-study-partner-presentation.scss";
@import "components/case-study-results";
@import "components/case-study-cooperation-stories-list";
@import "components/case-study-featured-cooperation-story";
@import "components/case-study-more-cases";
@import "components/case-study-services";
@import "components/case-study-story-timeline";
@import "components/case-studies-recent";
@import "components/client-logos-slider";
@import "components/clients-reviews";
@import "components/download-page";
@import "components/download-raport";
@import "components/download-hero";
@import "components/download-about";
@import "components/download-description";
@import "components/quote";
@import "components/goal-list";
@import "components/press-office-page";
@import "components/press-office-who-we-are";
@import "components/press-office-download";
@import "components/press-office-contact";
@import "components/press-office-form";
@import "components/lang-labels";
@import "components/insights-listing";
@import "components/insights-block-featured";
@import "components/privacy-policy";
@import "components/review-card";
@import "components/clients-reviews";
@import "components/new-contact-banner";
@import "components/new-contact-form";
@import "components/cookiebot";
@import "components/floating-button";


@import 'components/custom-dropdown';
@import 'components/mobile-app-advisory';


// z-indexes
// career filters 3
// insights: 4
// header dropdown: 5
// mobile insights: 6
// hamburger menu: 7
// hamburger button & insights button mobile: 10
// cookies info: 8
