.s-awards {
  padding: 40px 0;
  color: $fm-white;
  @include min-screen($tablet-s) {
    padding: 80px 0;
  }
}

.s-awards__clutch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  @include min-screen($tablet-s) {
    flex-direction: row;
  }
}

.s-awards__description {
  width: 100%;
  margin-bottom: 32px;

  @include min-screen($tablet-s) {
    max-width: 470px;
  }
}

.s-awards__heading {
  margin-top: 40px;
  
  h2 {
    font-weight: 200;
  }
  
  @include min-screen($tablet-s) {
    margin-top: 80px;
  }
}

.s-awards__images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
  img {
    margin-right: 30px;
    margin-bottom: 16px;
  }

  @include min-screen($tablet-s) {
    justify-content: flex-start;
    img {
      margin-bottom: 0;
    }
  }
}