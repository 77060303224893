#CybotCookiebotDialog {
    background: rgba(21, 21, 21, 0.95) !important;
    border-radius: 7px !important;
    max-width: 435px !important;
    outline: 1px solid rgba(255, 255, 255, 0.40);
    opacity: 0;
    visibility: hidden;

    top: auto !important;
    bottom: 20px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    max-height: 80vh !important;

    backdrop-filter: blur($blur) !important;
    -webkit-backdrop-filter: blur($blur) !important;

    @include min-screen($phone) {
        top: auto !important;
        left: auto !important;
        right: 40px !important;
        bottom: 40px !important;
        transform: translate(0, 0) !important;
    }

    // MAIN WRAPPER
    .CybotCookiebotDialogContentWrapper {
        padding: 40px;
        row-gap: 40px;

        #CybotCookiebotDialogHeader {
            display: none;
        }

        #CybotCookiebotDialogNav {
            border: none;

            .CybotCookiebotFader {
                display: none;
            }

            //  NAVIGATION
            #CybotCookiebotDialogNavList {

                .CybotCookiebotDialogNavItem {

                    .CybotCookiebotDialogNavItemLink {
                        display: flex;
                        width: fit-content;
                        padding: 0;
                        color: $fm-white;
                        font-family: $base-font-family;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        border-color: transparent;
                    }

                    .CybotCookiebotDialogActive {
                        border-color: $fm-white;
                    }
                }
            }
        }

        //  BODY
        #CybotCookiebotDialogTabContent {

            // ABOUT, DETAILS TABS
            .CybotCookiebotDialogActive {
                padding: 0;
                border: none;
                // max-height: 220px; 

                .CybotCookiebotFader {
                    width: 100%;
                    transform: translateX(-2px) translateY(1px);
                    background: linear-gradient(to bottom, rgba(21, 21, 21, 0.95), rgba(21, 21, 21, 0));
                }

                // DETAILS TAB
                #CybotCookiebotDialogTabContentDetails {
                    max-height: 460px;

                    @include min-screen($tablet) {
                        max-height: 372px;
                    }

                    #CybotCookiebotDialogDetailBody {

                        #CybotCookiebotDialogDetailBodyContent {

                            #CybotCookiebotDialogDetailBodyContentTextOverview {

                                #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
                                    padding: 0;

                                    .CookieCard:first-child {
                                        padding-top: 0;
                                    }

                                    .CookieCard {
                                        padding-top: 40px;
                                        padding-bottom: 20px;
                                        padding-right: 12px;

                                        .CybotCookiebotDialogDetailBodyContentCookieContainerHeader {
                                            height: 24px;

                                            .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
                                                display: flex;
                                                align-items: center;
                                                font-family: $secondary-font-family;
                                                text-transform: uppercase;
                                                color: $fm-white;
                                                line-height: 20px;
                                                font-weight: 400;

                                                label {
                                                    font-size: 13px;
                                                }

                                                &::before {
                                                    display: inline-block;
                                                    border: none;
                                                    width: 24px;
                                                    height: 24px;
                                                    background-repeat: no-repeat;
                                                    background-position: left;
                                                    background-image: url('../images/chevron-white-down.svg');
                                                    transform: rotate(0deg);
                                                    top: 0;
                                                    margin-right: 10px;
                                                    transition: transform $transition-time $transition-easing;
                                                }

                                                .CybotCookiebotDialogDetailBulkConsentCount {
                                                    background: transparent;
                                                    font-family: $secondary-font-family;
                                                    font-size: 13px;
                                                    line-height: 20px;
                                                    text-transform: uppercase;
                                                    color: rgba(255, 255, 255, 0.75);
                                                }
                                            }

                                            .CybotCookiebotDialogDetailBodyContentCookieContainerButton[aria-expanded="true"] {

                                                &::before {
                                                    transform: rotate(180deg);
                                                }
                                            }

                                            // SWITCH
                                            .CybotCookiebotDialogBodyLevelButtonSliderWrapper {

                                                form {

                                                    .CybotCookiebotDialogBodyLevelButtonSlider {
                                                        height: 22px;
                                                        width: 52px;
                                                        background: $fm-iron;

                                                        &::before {
                                                            height: 18px;
                                                            width: 18px;
                                                            background: $fm-grey;
                                                            left: 2px;
                                                            bottom: 2px;

                                                        }

                                                        &:active {
                                                            border: none;
                                                            outline: none;
                                                        }

                                                    }

                                                    input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
                                                        background: $fm-white;

                                                        &::before {
                                                            background: $fm-iron;
                                                            transform: translateX(30px);
                                                        }
                                                    }

                                                    input:disabled+.CybotCookiebotDialogBodyLevelButtonSlider {
                                                        background: $fm-iron;

                                                        &::before {
                                                            background: $fm-grey;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        // CARD TEXT
                                        .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
                                            padding-top: 20px;
                                            color: $fm-stone;
                                            font-family: $base-font-family;
                                            font-size: 12px;
                                            font-weight: 400;
                                            line-height: 16px;

                                            @media screen and (min-width: 1200px) {
                                                padding-left: 0;
                                            }
                                        }

                                        // CARD COLLAPSED BODY
                                        .CollapseCard {

                                            .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer {
                                                padding-top: 20px;

                                                @media screen and (min-width: 1200px) {
                                                    margin-left: 0;
                                                }

                                                .CybotCookiebotDialogDetailBodyContentCookieTabContent {

                                                    .CybotCookiebotDialogDetailBodyContentCookieGroup {
                                                        padding: 0;
                                                        border: none;
                                                        width: 90%;
                                                        margin-bottom: 20px;

                                                        // ACCORDION
                                                        .CybotCookiebotDialogDetailBodyContentCookieProvider {
                                                            border-bottom: 1px solid $fm-iron;
                                                            padding-bottom: 10px;
                                                            font-family: $secondary-font-family;
                                                            font-size: 13px;
                                                            line-height: 20px;
                                                            text-transform: uppercase;
                                                            color: $fm-white;
                                                            position: relative;

                                                            &::after {
                                                                content: '';
                                                                position: absolute;
                                                                top: 4px;
                                                                right: 9px;
                                                                width: 2px;
                                                                height: 12px;
                                                                display: block;
                                                                background-color: $fm-white;
                                                                transition: all 0.3s ease;
                                                                opacity: 1;
                                                                transform: rotate(0);
                                                                border: none;
                                                            }

                                                            &::before {
                                                                content: '';
                                                                position: absolute;
                                                                top: 9px;
                                                                right: 4px;
                                                                height: 2px;
                                                                width: 12px;
                                                                background-color: $fm-white;
                                                                transition: all 0.4s ease;
                                                                transform: rotate(0);
                                                            }

                                                            .CybotCookiebotDialogDetailBodyContentCookieInfoCount {
                                                                display: none;
                                                            }
                                                        }

                                                        // LINK BELOW THE ACCORDION
                                                        .CybotCookiebotDialogDetailBodyContentCookieLink {
                                                            display: none;
                                                        }

                                                        // ACCORDION CONTENT
                                                        .CybotCookiebotDialogDetailBodyContentCookieInfo {
                                                            margin-top: 20px;
                                                            padding: 0;
                                                            border: none;
                                                            background-color: transparent;
                                                            font-family: $base-font-family;

                                                            .CybotCookiebotDialogDetailBodyContentCookieInfoTitle {
                                                                color: $fm-stone;
                                                                font-size: 13px;
                                                                font-weight: 400;
                                                                line-height: 160%;
                                                                margin-bottom: 10px;
                                                            }

                                                            .CybotCookiebotDialogDetailBodyContentCookieInfoDescription {
                                                                color: $fm-grey;
                                                                font-size: 12px;
                                                                font-weight: 400;
                                                                line-height: 16px;
                                                                padding: 0;
                                                                margin-bottom: 10px;

                                                            }

                                                            .CybotCookiebotDialogDetailBodyContentCookieInfoFooter {
                                                                border: none;
                                                                padding: 0;
                                                                flex-direction: row;
                                                                justify-content: space-between;
                                                                margin-bottom: 30px;

                                                                span,
                                                                b {
                                                                    margin-top: 0;
                                                                    width: fit-content;
                                                                    font-family: $secondary-font-family;
                                                                    font-size: 13px;
                                                                    font-weight: 400;
                                                                    line-height: 20px;
                                                                    text-transform: uppercase;
                                                                    color: $fm-stone;
                                                                }

                                                                b {
                                                                    color: $fm-grey;
                                                                }
                                                            }

                                                            &::before {
                                                                display: none;
                                                            }
                                                        }
                                                    }

                                                    // ACCORDION ACTIVE 
                                                    .CybotCookiebotDialogDetailBodyContentCookieGroup.open .CybotCookiebotDialogDetailBodyContentCookieProvider {

                                                        &::after {
                                                            opacity: 0;
                                                            transform: rotate(720deg);
                                                        }

                                                        &::before {
                                                            transform: rotate(720deg);
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        // UNCLASSIFIED CARD
                                        #CybotCookiebotDialogDetailBodyContentCookieContainerUnclassified {
                                            display: flex;
                                            align-items: center;
                                            font-family: $secondary-font-family;
                                            text-transform: uppercase;
                                            color: $fm-white;
                                            font-size: 13px;
                                            line-height: 20px;
                                            font-weight: 400;

                                            &::before {
                                                display: inline-block;
                                                border: none;
                                                width: 24px;
                                                height: 24px;
                                                background-repeat: no-repeat;
                                                background-position: left;
                                                background-image: url('../images/chevron-white-down.svg');
                                                transform: rotate(0deg);
                                                top: 0;
                                                margin-right: 10px;
                                                transition: transform $transition-time $transition-easing;
                                            }

                                            .CybotCookiebotDialogDetailBulkConsentCount {
                                                background: transparent;
                                                font-family: $secondary-font-family;
                                                font-size: 13px;
                                                line-height: 20px;
                                                text-transform: uppercase;
                                                color: rgba(255, 255, 255, 0.75);
                                            }
                                        }

                                        #CybotCookiebotDialogDetailBodyContentCookieContainerUnclassified[aria-expanded="true"] {
                                            &::before {
                                                transform: rotate(180deg);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // CARD FOOTER
                        #CybotCookiebotDialogDetailFooter {
                            display: none;
                        }
                    }
                }

                // ABOUT
                #CybotCookiebotDialogDetailBodyContentTextAbout {
                    border: none;
                    padding: 0;
                    color: $fm-stone;
                    font-family: $base-font-family;
                    font-weight: 400;
                    line-height: 20px;
                    max-height: 460px;

                    @include min-screen($tablet) {
                        max-height: 372px;
                    }

                    p {
                        span {
                            font-size: 15px;
                 
                            @include min-screen($tablet) {
                                font-size: 13px;
                            }
                            
                            @include min-screen($desktop-xl) {
                                font-size: 14px;
                            }
                        }

                        a {
                            font-weight: 400;
                            color: $fm-stone;
                            text-decoration: underline;
                        }
                    }
                }
            }

            // CONSENT
            #CybotCookiebotDialogBody {

                .CybotCookiebotScrollContainer {
                    padding: 0;
                    border: none;
                    min-height: unset;

                    #CybotCookiebotDialogBodyContent {
                        padding: 0;

                        .CybotCookiebotFader {
                            display: none;
                        }

                        #CybotCookiebotDialogBodyContentTitle {
                            display: none;
                        }

                        #CybotCookiebotDialogBodyContentText {
                            color: $fm-stone;
                            font-family: $secondary-font-family;
                            font-weight: 400;
                            line-height: 20px;

                            p {
                                span {
                                    font-size: 15px;
                                    text-transform: uppercase;

                                    @include min-screen($tablet) {
                                        font-size: 13px;
                                    }
                                    
                                    @include min-screen($desktop-xl) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }

                .CybotCookiebotDialogBodyBottomWrapper {
                    display: none;
                }
            }
        }

        //  FOOTER
        #CybotCookiebotDialogFooter {
            padding: 0;

            #CybotCookiebotDialogBodyButtons {

                #CybotCookiebotDialogBodyButtonsWrapper {
                    column-gap: 16px;

                    .CybotCookiebotDialogBodyButton {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 8px 12px;
                        border: none;
                        border-radius: 6px;
                        background-color: $fm-iron;
                        color: $fm-alabaster;
                        font-family: $secondary-font-family;
                        text-transform: uppercase;
                        font-size: 15px;
                        line-height: 20px;
                        font-weight: 400;
                        white-space: nowrap;

                        @include min-screen(601px) {
                            width: fit-content;
                        }

                        @include min-screen($tablet) {
                            font-size: 13px;
                        }
                        
                        @include min-screen($desktop-xl) {
                            font-size: 14px;
                        }

                        &--iron {
                            background-color: $fm-iron !important;
                            color: $fm-alabaster !important;
                        }

                        &--white {
                            background-color: $fm-white !important;
                            color: $fm-black !important;
                        }
                    }

                    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
                        display: none;
                        background-color: $fm-white;
                        color: $fm-black;
                    }

                    #CybotCookiebotDialogBodyLevelButtonCustomize {
                        .CybotCookiebotDialogArrow {
                            display: none;
                        }
                    }

                    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
                        background-color: $fm-white;
                        color: $fm-black;
                        width: 100%;
                    }
                }
            }

            #CybotCookiebotDialogPoweredByText {
                display: none;
            }
        }
    }
}