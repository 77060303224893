.c-tag {
  @include secondary-font;
  @include btn--m;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
}

.c-tag__inner {
  padding-right: 32px;
}

.c-tag--text {
  @include secondary-font;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  @include min-screen($desktop-xl) {
    font-size: 13px;
  }
}

.c-tag--grey {
  background-color: $fm-smoke;
  color: $fm-black-transparent;
}

.c-tag--text-grey {
  @extend .c-tag--text;
  color: $fm-grey;
}

.c-tag--grey-sm {
  @extend .c-tag--text;
  color: $fm-grey;
  font-size: 10px;
  line-height: 17px;
}

.c-tag--iron {
  background-color: $fm-iron;
  color: $fm-stone;
}

.c-tag--transparent-grey {
  background: $fm-transparent;
  color: rgba(255, 255, 255, 0.5);
}

.c-tag--transparent-black {
  background: $fm-black;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid $fm-transparent;
}


.c-tag--insights {
  @extend .c-tag--transparent-black;
  cursor: pointer;

  @include min-screen($desktop-s)  {
    background: $fm-transparent;
    color: $fm-transparent-2;
    border: none;
  }
}


.c-tag--white {
  color: #000000;
  background: $fm-white;
  border: 1px solid #e0e0e0;
}

.c-tag--white-no-border {
  color: #000000;
  background: $fm-white;
  border: 1px solid $fm-white;
}

.c-tag--black {
  color: $fm-white;
  background-color: $fm-black;
}

.c-tag--transparent {
  color: #000000;
  background: transparent;
  border: 1px solid #e0e0e0;
}

.c-tag--transparent-white {
  color: $fm-transparent-2;
  background: transparent;
  border: 1px solid $fm-transparent;
}

.c-tag--transparent-smoke {
    color: $fm-smoke;
    background: transparent;
    border: 1px solid $fm-transparent;
}

.c-tag--white-outlined {
  color: $fm-white;
  background: transparent;
  border: 1px solid $fm-transparent-2;
}

.c-tag__arrow {
  display: inline-block;
  width: 15px;
  height: 10px;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: left;
  transition: background-position 0.3s ease-out;
  background-image: url("../images/arrow-black.svg");

  .c-tag--black & {
    background-image: url("../images/arrow-white.svg");
  }
}

.c-tag:hover .c-tag__arrow {
  background-position: right;
}

.c-tag--s {
  padding: 5px 7px;
  border-radius: 5px;
}

.c-tag--carousel-more {
  cursor: pointer;
  background-image: url("../images/dots.svg");
  background-position: center center;
  background-repeat: no-repeat;
  color: rgba($fm-black, 0.12);
}
