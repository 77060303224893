.s-description-text-image {
    display: grid;
    grid-template-areas:
        'image'
        'text';
    row-gap: 64px;
    padding-block: 60px;

    @include min-screen($tablet-s) {
        grid-template-areas:
        'text image';
        grid-template-columns: 1fr minmax(1fr, 504px);
        column-gap: clamp(3rem, -2.1285rem + 10.6984vw, 7.5rem);
    }

    @include min-screen($tablet)  {
        padding-block: 80px;
    }

    &--imgOnTheLeft {
        @include min-screen($tablet-s) {
            grid-template-areas:
            'image text';
            grid-template-columns: minmax(1fr, 504px) 1fr;
        }
    }

    &__text {
        @include reportTypography();
        grid-area: text;
        height: fit-content;

        @include min-screen($tablet-s) {
            align-self: center;
        }
    }

    &__image {
        grid-area: image;
        width: 100%;
  
        @include min-screen($tablet-s) {
            height: clamp(21.125rem, 9.3009rem + 24.6657vw, 31.5rem);
            width: clamp(21.125rem, 9.3009rem + 24.6657vw, 31.5rem);
            // $tablet-s - 338px - $desktop-l 504px
            aspect-ratio: 1 / 1;
        } 

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;

        }
    }
}
