.s-newsletter {
  color: $fm-white;
  padding: 50px 0;
  @include min-screen($tablet) {
    padding: 100px 0 84px 0;
  }

  @include min-screen($desktop-xl) {
    padding: 140px 0 140px 0;
  }
}

.s-newsletter__container {
  display: grid;
  @include min-screen($tablet) {
    grid-template-columns: 246px auto;
  }
  @include min-screen($desktop-s) {
    grid-template-columns: 346px auto;
  }

  @include min-screen($desktop-xl) {
    grid-template-columns: 500px auto;
  }
}

.s-newsletter__form {
  @include input-text;
  justify-content: space-between;
  padding-right: 0;

  input::placeholder {
    font-size: 13px;
    font-family: $secondary-font-family;
  }
}

.s-newsletter__copy {
  @include secondary-font;
  font-size: 15px;
  padding-top: 15px;

  @include max-screen($tablet) {
    display: none;
    font-size: 13px;
  }

  @include min-screen($tablet) {
    font-size: 13px;
  }

  @include min-screen($desktop-xl) {
    font-size: 14px;
  }
}

.s-newsletter__submit {
  background: none;
  border: none;
  cursor: pointer;
}

.s-newsletter__form-container {
  h1 {
    font-weight: 150;
    margin-bottom: 21px;
    font-size: 35px;
    line-height: 40px;

    @include min-screen($tablet-s) {
      font-size: 45px;
      line-height: 55px;
    }

    @include min-screen($desktop-s) {
      font-size: 55px;
    }

    @include min-screen($desktop-xl) {
      font-size: 75px;
    }

    span {
      font-weight: 400;
      @include text--gradient-light();
    }
  }
}

.s-newsletter__form-checkbox {
  display: flex;

  label {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 0px;
    padding-top: 0px;
    cursor: pointer;
    font-size: 15px;
    line-height: 26px;
    color: $fm-light-grey;

    @include min-screen($tablet) {
      font-size: 13px;
    }

    @include min-screen($desktop-xl) {
      font-size: 18px;
    }
  }

  label input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .s-newsletter__checkbox-indicator {
    position: absolute;
    top: 7px;
    left: 0;
    height: 16px;
    width: 16px;
    background: $fm-black;
    border: 1px solid #707070;
    border-radius: 2px;
  }
  label:hover input ~ .s-newsletter__checkbox-indicator,
  label input:focus ~ .s-newsletter__checkbox-indicator {
    background: #707070;
  }

  label input:checked ~ .s-newsletter__checkbox-indicator {
    background: #707070;
  }
  label:hover input:not([disabled]):checked ~ .s-newsletter__checkbox-indicator,
  label input:checked:focus ~ .s-newsletter__checkbox-indicator {
    background: #707070;
  }
  label input:disabled ~ .s-newsletter__checkbox-indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  .s-newsletter__checkbox-indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
  }
  label input:checked ~ .s-newsletter__checkbox-indicator:after {
    display: block;
  }
  .s-newsletter__checkbox-control {
    text-align: left;
  }
  .s-newsletter__checkbox-control .s-newsletter__checkbox-indicator:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid $fm-white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .s-newsletter__checkbox-control input:disabled ~ .s-newsletter__checkbox-indicator:after {
    border-color: #7b7b7b;
  }
}

.c-newsletter__success {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  height: 0;
  width: 0;
}

.c-newsletter__success--visible {
  opacity: 1;
  height: auto;
  max-width: 400px;
  width: 100%;
  margin-top: 26px;
}

.s-newsletter_error-email {
  margin-top: -20px;
  margin-bottom: 20px;
}
