.report_quote_with_desc {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  padding-block: 60px;

 @include min-screen($phone) {
    row-gap: 120px;
 }

 @include min-screen($tablet-s) {
    flex-direction: row;
    column-gap: clamp(3rem, -2.1285rem + 10.6984vw, 7.5rem);
    // $tablet-s - 48px - $desktop-s- 120px 
 }

 @include min-screen($tablet) {
   padding-block: 80px
 }

 &__quote {
  flex: 1 1;

    .quote__text {

        h2 {
            font-size: 32px;
            line-height: 40px;

            @include min-screen($phone) {
                font-size: 45px;
                line-height: 56px;
            }
            
            @include min-screen($tablet-s) {
                font-size: 55px;
                line-height: 72px;
            }
        }

        p {
            font-size: 20px;
            line-height: 26px;
            font-weight: 500;
        }
    }

    .quote__line--report {
        height: 6px;
        margin-bottom: 0;
    }
  }

  &__text {
    flex: 1;
    @include reportTypography();
  }
}
