.s-contact-form-container {
  padding: 40px 0;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;

  & > h2 {
    @include h--m();
    text-align: center;
    margin-bottom: 40px;

    .swal2-container & {
      margin-bottom: 32px;
    }

    & > a {
      text-decoration: none;
    }

    & > span {
      @include text--gradient;
    }
  }

  & > h3 {
    @include h--m();
    color: white;
    margin-bottom: 20px;
  }

  @include min-screen($tablet) {
    padding: 100px 0;

    .swal2-container & {
      padding: 0;
    }

    & > h3 {
      margin-bottom: 60px;
    }
  }

  .swal2-container & {
    max-width: 1072px;
  }
}

.c-contact-form {
  max-width: 600px;
  width: 100%;

  .swal2-container & {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

    @include min-screen($tablet) {
      margin-top: 80px;
    }
  }
}

.c-contact-form__input {
  display: block;
  width: 100%;
  margin-bottom: 28px;

  input,
  textarea {
    width: 100%;
    padding: 12px 0;
    border: none;
    -webkit-border-radius: 0;
    outline: none;
    border-bottom: 2px solid $fm-grey;
    font-family: $base-font-family;
    color: $fm-black;
    font-size: 13px;

    // prevent auto zoom on iOS
    @include max-screen($phone) {
          font-size: 16px;
        }
   
    &::placeholder {
      font-family: $secondary-font-family;
      text-transform: uppercase;
      color: $fm-grey;
    }
  }

  @include min-screen($tablet) {
    margin-bottom: 48px;
  }

  textarea {
    height: 140px;
    border: 2px solid $fm-grey;
    padding: 12px;
  }

  &--transparent {
    input,
    textarea {
      background: transparent;
    }
  }
}

.c-contact-form__textarea--outlined {
   textarea {
    border-bottom: 2px solid $fm-grey;
    border-style: none none solid none;
    padding-left: 0;
    padding-top: 0;
  }
}

.c-contact-form__submit {
  margin-top: 30px;

  @include min-screen($tablet) {
    margin-top: 50px;
  }
}

.c-contact-form__success {
  margin: 0 auto;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;

  .s-header-cta-gradient::after, .s-carrer-header-job-offer::after {
    display: none;
  }
}

.js-form-success--visible {
  opacity: 1;
  height: auto;
  max-width: 100%;
  width: 100%;

  .s-header__subtext {
    margin-inline: auto;
  }

  h3 {
    margin-bottom: 20px;
  }

  a {
    margin-top: 10px;
  }
}

.swal2-close {  
  &,
  &:focus,
  &:hover,
  &:active {
    outline: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
  }
}

.contact-form--sent {
  background-color: $fm-black;
}

.contact-form__popup--sent {
  text-align: left;

  .swal2-close {
    color: #fff;
  }

  &.swal2-popup {
    background-color: $fm-black;
  }

  .s-header__subtext {
    margin-left: auto;
    margin-right: auto;
  }
}

.js-form-resource-success {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.js-form-resource-success--visible {
  visibility: visible;
  opacity: 1;
}

.s-download-contact {
  display: flex;
  flex-direction: column-reverse;
  padding: 80px 0;
  position: relative;

  @include min-screen($tablet) {
    flex-direction: row;
    padding: 160px 0;
    column-gap: $grid-gap-xxl;

    > div:first-child {
      max-width: 500px;
    }
  }

  @include min-screen($desktop-xl) {
    > div:first-child {
      max-width: 700px;
    }
  }

  &__heading {
    margin-block: 20px;

    @include min-screen($tablet) {
      margin-block: 0 80px;
    }

    span {
      @include colors-gradient-press-office;
      @include gradient-text;
    }
  }

  &__image {
    display: none;

    @include min-screen($tablet) {
      display: flex;
      flex: 1;
      text-align: center;
      justify-content: center;
      text-align: center;
    }

    @include min-screen($desktop-s) {
      text-align: right;
    }

    img {
      max-height: 586px;

      @include min-screen($desktop-l) {
        max-height: 700px;
        position: absolute;
        left: 55%;
        transform: translateX(-50%);
        top: 50%;
        transform: translateY(-50%);
      }
    }

        &--with-title-top {
       
          img {
   
            @include min-screen($desktop-l) {
              max-height: 586px;
              position: initial;
              left: unset;
              top: unset;
              transform: none;
            }
          }
        }
  }

  &__persons {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-bottom: 80px;

    @include min-screen($tablet) {
      row-gap: 60px;
      margin-bottom: unset;
    }

    &--item {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      &--details {
        display: flex;
        flex-direction: column;

        p, a {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          font-family: $secondary-font-family;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        object-position: center;
        object-fit: cover;

        @include min-screen($tablet) {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
  .s-download-contact__form {
    padding: 40px;
  }

  .s-download-contact__form, input {
      background-color: $fm-alabaster;
  }
}

.s-download-contact--with-persons {
  flex-direction: column;
  row-gap: 80px;

    @include min-screen($tablet) {
        column-gap: 120px;
        flex-direction: row;
    
        > div:first-child {
          flex: 1;
          max-width: 600px;
        }
      }
    
      @include min-screen($desktop-xl) {
        column-gap: 160px;
    
    
        > div:first-child {
          max-width: 700px;
        }
      }
}

.s-raport__form-success {
  height: 500px;
  display: none;
  justify-content: center;
  flex-direction: column;
}

.top-heading {
  h3 {
    p {
      margin-bottom: 55px;
    }
  }
}

.form-variant-report {
  @include min-screen($tablet) {
    padding-top: 80px;
  }
}