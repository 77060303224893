.s-about__links {
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    padding-block: 40px;

    @include min-screen($tablet-s) {
        flex-direction: row;
        column-gap: clamp(3.75rem, -3.4432rem + 15.0054vw, 12.5rem); // $tablet-s -> 60px $desktop-xl - 200px
    }

    @include min-screen($tablet) {
        padding-block: 80px;
    }
}

.s-about__links-info {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    width: 100%;
    max-width: 700px;

    @include min-screen($tablet-s) {
        max-width: 438px;
    }

    @include min-screen($desktop-xl) {
        max-width: 500px;
    }

    a {
        margin-top: 24px;
    }

    &--title {
        @include h--l();
    }

    &--text {
        @include b--m();
        color: $fm-black;

        p:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }
}

.s-about__links-list {
    display: flex;
    width: 100%;
    max-width: 700;
    flex-direction: column;
    justify-content: flex-end;
    row-gap: 32px;

    @media only screen and (min-width: 767px) {
        justify-content: center;

      }

    &--item {
        position: relative;

        a {
            display: flex;
            padding-bottom: 20px;
            @include h--xs();
            color: $fm-black;
            text-decoration: none;
            text-transform: uppercase;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            height: 24px;
            background-image: url("../images/arrow-black-lg.svg");
            background-repeat: no-repeat;
            background-position: center right;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background: $fm-black;
        }

        &:hover:after {
            background: linear-gradient(
                89.04deg,
                $main-gradient-1 0.62%,
                $main-gradient-2 15.43%,
                $main-gradient-3 47.74%,
                $main-gradient-4 54.8%,
                $main-gradient-5 80.24%,
                $main-gradient-6 93.11%,
                $main-gradient-7 97.37%
            );
        }
    }
}
