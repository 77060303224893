@mixin make-order() {
  @each $name, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint){
      @for $i from 1 through 12 {
        .order-#{$name}-#{$i} {
          order: $i;
        }
      }
    }
  }
}
