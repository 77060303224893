.s-case-study-client {

    &__heading {
        color: $fm-white;
        padding-top: 80px;

        @include min-screen($tablet) {
            padding-top: 160px;
        }
    }

    &__features-list {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 60px;
        margin: 80px 0;

        h3 {
            @include h--m--desktop-s;
            color: #fff;
            letter-spacing: -0.01em;
        }

        p {
            margin-top: 10px;
            margin-bottom: 30px;
            color: $fm-grey;
        }

        @include min-screen($tablet) {
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 100px;
            margin: 120px 0 0;

            h3 {
                margin-bottom: 20px;
            }
            p {
                margin-top: 0px;
            }
        } 
    }
    

    &__meta {
        display: grid;
        grid-template-columns: 1fr;
        gap: $grid-gap;
        padding-top: 40px;
        padding-bottom: 60px;
        color: $fm-white;

        @include min-screen($tablet) {
            grid-template-columns: 4fr 8fr;
            padding-bottom: 160px;
        }
    }

    &__tags-list {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    &__quote {
        margin-top: 16px;

        @include min-screen($tablet) {
            margin-top: 0;
        }

        &:before {
            content: '';
            @include colors-gradient();
            height: 4px;
            display: block;
        }

        > p {
            margin-top: 40px;
        }
    }

    &__author {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &-image {
            max-width: 65px;
        }
    }

    &__author-data {
        margin-left: 16px;

        span {
            margin-top: 0px;
            display: inline-block;
            color: $fm-grey;
        }
    }
}
