.s-advisory__who-we-are {
    padding-block: 40px;

    h2 {
        margin-bottom: 30px;
    }

    @include min-screen($tablet) {
        padding-block: 80px;

        h2 {
            margin-bottom: 60px;
        }
    }
}

.s-advisory__who-we-are__item {
    display: grid;
    grid-template-areas:
        'text'
        'image';
    row-gap: 40px;

    @include min-screen($tablet-s) {
        grid-template-areas:
            'text image';
        grid-template-columns: 1fr minmax(1fr, 504px);
        column-gap: clamp(3rem, -2.1285rem + 10.6984vw, 7.5rem);
    }

    &--text {
        @include formatRichText();
        grid-area: text;
        height: fit-content;

        p {
            @include min-screen($tablet) {
                font-size: 20px;
            }
        }

        
    }

    &--image {
        grid-area: image;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 450px;

            @include min-screen($tablet-s) {
                height: clamp(21.125rem, 9.3009rem + 24.6657vw, 31.5rem);
                width: clamp(21.125rem, 9.3009rem + 24.6657vw, 31.5rem);
                // $tablet-s - 338px - $desktop-l 504px
                aspect-ratio: 1 / 1;
            }

            @include min-screen($desktop-xl) {
                max-height: 325px;
                max-width: 325px;
            }

        }
    }
}