.s-video-header {
  position: relative; 
  overflow:hidden;
  height: 600px;

  video {
    position: absolute; 
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    object-fit: cover;
    height: 500px;

    @include min-screen($phone) {
      height: 100%;
    }

    @include min-screen($desktop-s) {
      left: 200px;
    }
  }

  @include min-screen($tablet-s) {
    height: 500px;
  }

  @include min-screen($tablet) {
    height: 600px;
  }

  @include min-screen($desktop-s) {
    height: 700px;
  }
  
  @include min-screen($desktop-xl) {
    height: 890px;
  }
}

.s-video-header__cta-outer {
  position: relative;
  @include baseContainerSize();
}

.s-video-header__cta-inner {
  position: absolute;
  margin-top: 200px;

  @include min-screen($tablet-s) {
    margin-top: 100px
  }
}