.s-case-study-featured-cooperation-story {
    position: relative;
    margin-bottom: 120px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 50%;
        background-color: $fm-black;
        z-index: 0;
    }

    &__inner {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba($fm-black, 0.12);
        border-radius: 16px;
        overflow: hidden;
        margin: auto;

        @include min-screen($tablet-s) {
            flex-direction: row-reverse;
            align-items: stretch;
        }
    }

    &__header {
        background-color: $fm-white;
        padding: 30px;
        
        @include min-screen($tablet-s) {
            flex: 1;
            padding: 60px;
        }
        
        @include min-screen($desktop) {
            padding: 90px;
        }

        h3 {
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 28px;
            max-width: 476px;
            @include b--m;
        }
    }

    &__image {
        flex: 1;
        object-fit: cover;
        @include min-screen($tablet-s) {
            width: 40%;
        }

        @include min-screen($tablet) {
            max-width: 576px;
            width: auto;
        }
    }
}