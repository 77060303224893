.s-career-hiring-process {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding-block: 40px;

    @include min-screen($tablet) {
        flex-direction: row;
        column-gap: clamp(3.75rem, -0.9223rem + 9.7466vw, 6.875rem); // $tablet-s = 60px to $desktop = 110px
    }

    @include min-screen($tablet) {
        padding-block: 80px 87px;
    }

    &--headings {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        flex: 0.55;
    }

    &--title {
        @include h--l();
        font-weight: 250;

        b {
            font-weight: 400;
        }

        @include min-screen($tablet) {
            transform: translateY(-8px);
        }

        @include min-screen($desktop-s) {
            transform: translateY(-15px);
        }

        @include min-screen($desktop-xl) {
            transform: translateY(-20px);
        }
    }

    &--steps {
        flex: 0.45;
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
}

.s-career-hiring-process__list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &--item {
        display: flex;
        column-gap: 16px;

        span {
            @include h--xs();
            font-family: $secondary-font-family;
            text-transform: uppercase;
            height: fit-content;
            align-self: flex-end;
        }

        p {
            @include h--s();
            color: $fm-white;
        }
    }
}

.s-career-hiring-process__closing-remarks {
    display: flex;
    @include h--s();
    @include colors-gradient-cold();
    @include gradient-text();
    padding-left: 39px;
  
}
