@mixin input-text() {
  display: flex;
  padding: 10px;
  padding-left: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid grey;

  input {
    display: block;
    color: $fm-white;
    background-color: $fm-black;
    max-width: 600px;
    width: 100%;
    font-size: 15px;
    font-family: $base-font-family;
    border: none;
    outline: none;
    border-radius: 4px;

    &::placeholder {
      text-transform: uppercase;
    }
  }
}
