.s-career-insights {

    .s-insights-recent {
        @include min-screen($tablet) {
            padding-block: unset;
            padding-bottom: 40px;
        }

        @include min-screen($desktop-xl) {
            padding-block: 40px;
        }
    }
}