.s-company-name {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 100px;
  padding-top: 60px;

  @include min-screen($tablet-s) {
    padding-bottom: 175px;
    padding-top: 125px;
  }

  @include min-screen($desktop-s) {
    padding-bottom: 250px;
    padding-top: 175px;
  }

  @include min-screen($desktop-xl) {
    padding-bottom: 350px;
    padding-top: 200px;
  }
}

.s-company-name__text {
  font-size: clamp(3.4375rem, 0.3906rem + 15.2344vw, 15.625rem); // 55px - 250px
  line-height: 1;
  width: 100%;
  white-space: nowrap;
  transform: translateX(80%);
}
