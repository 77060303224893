.slider-button__prev, .slider-button__next {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 2;
  text-decoration: none;
  height: 100%;
  width: 84px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;

  @include max-screen($tablet) {
    display: none;
  }
}

.slider-button__prev {
  background: linear-gradient(90deg, rgba(21, 21, 21, 0.8) 2%, rgba(21, 21, 21, 0) 98.2%);
  left: 0;
  .s-career-testimonials & {
    background: transparent;
  }
}


.slider-button__next {
  background: linear-gradient(270deg, rgba(21, 21, 21, 0.8) 2%, rgba(21, 21, 21, 0) 98.2%);
  right: 0;
  .s-career-testimonials & {
    background: transparent;
  }
}


.slider-button__next::before, .slider-button__prev::before {
  content: '';
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  background-image: url('../images/arrow-white.svg');
  background-size: 29px 25px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s;

  .s-people-slider & {
    display: none;
  }

  .s-career-testimonials & {
    background-image: url('../images/arrow-black.svg') !important;

  }
}

.slider-button__prev::before {
  transform: rotate(180deg);
}

.slider-button__next:hover::before, .slider-button__prev:hover::before {
  background-size: 34px 30px;
}

.slider-button--lifestyle {
  margin-top: 5px;
  height: calc(100% - 10px);
}

.slider-button--services {
  margin-top: 6px;

  .s-people-slider & {
    margin-top: 0px;
  }
}

.slider-button__prev--light-grey {
  background: linear-gradient(90deg, rgba(21, 21, 21, 0.32) 2%, rgba(21, 21, 21, 0) 98.2%);
}

.slider-button__next--light-grey {
  background: linear-gradient(270deg, rgba(21, 21, 21, 0.32) 2%, rgba(21, 21, 21, 0) 98.2%);
}