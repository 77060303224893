.s-advisory__intro {
    padding-block: 80px;

    @include min-screen($desktop-s) {
        padding-block: 148px;
    }

    h2 {
        @include h--l();
        margin-inline: auto;
        text-align: center;
        max-width: 26ch;

        p {
            .text--gradient {
                @include colors-gradient-eb;
            }
        }
    }

    &--black-bg {
        background: $fm-black;
        color: $fm-white;

        .text--gradient {
            background-image: linear-gradient(90deg,
            #F6B2A2 0%,
            #E84230 9.96%,
            #AF2272 27.68%,
            #9F154B 32.1%,
            #804CBB 45.83%,
            #4060F2 58.23%,
            #0096C7 88%,
            #5CC2D7 100%) !important;
            background-clip: text;
            -webkit-background-clip: text;
            text-fill-color: transparent;
            -webkit-text-fill-color: transparent;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }
    }
}