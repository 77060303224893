.s-case-study-more-cases {
    margin: 60px 0;

    @include min-screen($tablet-s) {
        margin: 80px 0;
    }
    
    @include min-screen($desktop) {
        margin: 120px 0;
    }

    &__header {
        margin-bottom: 60px;
    }

    &__item {
        position: relative;
        display: block;
        text-decoration: none;
        padding: 32px 0;
        border-bottom: 1px solid $fm-stone;
        transition: 0.2s all ease;

        @include min-screen($tablet-s) {
            padding: 48px 0;
        }
        
        @include min-screen($desktop) {
            padding: 64px 0;
        }

        &:after {
            content: url('../images/arrow-black.svg');
            display: inline-block;
            position: absolute;
            right: 16px;
            top: calc(50% - 8px);
        }

        &:hover {
            padding-left: 30px;
          }

        .h--m {
            margin-bottom: 12px;
        }
        
        .c-tag--text {
            color: $fm-iron;
        }
    }

    &__more {
        margin-top: 60px;
    }
}