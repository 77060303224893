.s-solutions__how-it-works {
    padding-block: 40px;

    h2 {
        margin-bottom: 30px;

        span {
            font-weight: 200;
        }
    }

    @include min-screen($tablet) {
        padding-block: 80px;

        h2 {
            margin-bottom: 60px;
            font-weight: 300;

            b {
                font-weight: 500;
            }
        }
    }
}

.s-solutions__how-it-works--container {
    display: flex;
    flex-direction: column;
    row-gap: 80px;

    @include min-screen($tablet) {
        row-gap: 100px;
    }

    @include min-screen($desktop-l) {
        row-gap: 120px;
    }

    @include min-screen($desktop-xl) {
        row-gap: 140px;
    }
}

.s-solutions__how-it-works--item {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;

    @include min-screen($tablet-s) {
        flex-direction: row;
        column-gap: clamp(3.75rem, -0.9223rem + 9.7466vw, 6.875rem); // $tablet-s = 60px to $desktop = 110px
    }
}

.s-solutions__how-it-works--item {
    @include min-screen($tablet-s) {
        &-image-left {
            flex-direction: row-reverse;
        }
    }
}

.s-solutions__how-it-works--text {
    flex: 0.5;
    height: 100%;
    @include formatRichText();

    h3 {
        @include h--m();
        margin-bottom: 40px;
    }

    p {
        @include min-screen($tablet) {
            font-size: 20px;
        }
    }
}

.s-solutions__how-it-works--image {
    flex: 0.5;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include min-screen($desktop-l) {
            max-height: 538px;
        }
    }
}
