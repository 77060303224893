.s-project-overview {
  .s-project__deliverables {
    margin-bottom: 40px;

    a {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  & > h3 {
    margin-top: 0 !important;
  }
}

h1.s-project__heading {
  display: inline-block;
  @include colors-gradient();
  @include gradient-text();
  margin: 40px 0;
}

.s-project-testimonials {
  padding: 70px 0;
  
  @include min-screen($tablet) {
    padding: 130px 0;
  }
}

.c-project__formatted {
  @include formatRichText();

  h2 {
    @include h--xl();
    display: inline-block;
    @include colors-gradient();
    @include gradient-text();
  }

  h3 {
    @include h--xs();
    color: $fm-grey;
    margin-top: 24px;
    margin-bottom: 8px;

    & > strong {
      font-weight: 200 !important;
    }
  }

  ul, ol {
    list-style: initial;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  // [class*=col-]
  @include min-screen(576px) {
    .layout-row > div:nth-child(1) {
      padding-right: 40px;
    }
    .layout-row > div:nth-child(2) {
      padding-left: 40px;
    }
  }
}

.c-project__client-section {
  @include min-screen(767px) {
    padding-left: 40px;
  } 
}

.c-project__tiles {
  padding-bottom: 70px;
  
  @include min-screen($tablet) {
    padding-bottom: 130px;
  }
}

.c-project__link {
  @include secondary-font;
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  padding: 0 20px 10px 0;
  color: #000000;
  text-decoration: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    right: 0;
    width: 10px;
    height: 10px;
    background-image: url('../images/arrow-black.svg');
    background-repeat: no-repeat;
    background-position: center right;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: $fm-iron;
  }

  &:hover:after {
    background: linear-gradient(89.04deg, $main-gradient-1 0.62%, $main-gradient-2 15.43%, $main-gradient-3 47.74%, $main-gradient-4 54.8%, $main-gradient-5 80.24%, $main-gradient-6 93.11%, $main-gradient-7 97.37%);
  }
}