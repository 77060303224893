.s-insights-bar {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  color: $fm-white;
}

@mixin baseInsightsVisible() {
  position: fixed;
  opacity: 1;
  height: auto;
  z-index: 4;
  width: 100vw;
  height: 100vh;
}

.s-insights-bar--visible {
  @include baseInsightsVisible();
  background: linear-gradient(225deg, $main-gradient-1 0%, $main-gradient-2 15.27%, $main-gradient-3 48.7%, $main-gradient-4 55.96%, $main-gradient-5 82.27%, $main-gradient-6 95.62%, $main-gradient-7 100%);
}

.s-insights-bar--visible-blue {
  @include baseInsightsVisible();
  background: linear-gradient(38.13deg, #AECDF0 -19.79%, #83A9D6 0.89%, #17569C 38.95%, #2073BB 66.74%, #AED2E9 99.59%);
}

.s-insights-bar__content {
  overflow-y: scroll;
  height: 100%;
}

.s-insights-bar__content--mobile {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

.s-insights-bar__content-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include min-screen(650px) {
    display: grid;
    align-items: flex-start;
    grid-template-columns: auto 300px;
    column-gap: $grid-gap;
    height: 100%;
    padding-top: 60px;
  }
  
  @include min-screen($tablet) {
    grid-template-columns: 616px auto; // 300 + 300 + 16
  }

  @include min-screen(1080px) {
    padding-top: 124px;
    grid-template-columns: 710px auto; // 347+347+16
  }

  @include min-screen($desktop-xl) {
    padding-top: 124px;
    grid-template-columns: 1073px auto; // 347+347+347+16+16
  }
}

.s-insights-bar-list {
  width: 100%;
  padding: 0 20px;
  margin: 40px 0;

  @include min-screen(650px) {
    margin-top: 0;
  }
}

.s-insights-bar-list__featured-heading {
  font-weight: 250;
  font-size: 25px;
  line-height: 32px;

  span {
    font-weight: 400;
    color: $fm-white;
  }
}


div.s-insights-bar-list__item {
  margin-top: 45px;
  padding-top: 12px;
  border-top: 1px solid $fm-transparent-2;
}

.s-insights-bar-list__heading {
  margin-bottom: 15px;
}

span.s-insights-bar-list__footer {
  display: block;
  color: $fm-smoke;
} 

.s-insights-bar-list__authors {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

a.s-insights-bar-list__link {
  color: $fm-white;
  text-decoration: none;
}

.s-insights-bar__posts {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: $grid-gap;

  .insight-block:nth-of-type(n+5) {
    display: none;
  }

  @include min-screen(1080px) {
    grid-template-columns: repeat(auto-fill, 347px);
  }

  @include min-screen($desktop-xl) {
    .insight-block:nth-of-type(n+5) {
      display: flex;
    }
  }
}
