.s-cs-gallery {
  padding: 60px 0 0 0;

  @include min-screen($tablet) {
    padding: 100px 0 0 0;
  }

  @include min-screen($desktop-xl) {
    padding: 140px 0 0 0;
  }
}

.s-cs-gallery__header {
  margin-bottom: 40px;

  span:first-of-type {
    font-weight: 150;
    display: block;
  }

  @include min-screen($tablet) {
    margin-bottom: 80px;
  }
}

.s-cs-gallery__container {
  @include min-screen($tablet) {
    display: flex;
 }
}

.s-cs-gallery__projects {
  display: flex;
  gap: 5px;
  width: calc(100% + 40px);
  padding-right: 20px;
  margin-left: -20px;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 33px;
  @include secondary-font;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  // animation run in JS
  opacity:0;
  transition: all 2s;
    
  @include min-screen($tablet) {
    display: initial;
    gap: 0; 
    width: 100%;
    padding-right: 0;
    margin-left: 0;
    font-family: $base-font-family;
    text-transform: none;
    max-width: 272px;
    padding-right: 20px;
    padding-bottom: 0;
    overflow-x: visible;
  }

  @include min-screen($desktop-xl) {
    max-width: 320px;
  }

  li {
    display: inline-flex;
    font-size: 13px;
    font-weight: 400;
    padding: 5px 7px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    color: #000000;
    background: $fm-white;
    border: 1px solid #E0E0E0;

    &:first-of-type {
      margin-left: 20px;
    }
    &:hover {
      color: $fm-grey;
    }
    
    @include min-screen($tablet) {
      @include list-item-desktop;
      display: list-item;
      margin-bottom: 20px;
      margin-right: 0;
      padding: 0;
      border: none;
      width: fit-content;

      &:first-of-type {
        margin-left: 0;
      }

    }

    &.s-cs-gallery__projects--active {
      color: $fm-white;
      background-color: $fm-black;
      border: 1px solid $fm-black;

      @include min-screen($tablet) {
        @include list-item-active-desktop;
        border: none;
        background-color: transparent;
      }
    }
  }
}

.s-cs-galitem {
  display: none;
  width: 100%;
}

.s-cs-galitem--active {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @include min-screen(800px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.s-cs-galitem__view-full {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;

  img {
    width: 100%;
    display: block;
    height: auto;
    margin-bottom: 32px;
  }

  @include min-screen(800px) {
    width: 437px;

    img {
      width: 437px;
    }
  }

  @include min-screen($desktop-l) {
    width: 650px;

    img {
      width: 650px;
    }
  }

  @include min-screen($desktop-xl) {
    width: 750px;

    img {
      width: 750px;
    }
  }
}

.s-cs-galitem__view-full--animated, .s-cs-gallery__related-items--animated {
  // animation run in JS
  opacity: 0;
  transition: all 2s;
}

a.s-cs-galitem__link {
  @include secondary-font;
  display: block;
  text-align: left;
  width: 120px;
  padding: 10px 0;
  color: $fm-black;
  text-decoration: none;
  font-size: 13px;
  background-image: url('../images/arrow-black.svg');
  background-repeat: no-repeat;
  background-position: right;
  align-self: flex-end;

  @include min-screen($tablet) {
    align-self: flex-start;
  }

  @include min-screen($desktop) {
    font-size: 15px;
  }
}

.s-cs-galitem__header-border {
  display: block;
  width: 100%;
  height: 4px;
  margin: 16px 0;
  background: linear-gradient(89.04deg, $main-gradient-1 0.62%, $main-gradient-2 15.43%, $main-gradient-3 47.74%, $main-gradient-4 54.8%, $main-gradient-5 80.24%, $main-gradient-6 93.11%, $main-gradient-7 97.37%);
}

.s-cs-gallery__related-header {
  @include h--m--desktop-s;
  margin: 30px 0;
  font-weight: 200;
  span {
    font-weight: 400;
  }

  @include min-screen(800px) {
    margin: 0 0 20px 0;
  }
}

.s-cs-gallery__related-items {
  width: 100%;
  
  @include min-screen(800px) {
    margin-left: 20px;
  }

  @include min-screen($desktop) {
    margin-left: 40px;
  }

  @include min-screen($desktop-xl) {
    margin-left: 60px;
  }

  div:nth-of-type(2) {
    margin-top: 40px
  }
}

.s-cs-gallery__related-item {
  width: 100%;
  margin-bottom: 20px;
  border-top: 1px solid $fm-stone;

  &:nth-of-type(1) {
    margin-right: 20px;
  }

  span {
    color: $fm-grey;
    display: block;
  }
}

a.s-cs-gallery__link {
  display: block;
  text-decoration: none;
  margin: 8px 0 20px 0;
  @include h--s-desktop();
  &:hover {
    color: $fm-grey;
  }

}
