.s-mobile-app-development__attributes {
  padding-top: 40px;
  padding-bottom: 40px;

  h2 {
    margin-bottom: 16px;
  }
}

.s-mobile-app-development__attributes--no-padding-top {
  padding-top: 0;
}

.s-mobile-app-development__attributes--no-padding-block {
  padding-block: 0;
}

.s-mobile-app-development__attributes--space-top {
  padding-top: 40px;

  @include min-screen($tablet-s) {
    padding-top: 80px;
  }
}

.s-mobile-app-development__attributes-row {
  padding-top: 40px;
  padding-bottom: 40px;

  @include min-screen($tablet-s) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  h2 {
    font-size: 45px;
    line-height: 45px;

    @include min-screen($desktop-s) {
      font-size: 55px;
      line-height: 72px;
    }

    @include min-screen($desktop-xl) {
      font-size: 80px;
      line-height: 80px;
    }
  }
}

.s-mobile-app-development__accordions {
  margin-bottom: -32px;
  margin-top: 32px;

  @include min-screen($tablet-s) {
    margin-top: 0;
  }
}

.s-mobile-app-development__apps-pride {
  padding-top: 48px;
  padding-bottom: 80px;
}

.s-mobile-app-development__apps-pride-heading {
  padding-top: 48px;
  padding-bottom: 42px;
  color: $fm-stone;
}

.s-mobile-app-development__apps-pride-portfolio {
  margin-bottom: 68px;
}

.s-mobile-app-development__apps-pride-item  {
  p {
    color: $fm-white;
  }
}
