.clients-reviews {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    color: $fm-white;
    padding-block: 40px 20px;
  
    @include min-screen($tablet) {
      padding-block: 80px 40px;
    }
  
    &--headings {
      display: flex;
      flex-direction: column;
      row-gap: 26px;
  
      h3 {
        @include h--s();
        max-width: 600px;
  
        @include min-screen($desktop-xl) {
          max-width: 750px;
        }
      }
    }
  
    &--variant-testimonials {
      padding-block: 20px 0;
  
      h2 {
        margin-bottom: 60px;
      }
  
      @include min-screen($tablet) {
        padding-block: 40px 0;
  
        h2 {
          margin-bottom: 40px;
        }
      }
    }
  }
  
  .s-reviews-slider {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, $transition-time $transition-easing;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
  
    @include min-screen($tablet) {
      padding-bottom: 115px;
    }

    .tns-item {
      @include sliderGrabCursor()
    }
    
    .tns-item:last-of-type {
        .c-review-card {
            margin-right: 40px;
        }
    }

    &.s-people-slider {
      @include max-screen($desktop-s) {
        padding: 0 20px;
        max-width: 100%;
      }

      @include baseContainerMaxWidth();
      margin: auto;

      .s-our-management__person {
        margin-left: 5px;
      }
    }
  }
  
  .s-reviews-slider--visible {
    visibility: visible;
    opacity: 1;
  }
  
  .s-reviews-slider__outer {
    position: relative;
    overflow: hidden;
  }
  
  .s-reviews-slider__inner {
    display: flex;
    height: 100%;
    position: relative;

    .tns-item {
      min-height: 100%;
    }
  }
  

  .s-reviews-slider__dots {
    display: flex;
    justify-content: center;
    column-gap: 7px;
    width: 100%;
    margin-top: 20px;

    .s-people-slider & {
      @include max-screen($tablet) {
        padding-bottom: 40px;
      }
    }

    &--dot {
        padding: 5px;
        border-radius: 50%;
        border: none;
        outline: none;
        background-color: $fm-grey;
        cursor: pointer;

        &:hover {
            background-color: $fm-smoke;
        }

        &--inactive {
            background-color: $fm-grey;
        }

        &--active {
            background-color: $fm-smoke;
        }
    }
}
