.s-contact-section {
    padding-block: 60px;
    background-color: $fm-alabaster;

    @include min-screen($tablet) {
        padding-block: 120px;
    }
}

.s-contact-section__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 35px;

    @include min-screen($tablet) {
        row-gap: 90px;
    }
}

.s-contact-section__persons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1070px;
    row-gap: 45px;

    @include min-screen($tablet-s) {
        flex-direction: row;
        justify-content: space-around;
    }

    @include min-screen($tablet) {
        justify-content: space-around;
        padding-inline: 24px;
    }
}

.s-contact-section__persons--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;
    text-align: center;
    width: 235px;

    img {
        width: 100%;
        height: 275px;
        border-radius: 6px;
        object-fit: cover;
    }

    a {
        margin-top: unset;
        white-space: nowrap;
    }

    @include min-screen($tablet-s) {
        row-gap: 20px;
    }
}

.s-contact-section__persons--item-industries {
    @include b--l;
    color: $fm-iron;
}

.s-contact-section__heading {
    text-align: center;
    font-size: 32px;
    line-height: 42px;
    
    @include min-screen($tablet-s) {
        @include h--l();
    }

    span {
        @include text--gradient;
    }
}
