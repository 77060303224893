.accordion__trigger {
  background-color: transparent;
  cursor: pointer;
  padding: 0 40px 16px 0;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  position: relative;
  color: $fm-black;
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;  

  &::after {
    content: ''; /* Unicode character for "plus" sign (+) */
    position: absolute;
    top: 4px;
    right: 9px;
    width: 2px;
    height: 12px;
    display: block;
    background-color: $fm-black;
    transition: all 0.3s ease;
    opacity: 1;
    transform: rotate(0);
  }
  &::before {
    content: '';
    position: absolute;
    top:9px;
    right: 4px;
    height: 2px;
    width: 12px;
    background-color: $fm-black;
    transition: all 0.4s ease;
    transform: rotate(0);
  }

  &--white {
    color: $fm-white;

    &::after {
      background-color: $fm-white;
    }
    
    &::before {
      background-color: $fm-white;
    }
  }
}

.accordion__trigger--active {
  font-weight: 500;
  &::after {
    opacity: 0;
    transform: rotate(720deg);
  }
  &::before {
    transform: rotate(720deg);
  }
}


.accordion__trigger--active {
  border-bottom: 0;
}

.accordion__content {
  background-color: $fm-white;
  max-height: 0;
  overflow: hidden;
  transition: max-height $transition-time $transition-easing;
  border-bottom: 1px solid $fm-black;
  margin-bottom: 32px;
  padding-right: 40px;

  p {
    color: $fm-grey;
    padding-bottom: 32px;
    @include b--m();
  }


  &--white {
    color: $fm-stone;
    background: transparent;
    border-color: $fm-white;
  }
}
