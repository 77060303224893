.s-our-vision {
  padding-bottom: 60px;
  max-width: 500px;
  width: 100%;
  color: $fm-white;

  h1 {
    color: $fm-grey;
  }

  p {
    margin-top: 24px;
  }

  @include min-screen($tablet) {
    padding-bottom: 120px;
  }
}

.s-our-mission {
  text-align: center;
  padding: 100px 20px;
  background-color: $fm-white;
  color: $fm-black;

  @include min-screen($tablet) {
    padding: 148px 0;
  }

  h1 {
    color: $fm-grey;
  }

  h2 {
    max-width: 890px;
    margin: 0 auto;

    span {
      @include colors-gradient();
      @include gradient-text();
    }
  }
}

.s-stats {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 256px);
  gap: $grid-gap-xxl;
  padding: 80px 20px;
  color: $fm-white;

  @include min-screen($desktop-s) {
    padding-top: 100px;
  }

  @include min-screen($desktop-l) {
    grid-template-columns: repeat(4, 256px);
  }

  @include min-screen($desktop-xl) {
    padding-top: 140px;
    grid-template-columns: repeat(4, 356px);
  }
}

.s-stats--justify-content-space-between {
  @include min-screen($desktop) {
    justify-content: space-between;
  }
}

.s-stats__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  border: 1px solid $fm-transparent;
  border-radius: 7px;
  text-align: center;

  @include min-screen($tablet) {
    height: 238px;
  }

  @include min-screen($desktop-xl) {
    height: 300px;
  }

  span:first-of-type {
    margin-bottom: 32px;
  }
}

.s-stats-description {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 50px 0 100px 0;

  @include min-screen($desktop-s) {
    justify-content: flex-end;
    text-align: left;
  }

  p {
    color: $fm-white;
    max-width: 528px;
  }
}

.s-culture-code {
  padding: 0 0 40px 0;
  color: $fm-white;

  @include min-screen($tablet) {
    padding: 166px 0 79px 0;
  }

  h2 {
    color: $fm-stone;
    margin-bottom: 56px;
  }
}

.s-culture-code__item {
  margin-bottom: 40px;

  @include min-screen($phone) {
    margin-bottom: 80px;
  }

  h3 {
    color: $fm-white;
  }
}

.s-culture-code__enjoy {
  display: flex;

  p {
    @include text--gradient-light();
  }
}

.s-our-management {
  padding-top: 80px;
}

.s-our-management:last-of-type {
  padding-bottom: 100px;
  padding-top: 80px;

  @include min-screen($tablet) {
    padding-bottom: 160px;
  }
}

.s-our-management__heading {
  color: $fm-stone;
}

.s-our-management__container {
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;
  gap: $grid-gap-xxl;
  justify-content: center;

  @include min-screen($desktop-l) {
    grid-template-columns: repeat(4, 256px);
    justify-content: flex-start;
  }
}

.s-our-management__person {
  padding-bottom: 32px;
  border: 1px solid $fm-transparent-2;
  border-radius: 7px;
  text-align: center;
  width: 256px;
  overflow: hidden;

  img {
    width: 256px;
    height: auto;
  }
}

.s-our-management__person--product {
  img {
    width: 294px;
    height: auto;
  }

  h3.s-our-management__name {
    color: $fm-black;
  }
}

.s-our-management__person--contact-baner-mobile {
  width: 150px;
  margin-left: 0;

  img {
    width: 150px;
  }
}

.s-our-management__name {
  color: $fm-white;
  margin: 16px 0 8px 0;
}

.s-our-management__position {
  display: block;
  color: $fm-light-grey;
}