.press-office-page {
  background-color: #fff;
  .base-container {
  }
}

.press-office-page__heading {
  @include colors-gradient-press-office-text;
  @include gradient-text;
}

.press-office-page__sub-heading {
  @include h--m--desktop-s;
  margin-bottom: 32px;
}

.press-office-page__lead-text {
  @include h--s-desktop;
}

.press-office-page__sub-heading--in-english {
  margin-top: 64px;
}

.press-office-page--section-alabaster {
  background-color: $fm-alabaster;
}

.press-office-page--section-padding {
  padding-top: 74px;
  padding-bottom: 74px;

  @include min-screen($tablet-s) {
    padding-top: 148px;
    padding-bottom: 148px;
  }
}

.press-office-page--section-padding-half-bottom {
  padding-top: 74px;
  padding-bottom: 32px;

  @include min-screen($tablet-s) {
    padding-top: 148px;
    padding-bottom: 74px;
  }
}

.press-office-page--heading-margin {
  margin-bottom: 64px;
}

.press-office-page__post-list-item-image-link {
  display: block;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: (208 / 295) * 100%;
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
  }

  .press-office-page__post-list-item--featured & {
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}

.press-office-page__post-list-item {
  margin-bottom: 64px;

  @include min-screen($tablet) {
    margin-bottom: 0;
  }

  .press-office-page__post-list--in-media & {
    margin-bottom: 32px;
  }
}

.press-office-page__post-list-item-date {
  color: $fm-grey;
  margin-bottom: 4px;
  margin-top: 12px;

  .press-office-page__post-list-item--featured & {
    margin-top: 36px;
  }
}

.press-office-page__post-list-item-title {
  @include h--s-desktop;
  font-weight: 500;
  text-overflow: ellipsis;
  height: 44px;
  overflow: hidden;

  @include min-screen($desktop-s) {
    height: 52px;
  }

  .press-office-page__post-list-item--featured & {
    font-size: 35px !important;
    line-height: 42px !important;
    height: 84px !important;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.press-office-page__row-press-release {
  height: 100%;
  align-content: space-between;
  
  @include min-screen($tablet) {
    row-gap: 48px;
  }
}

.press-office-page__button-more {
  .press-office-page__post-list--press-releases & {
    @include min-screen($tablet) {
      margin-top: 64px;
    }
  }

  .press-office-page__post-list--in-media & {
    padding-top: 32px;
  }
}
