.connected-boat__table {
  text-align: center;
  margin-bottom: 40px;
  border-left: 1px solid $fm-stone;
  border-top: 1px solid $fm-stone;
}

.connected-boat__table-row {
  margin: 0;
}

.connected-boat__table-col {
  border-right: 1px solid $fm-stone;
  padding: 0;
  display: flex;
  flex-direction: column;

  div {
    border-bottom: 1px solid $fm-stone;
    padding: 10px;

    &:nth-child(1) {
      text-transform: uppercase;
      font-weight: 700;
    }

    &:nth-child(2) {
      flex: 1;
    }
  }
}

.connected-boat__process {
  ul {
    margin-bottom: 0;
  }
  .row {
    margin-bottom: 20px;
  }
}

.connected-boat__phase {
  @include secondary-font;
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  padding: 0 20px 10px 0;
  color: #000000;
}