.u--mb-10 {
  margin-bottom: 10px;
}

.u--mb-16 {
  margin-bottom: 16px;
}

.u--mb-20 {
  margin-bottom: 20px;
}

.u--mb-30 {
  margin-bottom: 30px;
}

.u--mt-none {
  margin-top: 0 !important;
}

.u--pt-none {
  padding-top: 0 !important;
}

.u-mb-none {
  margin-bottom: 0 !important;
}

.u--ml-10 {
  margin-left: 10px;
}

.u--ml-20 {
  margin-left: 20px;
}

.u--mt-10 {
  margin-top: 10px;
}

.u--mt-20 {
  margin-top: 20px !important;
}

.u--mt-30 {
  margin-top: 30px;
}

.u--mt-40 {
  margin-top: 40px;
}

.u--mt-60 {
  margin-top: 60px !important;
}

.u--mt-80 {
  margin-top: 80px !important;
}

.u--mt-100 {
  margin-top: 100px !important;
}

.u--mb-20 {
  margin-bottom: 20px;
}

.u--mb-30 {
  margin-bottom: 30px;
}

.u--mb-40 {
  margin-bottom: 40px;
}

.u--mb-80 {
  margin-bottom: 80px;
}

.u--mt-40 {
  margin-top: 40px !important;
}

.u--mt-60 {
  margin-top: 60px;
}

.u--mt-100 {
  margin-top: 100px;
}

.u--ml-auto {
  margin-left: auto;
}

.u--mr-20 {
  margin-right: 20px;
}

.u--pr-20 {
  padding-right: 20px;
}

.u--ta-left {
  text-align: left;
}

.u--ta-center {
  text-align: center;
}

.u--ta-right {
  text-align: right;
}

.u--d-none {
  display: none;
}

.u--d-flex {
  display: flex;
}

.u--flex-items-center {
  align-items: center;
}

.u--flex-justify-between {
  justify-content: space-between;
}

.u--d-block {
  display: inline-block !important;
}

.u--nowrap {
  white-space: nowrap;
}

.u--fw-100 {
  font-weight: 100;
}

.u--fw-250 {
  font-weight: 250 !important;
}

.u--no-decoration {
  text-decoration: none;;
}

.u--underline {
  text-decoration: underline !important;
}

.u--capitalize {
  text-transform: capitalize;
}

.u--overflow-hidden {
  overflow: hidden;
}

.u--position-relative {
  position: relative;
}

.u--position-sticky {
  position: sticky;
}

.u--text-bold {
  font-weight: 700;
}

.u--test-semibold {
  font-weight: 500;
}

.u--float-right {
  float: right;
}

.u--w-50 {
  width: 50%;
}

.u--text-white {
  color: #fff;
}

.u--text-grey {
  color: $fm-light-grey;
}

.blur {
  -webkit-backdrop-filter: blur($blur);
  backdrop-filter: blur($blur);
}

.u--text-center {
    text-align: center;
}

.u--w-content {
  width: fit-content;
}

// https://uit.stanford.edu/accessibility/concepts/screen-reader-only-content

.u--sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
