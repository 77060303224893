.s-career-offer {
  display: flex;
  flex-direction: column;
  
  @include min-screen($tablet) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.s-career-offer__formatted {
  width: 100%;
  @include formatRichText();
  padding: 40px 20px 40px 0;
  @include min-screen($tablet) {
    width: 60%;
    padding: 80px 40px 80px 0;
  }

  ul {
    margin-bottom: 40px;
    li {
       background: no-repeat url('../images/icon-benefit-purple.svg');
       background-size: 20px 20px;
       background-position: left 5px;
       padding-left: 32px;
       margin: 12px 0;
       line-height: 25px;
     }
  }
}

.s-career-offer__sticky {
  display: none;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $fm-white;
  
  @include min-screen($tablet) {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    align-items: flex-start;
    top: 50px;
    margin-top: 80px;
    margin-bottom: 40px;
    width: 437px;
    border-radius: 8px;
    margin-bottom: 80px;
    border: 1px solid $fm-smoke;
  }
} 

.s-career-offer__details {
  max-width: 350px;
  width: 100%; 
  padding: 0 0 40px 0;
  p {
    color: $fm-grey;

    &:nth-child(2) {
      color: $fm-black;
      @include h--m--desktop-s;
    }
    &:nth-child(3) {
      margin-bottom: 40px;
    }

    &:nth-child(3), &:nth-child(1) {
      margin-bottom: 16px;
    }
  }

  @include min-screen($tablet) {
    padding: 40px 32px 0 32px;
  }
}

.s-career-offer__separator {
  display: none;
  
  @include min-screen($tablet) {
    display: block;
    margin: 40px 32px;
    height: 4px;
    width: calc(100% - 64px);
    @include colors-gradient-blue();
  }
}

.s-career-offer__contact {
  max-width: 350px;
  width: 100%;
  padding: 0 0 40px 0;

  @include min-screen($tablet) {
    padding: 0 32px 40px 32px;
  }

  p {
    color: $fm-grey;
  }

  a {
    margin-top: 32px;
  }
}

.s-career-offer__hr {
  display: flex;
  margin-top: 32px;
  p {
    color: $fm-black;
  }

  & > div:nth-child(2) {
    padding-left: 16px;
    p:nth-child(2) {
      margin-top: 8px;
      color: $fm-grey;
    }
  }
}

.c-career-offer__avatar {
  
  flex-shrink: 0;
  height: 80px;
  width: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
}