.s-quote {
  position: relative;
  color: $fm-white;
  padding-top: 120px;
  padding-bottom: 80px;

  @include min-screen($tablet) {
    padding-bottom: 0;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    background-color: $fm-black;
    top: 50%;
    bottom: 0;
  }

  &__container {
    overflow: hidden;
    background: var(--quote-bg) center;
    background-size: cover;
    z-index: 1;
    border-radius: 8px;
    margin: 0 20px;
    padding: 60px 20px;
    width: auto;

    @include min-screen($tablet-s) {
      padding: 108px 40px 150px;
    }

    @include min-screen($desktop-s) {
      margin: 0 auto;
      padding-inline: 80px;
      width: 100%;
    }

    &__text {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      h3 {
        text-align: center;
      }

      &__author {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 60px;
    
        &__avatar {
          width: 70px;
          height: 70px;
          margin-right: 30px;
          border-radius: 50%;
          object-fit: cover;
        }

        &__data {
          &__name {
            font-size: 34px;
            line-height: 46px;
          }

          &__position {
            text-transform: uppercase;
            color: $fm-stone;
          }
        }
      }
    }
  }
}
