.hubspot__form {
    background-color: #f8f8f8;
    padding: 40px;

    input {
        background-color: $fm-alabaster;
    }

    .hs-error-msg {
        color: #c04343;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        font-family: $base-font-family;
        text-transform: none;
    }

    .hs-fieldtype-textarea {
      width: 100% !important;

      textarea {
        background: #f8f8f8;
        ;
      }
    }

    fieldset {
      max-width: unset !important;
    }

  input,
  textarea {
    width: 100%;
    padding-bottom: 6px;
    border: none;
    -webkit-border-radius: 0;
    outline: none;
    border-bottom: 2px solid $fm-grey;
    font-family: $base-font-family;
    color: $fm-black;
    font-size: 14px;

    @include max-screen($phone) {
          font-size: 16px;
    }
   
    &::placeholder {
      font-family: $secondary-font-family;
      text-transform: uppercase;
      color: $fm-iron;
    }
  }

  .hs-submit {
    width: fit-content;
  }

  @include min-screen($tablet) {
    margin-bottom: 28px;
  }

  textarea {
    height: 140px;
    border: 2px solid $fm-grey;
    padding: 12px;
  }

  select {
    width: 100% !important;
    background: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 0;
    border: none;
    -webkit-border-radius: 0;
    outline: none;
    border-bottom: 2px solid $fm-grey;
    font-family: $base-font-family;
    color: $fm-black;
    font-size: 13px;
  }

  option {
    padding: 8px 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $secondary-font-family;
    font-size: 13px;
    line-height: 20px;
  }

  .form-columns-2,.form-columns-1 {
    display: flex;
    flex-direction: column;
    > div {
        margin-bottom: 48px;
    }

    @include min-screen($tablet) {
        flex-direction: row;
        > div {
            width: 100% !important;
            margin-bottom: 46px;
    
        }
        input {
            width: 100% !important;

        }
      }
    
  }

    .form-columns-2 {
        display: flex;
        position: relative;
        @include min-screen($tablet) {
          column-gap: $grid-gap-xxl;

          > div:first-child {
            max-width: 500px;
          }
        }

    }

    label {
      font-family: $secondary-font-family;
      font-size: 13px;
      text-transform: uppercase;
      color: $fm-iron;
  }

    .hs-richtext {
      font-family: $base-font-family;
      font-size: 12px;
      line-height: 14px;
  }

  .hs_message {
    .input {
      margin-top: 20px;
    }
  }

  .hs-form-booleancheckbox {
    label {
      font-family: $base-font-family;
      font-size: 12px;
      text-transform: none;
      color: black;
      line-height: 24px;

  }

  }

    .hs-form-booleancheckbox {
        input {
            height: 16px;
            width: 16px !important;
            accent-color: #151515;
            border: 1px solid #707070;
            border-radius: 2px;
        }
        label {
            display: flex;
            align-items: center;
        }
    }

    .hs-company {
      input {
        @include min-screen($tablet) {
          padding-top: 14px;

        }
      }
    
    }



    .hs-button {
        width: auto;
        font-family: $secondary-font-family;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        padding: 17px 20px;
        font-size: 15px;
        border-radius: 28px;
        text-decoration: none;
        line-height: 20px;
        font-weight: 400;
        cursor: pointer;
        background: #000;
        border: 1px solid #000;
        color: #fff;
        position: relative;
        padding-right: 33px;
        padding-left: 33px;

    
    }
    .actions {
        display: flex;
        width: fit-content;
    }

  
  .submitted-message {
      margin-block: 100px;
  }

  &.hubspot__modal {
    border-radius: 16px;

    .hs-form-field {
      text-align: left;
    }
    .hs-richtext {
      text-align: left;
    }

    .form-columns-2, .form-columns-1 {
      margin: auto;
    }

    .form-columns-2,.form-columns-1 { 
      > div {
        margin-bottom: 16px;
        }
    }
    .hs-message, .legal-consent-container {
      margin-bottom: 20px;
    }
  }
}