.spinner {
  display: block;
  width: 48px;
  height: 48px;
  color: $fm-stone;
  position: relative;
  border: 5px solid;
  border-radius: 50%;
  border-right-color: $fm-black;
  animation: rotate 1s linear infinite; 

  @include min-screen($tablet) {
    width: 64px;
    height: 64px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0); 
  }
  100% {
    transform: rotate(360deg); 
  } 
}

.s-spinner {
  display: none;
  justify-content: center;
}

.s-spinner--visible {
  display: flex;
}