.c-button {
  @include secondary-font;
  display: inline-flex;
  align-items: center;
  padding: 17px 30px;
  font-size: 15px;
  border-radius: 28px;
  text-decoration: none;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;

  @include min-screen($tablet) {
    padding: 12px 30px;
    font-size: 13px;
  }

  @include min-screen($desktop-xl) {
    padding: 16px 33px;
    font-size: 14px;
  }
}

.c-button--no-text {
  height: 56px;
}

.c-button--double-arrow {
  padding: 17px 20px;
}

.c-button__arrow {
  display: inline-block;
  width: 30px;
  height: 10px;
  margin-left: 15px;
  background-repeat: no-repeat;
  background-position: left;
  transition: background-position .3s ease-out;
}

.c-button--white {
  color: #121212;
  background: $fm-white;
  border: 1px solid $fm-white;
  padding-right: 30px;

  &:hover {
    color: $fm-white;
    background-color: #121212;
  }
}

.c-button--white-always:hover {
  color: #121212;
  background: $fm-white;
}

.c-button--transparent {
  color: #121212;
  background-color: rgba($fm-white, 0);
  border: 1px solid $fm-white;
  padding-right: 30px;

  &:hover {
    color: $fm-white;
    background-color: #121212;
  }
}

.c-button--black {
  color: $fm-white;
  background: $fm-black;
  border: 1px solid $fm-white;

  &:hover {
    color: #121212;
    background: $fm-white;
  }
}

.c-button--white-outlined:hover {
  border: 1px solid $fm-black;
}

.c-button--iron {
  color: $fm-white;
  border: 1px solid #ffffff;
  background-color: $fm-iron;

  &:hover {
    color: #121212;
    background: $fm-white;
  }
}

.c-button--white-outlined {
  color: $fm-white;
  border: 1px solid $fm-white;
}

.c-button--black-outlined {
  border: 1px solid $fm-black;
}

.c-button--stone-outlined {
  border: 1px solid $fm-stone;
}


.c-button__arrow--black {
  background-image: url('../images/arrow-black.svg');
}

.c-button:hover .c-button__arrow--black {
  background-image: url('../images/arrow-white.svg');
  background-position: right;
}

.c-button__arrow--white {
  background-image: url('../images/arrow-white.svg');
}

.c-button:hover .c-button__arrow--white {
  background-image: url('../images/arrow-black.svg');
  background-position: right;
}

.c-button__still-arrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 15px;
  background-repeat: no-repeat;
}

.c-button__still-arrow--white {
  background-image: url('../images/arrow-white.svg');
}

.c-button__icon_eye--black {
  background-image: url('../images/icon-eye-black.svg');
  width: 24px;
  height: 24px;
}

.c-button__icon-download-white {
  background-image: url('../images/download-file.svg');
  width: 24px;
  height: 24px;
}

.c-button__icon-close--white {
  background-image: url('../images/close-white.svg');
  width: 24px;
  height: 24px;
}

.c-button__icon-plus { 
  background-image: url('../images/plus.svg');
  width: 24px;
  height: 24px;
}

.c-button__icon-minus { 
  background-image: url('../images/minus.svg');
  width: 24px;
  height: 24px;
}

.c-button__arrow-down {
  transform: rotate(90deg);
}

.c-button__arrow-right {
  transform: rotate(0deg);
}

.c-button__still-arrow--flip {
  margin-left: 0;
  margin-right: 15px;
  transform: scaleX(-1);
}

.c-button--filled-grey {
  background: #353535;
  border: 1px solid #353535;
  color: $fm-white;
}

.c-button--filled-black {
  background: #000000;
  border: 1px solid #000000;
  color: $fm-white;
}

.c-button--filled-white {
  color: #121212;
  background: $fm-white;
  border: 1px solid $fm-white;
  padding-right: 30px;
}

.c-button--filled-iron {
  background: $fm-iron;
  border: 1px solid $fm-iron;
  color: $fm-white;
}

.c-button__arrow--always-white {
  background-image: url('../images/arrow-white.svg');
}

.c-button:hover .c-button__arrow--always-white {
  background-position: right;
  background-image: url('../images/arrow-white.svg');
}

.c-button__arrow--always-black {
  background-image: url('../images/arrow-black.svg');
}

.c-button:hover .c-button__arrow--always-black {
  background-position: right;
  background-image: url('../images/arrow-black.svg');
}

.c-button--arrow-flip {
  transform: scaleX(-1);
}

.c-button--center {
  justify-content: center;
}

.c-button {
  .c-button__arrow-down--centered {
    background-position: center center !important;
  }

  &:hover {
    .c-button__arrow-down--centered {
      background-position: center center !important;
    }
  }
}

.c-button__arrow-right-rotate-on-hover {
  span {
    transition: transform $transition-time $transition-easing;
    transform: rotate(0deg);
  }

  &:hover {
    span {
      transform: rotate(90deg);
    }
  }
}

.c-button--text {
  color: $fm-white;
  background: transparent;
  border: none;
  outline: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  font-family: $secondary-font-family;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.c-button__chevron-down-white {
  display: inline-block;
  width: 30px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url('../images/chevron-white-down.svg');
}

.c-button__small {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  height: 32px;
}
.c-button--circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  border: 1px solid $fm-white;
  background: transparent;

  span {
    margin-left: unset;
    background-position: center center;
  }

  &:hover {
    cursor: pointer;
  }
}