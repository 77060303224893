.s-barometr {
 &-grid-3 {
    @include min-screen($desktop-s) {
        column-gap: 70px;

        padding-top: 100px;
        grid-template-columns: repeat(3, 256px);
      }
    
      @include min-screen($desktop-xl) {
        padding-top: 140px;
        grid-template-columns: repeat(3, 356px);
      }
  }
  
  &-experts {
  justify-content: center;

    @include min-screen($desktop-s) {
      justify-content: start;
    }
 }

 &-experts-center {
  justify-content: center;

  @include min-screen($desktop-s) {
    justify-content: space-between;
  }
 }

 &--lang-switcher {


  .s-langLabels {
    z-index: 2;
    top: 30px;
  }
 }

 &-scroll-down {
  display: none;
  
  @media only screen and (min-width: 960px) {
    display: flex;
    justify-content: center;
    padding: 65px 0;
  }



  div {
    text-transform: uppercase;
    border-bottom: 1px solid #151515;
    font-family: PP Fraktion Mono;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    padding-bottom: 5px;
    &::after {
      content: '';
      background: url(../images/chevron-scroll.svg) 50% 50% / contain no-repeat;
      display: inline-block;
      margin-bottom: -6px;

      min-width: 24px;
      min-height: 24px;
      margin-top: 4px;
    }

  }
 }
}
