.s-case-study-section {
  display: grid;
  gap: $grid-gap;
  align-items: center;
  margin: 80px 0;

  h2 {
    margin-bottom: 60px;
  }

  h3 {
    @include h--m--desktop-s;
    margin-bottom: 60px;
  }

  ul {
    margin-top: 32px;

    li {
      position: relative;
      margin-bottom: 24px;
      padding-left: 44px;
      @include b--l;

      font-size: 16px !important;
      line-height: 24px !important;


      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: url("../images/icon-tick.svg") 50% 50% / contain no-repeat;
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }
  
  @include min-screen($tablet-s) {
    grid-template-columns: 4fr 0px 7fr;
  }

  @include min-screen($tablet) {
    margin: 160px auto;
  }
  
  @include min-screen($desktop) {
    grid-template-columns: 5fr 1fr 7fr;
  }

  &__heading {
    margin-bottom: 30px;

    @include min-screen($tablet-s) {
      margin-bottom: 60px;
    }
  }

  &__image {
    text-align: center;
    display: flex;
    
    @include min-screen($tablet-s) {
      grid-column: 1 / 2;
      grid-row: 1;
    }
  }

    &__video {
      display: flex;
      width: 100%;
      height: 100%;
  
      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

  &__content {
    max-width: 758px;
    @include min-screen($tablet-s) {
      grid-column: 3 / 4;
      grid-row: 1;
    }

  }

  &--full-image {
    display: flex;
    flex-direction: column;
    max-width: none;
    padding-inline: 0;

    img {
      display: block;
      width: 100%;
    }
  }

  &--full-video {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 0;
    width: 100%;
 
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  &--top-image {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .s-case-study-section__header {
      padding-left: 0;
      padding-right: 0;
      margin-top: 40px;

      @include min-screen($tablet-s) {
        margin-top: 80px;
      }

      @include min-screen($desktop) {
        margin-top: 160px;
      }
    }

    img {
      width: 100%;
    }
  }

  &--left-image {
    @include min-screen($tablet-s) {
    .s-case-study-section__image {
        grid-column: 1 / 2;
        margin-right: auto;
        margin-left: 0;
      }
      .s-case-study-section__content {
        grid-column: 3 / 4;
      }
    }
  }

  &--right-image {
    @include min-screen($tablet-s) {
      grid-template-columns: 7fr 1fr 5fr;
      .s-case-study-section__image {
          grid-column: 3 / 4;
          margin-right: 0;
          margin-left: auto;
        }
        .s-case-study-section__content {
          grid-column: 1 / 2;
        }
    }
  }
}
