.s-report-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 40px;
    row-gap: 24px;
    margin-bottom: 40px;

    @include min-screen($desktop-s) {
        margin-bottom: 80px;
    }

    a,
    button {
        width: fit-content;
        white-space: nowrap;
        height: 56px;
    }
}