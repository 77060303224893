.s-insights-recent {
    padding-block: 40px;

    @include min-screen($tablet) {
        padding-block: 80px;
    }
   
}
.s-insights-recent--heading {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 60px;

    p {
        @include b--m();
    }

    p:empty {
        display: none;
    }
}
.s-insights-recent--grid {
    padding-bottom: 55px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    column-gap: 14px;
    row-gap: 26px;

    @include min-screen($desktop-s) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.c-insights-recent__item {
    text-decoration: none;

    div {
        overflow: hidden;
        height: 180px;

        img {
            width: 100%;
            height: 100%;
            margin-bottom: 4px;
            object-fit: cover;
            @include animationZoomOnHover;
        }
    }

    h3 {
        color: $fm-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        @include text-ellipsis(2);
        margin-top: 12px;
    }

    &:hover {
        cursor: pointer;
    }
}

.s-insights-recent--button {
    display: flex;
    justify-content: center;

    .c-button {
        margin-top: 0;
    }
}
