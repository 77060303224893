.s-insights {
  display: flex;
  justify-content: center;
  padding: 64px 0 40px;
  column-gap: 140px;
}

.s-insights-article {
  max-width: 635px;

  @include min-screen($desktop-l) {
    max-width: 735px;
  }
}

.s-insights-article__cover {
  width: 100%;
  max-height: 315px;
  object-fit: cover;
  margin-bottom: 24px;
}

.s-insights-article__introduction {
  @include formatRichText();
  text-wrap: pretty;

  p {
    line-height: 24px;
  }
}

.responsive-iframe {
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}

.s-insights-article__figcaption {
  font-family: Aeonik;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  color: $fm-grey;
}

.s-insights-article__formatted--padding {
  padding: 0 !important;
}

.s-insights-article__formatted {
  @include formatRichText();
  padding-bottom: 32px;
  text-wrap: pretty;

  @include min-screen($phone) {
    padding-bottom: 40px;
  }

  h2 {
    margin-top: 42px;
    margin-bottom: 16px;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 8px;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  p {
    line-height: 24px;

    code {
      background: #f5f5f5;
      padding: 3px;
    }

    &.featured {
      font-weight: 500;
      line-height: 24px;

      @include min-screen($desktop-s) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  pre {
    margin-bottom: 16px;
  }

  blockquote {
    padding: 50px 50px 30px 50px;
    margin-bottom: 20px;
    color: $fm-white;
    background-color: $fm-black;
  }

  img {
    width: 100%;
  }

  figure {
    margin-bottom: 20px;
    img {
      & + figcaption {
        font-size: 13px;
        color: $fm-grey;
        line-height: 160%;
      }
    }
  }
}

.s-insights-article__blockquote {
  position: relative;
  padding-left: 44px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 24px;
  margin-bottom: 64px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background: linear-gradient(
      180deg,
      #f7ae9d 7.99%,
      #d64f3c 15.3%,
      #5c153b 48.7%,
      #561d5c 56%,
      #152a9e 82.3%,
      #1394c3 91.84%,
      #07c7df 98.12%
    );
  }

  blockquote {
    padding: 0 0 0 9px;
    background-color: transparent;
    margin-bottom: 0;

    position: relative;
    font-size: 20px;
    color: $fm-black;
    font-weight: 500;
    line-height: 26px;

    &::before {
      content: "“";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: "”";
    }
  }

  figcaption {
    padding-left: 9px;
    font-family: $secondary-font-family;
    font-size: 12px;
    text-transform: uppercase;
    color: $fm-black;
    line-height: 20px;
    margin-top: 40px;
  }
}

.s-insights-article__authors {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  margin-top: 24px;
  margin-bottom: 24px;

  @include min-screen($desktop-s) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.s-insights-article__author {
  display: flex;
  align-items: center;

  p {
    color: $fm-black;
  }
}

.s-insights-article__avatar {
  margin-right: 16px;
  flex-shrink: 0;
  height: 56px;
  width: 56px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.s-insights-article__info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 4px;

  p {
    margin: 0;
  }
}

.s-insights-article__name {
  font-size: 16px;
  line-height: 24px;
}

.s-insights-article__position {
}

.s-insights-article__tags {
  padding-top: 40px;
  border-top: 1px solid $fm-black;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.s-insights__resource-form {
  padding: 50px 0;
}

.s-insights__related-container {
  padding: 70px 0;
  color: $fm-white;

  @include min-screen($tablet-s) {
    padding: 140px 0;
  }

  @include min-screen($desktop-s) {
    display: none;
  }
}

.s-insights__related-header {
  max-width: 987px;
  margin: 0 auto;
  margin-bottom: 40px;

  h2 {
    font-weight: 200;
    font-size: 35px;
    line-height: 42px;

    span {
      font-weight: 400;
      font-size: 35px;
      line-height: 42px;
    }
  }
}

.s-insights__related {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: $fm-white;
}

.s-insights-sidebar {
  display: none;
  margin-bottom: 64px;
  transition: all $transition-time $transition-easing;

  h3 {
    margin-bottom: 36px;
  }

  @include min-screen($desktop-s) {
    display: block;
    position: sticky;
    top: 50px;
    width: 340px;
    height: fit-content;
  }
}

.s-insights-sidebar__item {
  padding-bottom: 19px;
  margin-bottom: 24px;
}

.s-insights-sidebar__avatars {
  display: flex;
  margin-bottom: 6px;

  p {
    margin-left: 12px;
  }
}

.s-insights-sidebar__avatar {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  object-fit: cover;

  &:not(:first-of-type) {
    margin-left: -12px;
  }
}

.s-insights-sidebar__title {
  text-decoration: none;
}

.s-insights-sidebar__contact {
  margin-top: 40px;

  h4 {
    width: 300px;
    margin-bottom: 28px;
  }
}

.s-insights-article__title {
  margin-top: 20px;
  font-size: 35px;
  line-height: 42px;
  letter-spacing: -0.35px;

  @include min-screen($desktop-s) {
    margin-top: 16px;
    font-size: 55px;
    line-height: 72px;
    letter-spacing: -0.55px;
  }
}
