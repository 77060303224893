.s-job-offer {
    // display: grid;
    // grid-template-areas:
    //     'introduction'
    //     'hiring'
    //     'description'
    //     'gallery-mobile'
    // ;

    // @include min-screen($tablet-s) {
    //     grid-template-areas:
    //         'introduction introduction'
    //         'hiring hiring'
    //         'description gallery';
    // }

    &--introduction {
        grid-area: introduction;
    }

    &--hiring-process {
        grid-area: hiring;
    }

    &--job-description {
        grid-area: description;
        transition: all $transition-time $transition-easing;
        height: fit-content;

        @include min-screen($desktop-s) {
            justify-self: center;
            position: relative;
            left: clamp(-3.4375rem, -31.4625rem + 38vw, -1.0625rem); // $desktop-s -55px - desktop -17px
        }

        @include min-screen($desktop) {
            left: clamp(-1.0625rem, -33.7669rem + 40.8805vw, 3rem); // $desktop -17px - desktop-l -48px
        }

        @include min-screen($desktop-l) {
            left: clamp(-2.625rem, -38.069rem + 39.3822vw, 3.75rem); // $desktop-l -48px - desktop-xl 60px
        }

        @include min-screen($desktop-xl) {
            left: clamp(-4.25rem, -30.8621rem + 25.0467vw, 12.5rem); // desktop-xl -68px - 2770px - 200px
        }
    }

    &--gallery {
        grid-area: gallery;
        display: flex;

        @include min-screen($tablet-s) {
            grid-area: gallery;
            display: flex;
            padding-bottom: 40px;
        }

        @include min-screen($tablet) {
            padding-bottom: 80px;
        }
    }

    &--gallery-mobile {
        grid-area: gallery-mobile;
        overflow: hidden;

        .s-lifestyle-gallery {
            margin-block: 20px;
        }

        @include min-screen($tablet-s) {
            display: none;
        }
    }
}

.s-career-offer {
    display: flex;
    flex-direction: column;
    
    @include min-screen($tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

.s-career-offer__sticky {
    display: none;


    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $fm-white;


    @include min-screen($tablet) {
        display: flex;
    }

    .s-career-locations {
        padding-top: 24px;
        padding-bottom: 16px;
    }
    
    @include min-screen($tablet) {
      display: initial;
      position: -webkit-sticky;
      position: sticky;
      align-items: flex-start;
      top: 50px;
      margin-top: 80px;
      margin-bottom: 40px;
      width: 376px;
      height: 660px;
      border-radius: 8px;
      margin-bottom: 80px;
      border: 1px solid $fm-smoke;
    }
  } 

.s-career-job-description {
    padding-block: 80px;
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    p {
        @include h--s();
        font-weight: 400;
        max-width: 600px;

    }
    h2 {
        @include h--l();
        font-weight: 400;
        text-wrap: balance;
        margin-bottom: -20px;
    }


    ul {
        margin-bottom: 40px;
        max-width: 600px;

        li {
            background: no-repeat url('../images/icon-benefit-black.svg');
            background-size: 28px 24px;
            background-position: left 5px;
            padding-left: 42px;
            margin: 24px 0;
            line-height: 25px;
            font-family: Aeonik;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            text-align: left;

         }
      }

    @include min-screen($tablet) {
        padding-block: 80px 230px;;
    }

    &--title {
        @include h--l();
        font-weight: 250;

        b {
            font-weight: 400;
        }
    }
}

.s-career-job-description__details {
    display: flex;
    flex-direction: column;
    row-gap: 60px;

    @include min-screen($tablet) {
        row-gap: 80px;
    }

    &--item {
        display: flex;
        flex-direction: column;
        row-gap: 40px;

        @include min-screen($tablet-s) {
            max-width: clamp(33rem, -11.7619rem + 55.9524vw, 47.6875rem); // $desktop 528px - desktop-xl 763px
        }

        &--title {
            @include h--m();
        }

        &--content {
            @include formatRichText();
            text-wrap: pretty;
            color: $fm-black;
            padding-right: 20px;

            p {
                font-size: 20px;
            }

            p:last-of-type {
                margin-bottom: 0;
            }

            ul {
                li {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin-bottom: 24px;
                    color: $fm-black;
                    font-size: 20px;

                    @include min-screen($tablet) {
                        margin-bottom: 32px;
                    }

                    &:before {
                        content: '';
                        background: url("../images/icon-tick-black.svg") 50% 50% / contain no-repeat;
                        display: inline-block;
                        min-width: 24px;
                        min-height: 24px;
                        margin-right: 16px;
                        margin-top: 5px;
                    }
                }

                li:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}