.s-case-study-header-cta {
    padding: 80px 0;

    @include min-screen($tablet) {
        padding: 160px 0;
    }

    @include min-screen($desktop-xl) {
        padding: 192px 0;
    }

    &__logos {
        display: inline-grid;
        grid-template-columns: auto auto auto auto;
        column-gap: 20px;
        align-items: center;
        margin-top: 60px;
        &-cooperation {
            @include secondary-font;
            color: $fm-atlas-grey;
            font-size: $base-font-size;
        }
    }
}

.s-case-study-header-image {
    display: block;
    width: 100%;
}

.s-case-study-header-video {
    display: flex;
    align-items: center;
    justify-content: center;

    video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}