.s-header-cta {
  background: $fm-black;
  color: $fm-white;
  position: relative;

}

.s-header-cta-gradient {
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 220px;
    width: 100%;
    @include gradient-black();
  }
}

.s-header-cta--transparent-bg {
  background: transparent;
  color: $fm-black;
}

.s-header-cta--logo-right-bg {
  background-image: url('../images/header-background-right.png');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;

  @include baseContainerSize();
}

.s-header-cta--logo-solutions-bg {
  background-image: url('../images/solutions-background.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;

  @include baseContainerSize();
}

.s-header-cta--logo-accessibility-bg {
  background-image: url('../images/accessibility-background.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;

  @include baseContainerSize();
}

.s-header-cta__spacer {
  padding: 60px 0 60px 0;

  @include min-screen($tablet) {
    padding: 100px 0 80px 0;
  }
  @include min-screen($desktop-s) {
    padding: 170px 0 120px 0;
  }
  @include min-screen($desktop-xl) {
    padding-bottom: 140px;
  }
}

.s-header-cta__spacer--logo-solutions {
  padding: 60px 0 60px 0 !important;

  @include min-screen($tablet) {
    padding: 60px 0 96px 0 !important;
  }
}

.s-header-cta__spacer--career {
  padding: 60px 0 60px 0 !important;

  @include min-screen($tablet) {
    padding: 80px 0 96px 0 !important;
  }
}

.s-header__upper-text {
  margin-bottom: 8px;
  color: $fm-stone;
  animation: 2s fadeInLineUp ease-out;

  span {
    @include text--gradient-light();
  }
}

.s-header__hero {
  margin-bottom: 32px;
  margin-block-start: 0;
}

.s-header__hero--white {
  color: $fm-white;
}

.s-header__hero--career {
  font-size: 60px;
  line-height: 75px;
  max-width: 22ch;
  margin-inline: auto;
}

.s-header__subtext {
  font-size: 20px;
  line-height: 26px;
  max-width: 700px;
}

.s-header__subtext--white {
  color: $fm-white;
}

.s-header-cta--height-thin {
  .s-header-cta__spacer {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-top: 50px;

    @include min-screen($tablet) {
      padding-top: 48px;
      padding-bottom: 48px;
      margin-top: 100px;
    }
  }
}

.s-header-cta--background-image {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(21,21,21);
    background: linear-gradient(0deg, rgba(21,21,21,0.65) 80%, rgba(21,21,21,1) 100%);
  }

  & > div {
    position: relative;
    z-index: 1;
  }
}

.s-header-cta__logo {
  object-fit: contain;
  height: 35px;
  width: auto;
}

.c-button  {
  position: relative;
  z-index: 2;
  margin-top: 60px;
}

