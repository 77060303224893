.c-form__error {
  display: none;
  color: #c04343;
  text-align: center;
  padding: 5px 0;
  font-size: 11px;
}

.c-form__error--visible {
  display: block;
}

.c-form-input--text {
  @include input-text;
}

.c-form-input--search {
  padding-right: 0;
}

.c-form__dropzone {
  border-radius: 8px;
  padding: 50px 0;
  background-color: darken($fm-alabaster, 10%);
  text-align: center;
  transition: 0.25s background-color ease-in-out;
  cursor: pointer;
  
  &-dragging,
  &:hover {
    background-color: darken($fm-alabaster, 15%);
  }
  
  & > input {
    display: none;
  }
}
