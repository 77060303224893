.s-advisory__cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;

    padding-block: 65px 75px;

    @include min-screen($desktop-xl) {
        
    }

    &--title {
        @include h--m--desktop-s();
        margin-inline: auto;
        text-align: center;
    }

    &.s-advisory__bg  {
        background: $fm-alabaster;
    }
}
