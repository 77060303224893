.s-recently-delivered {
}

.s-recently-delivered__heading {
  font-weight: 150;
  span {
    font-weight: 400;
  }
  margin-top: 48px;
  margin-bottom: 25px;
  
  @include min-screen($tablet) {
    margin-top: 100px;
  }

  @include min-screen($desktop-l) {
    margin-top: 140px;
  }
}

.s-recently-delivered__container {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: $grid-gap;
  row-gap: 100px;
}

.s-recently-delivered__item {
  h3 {
    margin: 20px 0;
  }
}

.s-recently-delivered__item--span {
  grid-column-end: span 2;
}

.s-recently-delivered__tags {
  span {
    margin-right: 15px;
    margin-bottom: 5px;
  }
}

.s-recently-delivered__button {
  margin: 0px 0 80px 0;
  display: flex;
  justify-content: center;
  
  @include min-screen($tablet) {
    margin: 80px 0 110px 0;
  }

  @include min-screen($desktop-xl) {
    margin-bottom: 140px;
  }
}