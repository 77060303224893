.download-page {

  &__stats {
    @extend .s-stats;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 60px;

    @include min-screen($tablet-s) {
      padding-top: 148px;
      padding-bottom: 148px;
      gap: 70px;
    }

    @include min-screen($desktop-s) {
      gap: 35px;
    }

    @include min-screen($desktop-xl) {
      gap: 70px;
      grid-template-columns: repeat(4, 322px);
    }

    &--item {
      @extend .s-stats__item;
      width: 256px;

      span.h--xl {
        font-size: 80px;
        line-height: 90px;
      }

      span:first-of-type {
        margin-bottom: 32px;
      }

      @include min-screen($desktop-xl) {
        height: 300px;
        width: 356px;
      }
    }
  }

  .s-our-management {
    &__heading {
      color: $fm-white;
    }

    &__container {
      justify-content: center;

      @include min-screen($phone) {
        width: 498px;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
      }

      @include min-screen($tablet-s) {
        width: 548px;
      }

      @include min-screen($desktop-s) {
        width: auto;
      }
    }

    &__person {
      width: 256px;

      @include min-screen($phone) {
        width: 233px;
      }

      @include min-screen($tablet-s) {
        width: 256px;
      }

      @include min-screen($desktop-s) {
        width: 233px;
      }

      @include min-screen($desktop-l) {
        width: 256px;
      }
    }
  }
}

.s-download-contact-form {
  padding-block: 80px;

  input {
    background: transparent;
  }

  @include min-screen($desktop) {
    padding-block: 120px;
  }
}

.s-container-margin-bottom {
  margin-bottom: 80px;

  @include min-screen($tablet) {
    margin-bottom: 160px;
  }
}

.s-download-page-hero {
  img {
    margin-bottom: 60px;
  }
}

.s-download-contact__top {
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  margin-block: 80px 0;

  @include min-screen($tablet) {
    flex-direction: row;
    align-items: flex-start;
    margin-block: 80px 40px;
  }

  @include min-screen($desktop) {
    margin-block: 120px 40px;

  }

  &--text {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    width: 100%;

    @include min-screen($tablet) {
      max-width: clamp(25rem, -3.3636rem + 47.2727vw, 31.5rem); // $tablet 400px -> $desktop-s 504px
      margin-right: clamp(3rem, -16.6364rem + 32.7273vw, 7.5rem); // $tablet 48px -> $desktop-s 120px
    }

    h3 {
      font-size: 36px;
      line-height: 43px;
    }

    h4 {
      font-size: 20px;
      line-height: 26px;
      font-weight: 250;
    }


    @include min-screen($tablet) {
      h3 {
        @include h--l();
      }

      h4 {
        @include h--m();
      }
    }

    @include min-screen($desktop-xl) {
      h3 {
        font-size: 55px;
        line-height: 72px;
      }

      h4 {
        font-size: 35px;
        line-height: 42px;
      }
    }
  }

  &--form {
    width: 100%;

    .s-download-contact__form {
      background-color: $fm-alabaster;
      padding: 40px;
      height: auto;

      h3 {
        display: none;
      }

      input {
        background: transparent;
      }
    }
  }
}