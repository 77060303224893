.s-contact-form {
    .s-advisory__contact-person--left-container--title {
        margin-bottom: 20px;

        @include min-screen($tablet) {
            margin-bottom: 40px;
        }
    }

    .s-advisory__contact-person--left-container--desc {
        @include formatRichText();
        max-width: unset;

        p {
            font-size: 20px;
            line-height: 26px;
            font-weight: 400;
            color: $fm-black;
            margin-bottom: 24px;
            max-width: unset;

            &:last-of-type {
                margin-bottom: 0;
            }

            @include min-screen($tablet) {
                font-size: 28px;
                line-height: 35px;
            }
        }
    }

    .s-advisory__contact-person--left-container--email {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 0;

        @include min-screen($tablet) {
            font-size: 32px;
            line-height: 42px;
        }
    }

    .s-advisory__contact-person--left-container {
        button {
            margin-top: 20px;
            width: fit-content;
        }
    }

    .s-advisory__conctact {
        .s-advisory__contact-form {
            margin-right: 0 !important;

            .s-advisory-contact-form,
            .js-advisory-contact-form {
                @include min-screen($desktop-l) {
                    max-width: unset;
                }
            }
        }
    }
}
