.press-office-contact {
  position: relative;
  text-align: center;
  padding-bottom: 33px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 106px;
    background-color: $fm-alabaster;
    display: block;
    width: 100%;

    @include min-screen($tablet-s) {
      height: 156px;
    }
  }
}

.press-office-contact__body {
  background-color: #fff;
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @include min-screen(tablet-s) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

.press-office-contact--d-flex {
  display: flex;
  justify-content: center;

  a {
    display: flex;
  }
}

.press-office-contact__item {
  margin-bottom: 10px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.press-office-contact__name {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

.press-office-contact__email,
.press-office-contact__phone {
  font-size: 16px;
  line-height: 20px;
  font-family: $secondary-font-family;
  text-decoration: none;

  .icon {
    height: 20px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    margin-right: 6px;

    @include min-screen($tablet-s) {
      height: 24px;
      margin-right: 10px;
    }
  }
}

.press-office-contact__email {
  .icon {
    background-image: url(../images/press-office/icon-mail.svg);
  }
}

.press-office-contact__phone {
  .icon {
    background-image: url(../images/press-office/icon-phone.svg);
  }
}
