.c-video {
  position: relative;

  video[poster] {
    height: 100%;
    width: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(21, 21, 21, 0.2);
    z-index: 1;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(21, 21, 21, 0.4);
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
}