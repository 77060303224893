.s-services-showcase {
  position: relative;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity $transition-time $transition-easing;
  display: flex;
  justify-content: center;

  .tns-item {
    @include sliderGrabCursor();
  }
}

.s-services-showcase--visible {
  visibility: visible;
  opacity: 1;
}

.s-services-showcase__outer {
  position: relative;
  overflow: hidden;
}

.s-services-showcase__inner {
  position: relative;
}

.s-services-showcase__item {
  width: 280px;
  height: 522px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $fm-alabaster;
  padding: 32px;

  @include min-screen($desktop-l) {
    height: 600px;
  }

  @include min-screen($phone) {
    width: 374px;
  }

  @include min-screen($desktop-xl) {
    width: 400px;
  }


  span.s-services-showcase__number {
    @include secondary-font;
    font-size: 12px;
  }

  p {
    margin-bottom: 32px;
  }
}

.s-services-showcase__item--black {
  background: #000000;
  color: $fm-white;

  span.s-services-showcase__heading-gradient {
    background-color: $fm-black;
    @include text--gradient-light();
  }
}

.s-services-showcase__branches {
  @include max-screen($tablet) {
    h3 {
      margin-bottom: 50px;
    }
  }
  @include min-screen($tablet) {
    ul {
      transition: opacity 1s ease-out;
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
}

@include min-screen($tablet) {
.s-services-showcase__item:hover .s-services-showcase__branches {
    h3 {
      margin-bottom: 50px;
    }
    ul {
      opacity: 1;
      margin-top: 20px;
      height: auto;
    }
  }
}