.s-hero {
  position: relative;
  height: auto;
  padding-block: 60px;

  @include min-screen($tablet-s) {
    padding-block: 60px 100px;
  }

  @include min-screen($tablet) {
    padding-block: 100px;
  }

  .base-container {
    display: flex;
    flex-direction: column;
    position: relative;
    column-gap: 16px;
    z-index: 1;

    &--image {
      @include min-screen($tablet-s) {
        flex-direction: row;
      }
    }
  }

  &__text {
    flex: 1;

    h1 {
      @include h--l();
      font-size: 55px;
      line-height: 72px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 56px;

    a,
    button {
      width: fit-content;
      white-space: nowrap;
      height: 56px;
    }

    @include min-screen($phone) {
      flex-direction: row;
      column-gap: 40px;
    }

    @include min-screen($tablet-s) {
      flex-direction: column;
      row-gap: 24px;
    }

    @include min-screen($desktop-s) {
      flex-direction: row;
    }
  }

  &__subtext {
    margin-top: 40px;
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 26px;

    @include min-screen($desktop-l) {
      @include h--m();
    }

    @include min-screen($desktop-xl) {
      font-size: 45px;
      line-height: 52px;
    }
  }

  &__introduction {
    @include b--m();

    @include min-screen($desktop-l) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  &__image {
    display: none;

    @include min-screen($phone) {
      flex: 1;
      display: flex;
      text-align: center;
      position: relative;
      justify-content: center;
    }

    img {

      @include min-screen($phone) {
        object-fit: contain;
        display: flex;
        margin-top: 40px;
        margin-bottom: unset;
      }

      @include min-screen($tablet-s) {
        margin-top: unset;
        margin-bottom: unset;
        height: 100%;
        align-self: center;
      }

      @include min-screen($tablet) {
        position: absolute;
        top: -30px;
        left: 0;
        height: 150%;
        max-height: 600px;
        left: 50%;
        transform: translateX(-50%);
      }

      @include min-screen($desktop-xl) {
        max-height: 800px;
      }
    }
  }
}