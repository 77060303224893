@mixin make-col($col, $max-cols: 12) {
  width: ($col / $max-cols) * 100%;
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-cols() {

  [class*=col-] {
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
    width: 100%;
  }

  @for $i from 1 through 12 {
    .col-#{$i}-12 {
      width: ($i / 12) * 100%;
    }
  }

  @each $name, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint){
      @for $i from 1 through 12 {
        .col-#{$name}-#{$i}-12 {
          @include make-col($i, 12);
        }
      }
      .col-#{$name}-auto {
        @include make-col-auto;
      }
    }
  }
}
