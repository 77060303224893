.s-case-study-partner {
  padding-bottom: 60px;
  border-bottom: 1px solid #C9C9C9;

  .s-case-study-header-cta__logos {
    img {
      max-width: 101px;
    }
  }
  
  @include min-screen($tablet) {
    padding-bottom: 120px;

  }

  &-presentation {
    padding-top: 80px;

  

    @include min-screen($tablet) {
      padding-top: 160px;
    }

    &__content {
        padding-top: 80px;    
        padding-bottom: 80px;

        display: grid;
        gap: 80px;
        grid-template-columns: 1fr;
        h2 {
          margin-bottom: 20px;
        }


        @include min-screen($tablet) {
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 100px;
          padding-bottom: 120px;

        
          p {
              margin-top: 0px;
          }
        } 
    }

  }
}