.s-advisory__services-container {
    background: $fm-alabaster;
    padding-block: 60px;

    @include min-screen($tablet) {
        padding-block: 120px;
    }
}

.s-advisory__services-showcase__card {
    width: 280px;
    height: 630px;
    margin-right: 16px;
    background: $fm-white;
    color: $fm-black;
    padding: 32px;

    @include min-screen($tablet) {
        width: 350px;
    }

    &-black {
        background: #000000;
        color: $fm-white;
    }

    &-black-content {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        p {
            color: $fm-smoke;
        }

        p:not(:last-of-type) {
            margin-bottom: 16px;
        }

        h3 {
            @include h--l();

            @include min-screen($desktop-xl) {
                font-size: 55px;
                line-height: 72px;
            }
        }
    }

    &-white-wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        span {
            @include secondary-font;
            color: $fm-black;
            font-size: 13px;
        }
    }

    &-white-content {
        display: flex;
        flex-direction: column;

        p {
            font-size: 20px;
            line-height: 32px;
            font-weight: 250;
        }

        h3 {
            p {
                @include h--m();
                margin-bottom: 24px;

                @include min-screen($tablet) {
                    margin-bottom: 48px;
                }

                @include min-screen($desktop-xl) {
                    font-size: 35px;
                    line-height: 42px;
                }
            }
        }
    }

    @include min-screen($tablet) {

        .s-advisory__services-showcase__card-white-desc {
            height: 0;
            opacity: 0;
            overflow: hidden;
            transition: all $transition-time $transition-easing;
        }
    
        &:hover {
            .s-advisory__services-showcase__card-white-desc {
                height: auto;
                opacity: 1;
                margin-top: 48px;
            }
        }
    }
}
