.c-press-office-who-we-are {
  text-align: center;
  max-width: 922px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

  h2 {

  }

  h3 {
    @include h--m--desktop-s;
  }

  span {
    @include colors-gradient-press-office-text;
    @include gradient-text;
  }
}
