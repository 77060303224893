.s-insights-filter__search-bar {
  padding-bottom: 12px;
  grid-area: search;

  input {
    font-size: inherit;
  }

  img {
    margin-right: 13px;
  }

  .c-form-input--search {
    margin-bottom: 0;
    border-bottom: 0;
  }

  @include min-screen($tablet-s) {
  }

    @include min-screen($desktop-s) {
      display: none;
      grid-area: unset;
  }

  .header & {
    padding-bottom: 0;
    display: none;

    @include min-screen($desktop-s) {
      display: block;
    }
  }
}

.s-insights-filter {
  grid-area: tags;
}

.s-insights-filter__tags-list {
  display: flex;
  gap: 5px; 
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 40px;
  overflow: hidden;
  height: fit-content;
  opacity: 0;
  transition: all $transition-time $transition-easing;

  @include min-screen($desktop-s) {
    margin-bottom: 64px;
  }

  &--visible {
    opacity: 1;
  }

  .c-tag {
    height: 32px;
  }
}

.s-insights-filter__show-more {
  display: none;

  &--visible {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @include min-screen($tablet-s) {
      display: none;
    }
  }
}
