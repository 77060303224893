.s-langLabels {
    $labels: &;

    position: absolute;
    top: -70px;
    right: 0;

    &__link {
        padding: 20px;
        position: relative;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.75);

        &:first-child::after {
            content: '';
            position: absolute;
            height: 11px;
            width: 1px;
            transform: translateY(-50%);
            right: 0;
            top: 50%;
            background-color: #C8C8C8;
        }

        &:hover {
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }

    &--en #{$labels}__link:nth-child(2),
    &--pl #{$labels}__link:nth-child(1) {
            text-decoration: underline;
            text-underline-offset: 2px;
            color: #000;
    }
}
