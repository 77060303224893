.s-mobile-nav {
  position: fixed;
  height: 100vh;
  width: 0;
  top: 0;
  overflow: hidden;
  color: $fm-white;
  padding-top: 20px;
  background: $fm-white;
  z-index: 7;
  transition: width 0.5s ease-out;
}

.s-mobile-nav--visible {
  width: 100vw;
}

.s-mobile-nav__base-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.s-mobile-nav__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .s-mobile-nav__logo {
    display: block;
    width: 38px;
    height: 24px;
    margin-top: 3px;

    @include min-screen($phone) {
      margin-top: 8px;
    }
  }
}

.s-mobile-nav__menu_container {
  color: $fm-black;
  padding-top: 64px;
  position: relative;
  flex: 1;

  a {
    text-decoration: none;
  }
}

ul.s-mobile-nav__menu {
  list-style: none;
  white-space: nowrap;

  li {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
  }
}

.s-mobile-nav__additional {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  transform: translateX(150%);
  padding: 0 20px 30px 0;
  transition: transform 0.5s ease-out;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &.pull {
    transform: translateX(0);
    overflow: auto;
  }

  .s-services-showcase__branches {
    margin-bottom: 30px;
  }

  .s-services-showcase__branches h3 {
    margin-bottom: 16px;
  }
}

.s-mobile-nav__additional-header {
  padding-top: 30px;
  padding-bottom: 30px;

  a {
    color: $fm-grey;
  }
}

.s-mobile-nav__additional-close {
  .c-button__arrow {
    margin-left: 0;
    height: 20px;
    background-size: 20px;
    width: 20px;
    opacity: 0.5;
    transform: scale(-1, 1);
    margin-right: 20px;
  }
}

.s-mobile-nav__additional-see-more {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;

  .c-button__arrow {
    margin-left: auto;
  }
}

.s-mobile-nav__expand-arrow {
  padding: 0 10px;
  img {
    height: 18px;
    width: 18px;
  }
}