.s-career-company-overview {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding-block: 40px;

    @include min-screen($tablet-s) {
        flex-direction: column;
        column-gap: clamp(3.75rem, -0.9223rem + 9.7466vw, 6.875rem); // $tablet-s = 60px to $desktop = 110px
    }

    @include min-screen($tablet) {
        padding-block: 80px 80px;
    }

    &--heading {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        flex: 0.45;

        b {
            font-weight: 400;
        }

    }

    &--benefits {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 54px;
        row-gap: 60px;

        @include min-screen($tablet) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;        
        }

        .item {
            display: flex;
            flex-direction: row;
            gap: 34px;

            &--content {
                display: flex;
                flex-direction: column;
                gap: 20px;

                h4 {
                    font-weight: 500;
                    color: $fm-black;

                }
                p {
                    color: $fm-iron;

                }
            }

            img {
                height: fit-content;
            }
        }
    }
}