.s-what-weve-done {
  max-width: 1280px;

  h1 {
    @include h--l();

    @include min-screen($desktop-xl) {
      margin-bottom: 92px;
    }
  }
}

