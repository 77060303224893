@font-face {
  font-family: 'Aeonik Air';
  src: url('./assets/fonts/Aeonik-AirItalic.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-AirItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Black.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Bold.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Air';
  src: url('./assets/fonts/Aeonik-Air.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Air.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Fraktion Mono';
  src: url('./assets/fonts/PPFraktionMono-Bold.woff2') format('woff2'),
      url('./assets/fonts/PPFraktionMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Light.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Medium.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Regular.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Light.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Light.woff') format('woff');
  font-weight: 250;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Thin.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Fraktion Mono';
  src: url('./assets/fonts/PPFraktionMono-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/PPFraktionMono-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Fraktion Mono';
  src: url('./assets/fonts/PPFraktionMono-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/PPFraktionMono-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Aeonik-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Fraktion Mono';
  src: url('./assets/fonts/PPFraktionMono-Regular.woff2') format('woff2'),
      url('./assets/fonts/PPFraktionMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

