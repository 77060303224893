.s-contact-press-office {
    color: $fm-black;
    padding: 40px 0;
    display: grid;
    grid-template-areas:
        "title"
        "content"
        "button";

    @include min-screen($tablet-s) {
        padding: 80px 0;
        grid-template-areas:
            "title button"
            "content content";
    }

    @include min-screen($desktop) {
        padding: 120px 0;
    }

    &__title {
        grid-area: title;
        color: $fm-black;
    }

    &__button {
        grid-area: button;
        height: fit-content;
        justify-self: center;

        @include min-screen($tablet-s) {
            justify-self: flex-end;
            align-self: center;
        }
    }

    &__content {
        grid-area: content;
        margin-top: 40px;

        @include min-screen($tablet-s) {
            margin-top: 60px;
        }

        @include min-screen($desktop) {
            margin-top: 80px;
        }
    }
}
