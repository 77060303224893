.c-review-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: clamp(17.5rem, 5.9375rem + 57.8125vw, 40.625rem); // $phone-s - 280px -> $tablet - 650px
    min-height: clamp(25rem, 24.1987rem + 3.4188vw, 26.25rem); // $phone-s - 400px -> $tablet - 420px
    height: 100%;
    padding: 36px;
    border: 1px solid $fm-grey;
    border-radius: $radius-md;

  

    @include min-screen($tablet) {
        padding: 56px;
    }

    &--text {
        color: $fm-white;
        @include h--s();
        font-size: 18px;
        font-weight: 250;
        padding-block: 24px;

        @include min-screen($desktop-xl) {
            padding-block: 40px;
        }

    }

    &.career-review-card {
        padding: 40px;
        
        @include min-screen($desktop) {
            min-height: 330px;
        };

        .c-review-card--text, .c-review-card__avatar--name, .c-review-card__avatar--position {
            color: $fm-black;

        }
    }
}

.c-review-card__quote {
    display: flex;
    align-items: center;
    column-gap: 28px;

    &--icon {
        position: relative;
        padding-right: 28px;
        p {
            font-size: 72px;
            line-height: unset;
            top: -24px;
            position: absolute;
            transform: rotate(180deg);
        }
    }

    &--bar {
        height: 4px;
        width: 100%;
        @include colors-gradient();
    }
}

.c-review-card__avatar {
    display: flex;
    align-items: center;
    column-gap: 16px;

    img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        object-fit: cover;
    }

    &--name {
        color: $fm-white;
        font-size: 20px;
    }

    &--position {
        @include secondary-font;
        font-size: 12px;
        color: $fm-transparent-2;
    }
}
