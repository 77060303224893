.c-goal-list {
  max-width: 580px;
}

.c-goal-list__item {
  margin-bottom: 64px;

  h2 {
    margin-bottom: 16px;
  }
}

.c-goal-list__image {
  margin-top: 40px;
  @include min-screen($phone) {
    margin-top: 0;
    height: 100%;
    margin-left: 28px;
    object-fit: cover;
    object-position: right;
  }

  @include min-screen($tablet) {
    height: auto;
    margin-left: 0;
  }
}
