.c-pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  gap: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: stretch;
  flex-wrap: wrap;
}

.c-pagination__item {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.c-pagination__link {

}
