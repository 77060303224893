.s-privacy-policy__text {
    @include formatRichText();

    margin-block: 40px;

    h2, h3 {
        margin-block: 0 10px;
    }

    p, ul li {
        @include b--m();
        margin-bottom: 10px;
    }

    ul {
        @extend .l--normal;
    }

    br {
        display: none;
    }
}