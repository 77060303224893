.quote {
    &__text {
        padding-left: 70px;
        position: relative;

        @include min-screen($tablet-s) {
            max-width: 85%;
        }
        
        h2 {
          @include h--m();
          margin-bottom: 24px;

          @include min-screen($tablet-s) {
            @include h--l();
            font-weight: 400;       
           }
        }

        &__tick {
            font-family: Aeonik;
            font-size: 158px;
            font-weight: 400;
            line-height: 136px;
            letter-spacing: -0.01em;
            position: absolute;
            left: 0;
            top: 0;
        }  
    }

    &__line {
        height: 4px;
        width: 100%;
        background-color: white;
        margin-top: 60px;
        margin-bottom: 25px;
        background-image: linear-gradient(89.04deg, #F7AE9D 0.62%, #D64F3C 15.43%, #5C153B 47.74%, #561D5C 54.8%, #152A9E 80.24%, #1394C3 93.11%, #07C7DF 97.37%);
    }
}
        
      