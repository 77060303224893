.s-advisory__services {
    padding-block: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 60px;

    @include min-screen($tablet-s) {
        row-gap: 100px;
    }

    @include min-screen($tablet) {
        padding-block: 80px 130px;
    }

    &--title {
        @include h--l();
    }
}

.s-advisory__services--grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 60px;

    @include min-screen($tablet-s) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
        row-gap: 80px;
    }

    @include min-screen($desktop-l) {
        column-gap: 32px;
    }
}

.s-advisory__services--grid-item {
    display: flex;
    column-gap: 34px;

    &--icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        flex-shrink: 0;
        background: $fm-iron;
    }

    &--content {
        width: 100%;
        padding-right: 38px;
        h4 {
            @include h--s();
            font-weight: 500;
            color: $fm-black;
            margin-bottom: 20px;
        }

        &-text {
            @include h--s();
            font-weight: 250;
            color: $fm-iron;

            p:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }
}
