.s-carreer-gallery {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 40px;
    height: 420px;
    max-height: calc(100vh - 40px);
    width: fit-content;
    min-width: 340px;
    overflow: hidden;

    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;

    &--visible {
        visibility: visible;
        opacity: 1;
    }

    &__images {
        display: flex;
        flex-direction: row;
        align-items: center;
        row-gap: 40px;
        gap: 40px;
        
        img {
            width: 340px;
            height: 275px;
            aspect-ratio: 4 / 3;
            object-fit: cover;
        }

        img:nth-child(even) {
            width: 225px;
            height: 310px;
            aspect-ratio: 3 / 4;
            margin-inline: auto;
        }
    }

    &__buttonWrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-top: 40px;
        background: $fm-white;
        align-self: center;
        display: flex;
        justify-content: center;

        .c-button {
            max-width: fit-content;
        }
    }
}

#tns2 {
    transition-timing-function: linear !important;
    will-change: transform;
}
