.c-list-icon-tick {
  margin-top: 32px;
  
    li {
      display: inline-flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 18px;
      font-size: 16px;
      line-height: 24px;
      
      &:before {
        content: '';
        background: url("../images/icon-tick.svg") 50% 50% / contain no-repeat;
        display: inline-block;
        min-width: 24px;
        min-height: 24px;
        margin-right: 16px;
        margin-top: 0px;
      }
    }
  }

.c-list-icon-tick--black {

    li {
      display: inline-flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 20px;
      line-height: 32px;

      &:before {
        content: '';
        background: url("../images/icon-tick-black.svg") 50% 50% / contain no-repeat;
        display: inline-block;
        min-width: 24px;
        min-height: 24px;
        margin-right: 18px;
        margin-top: 6px;
      }
    }

    li:not(:last-child) {
      margin-bottom: 24px;
    }
  }
